import * as React from "react"

const HotelIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            d="m19.986 7.455-6.193-4.879a2.893 2.893 0 0 0-3.591 0L4.01 7.455a3.935 3.935 0 0 0-1.508 3.108v9.015a2.377 2.377 0 0 0 2.374 2.374h3.956v-5.365a2.907 2.907 0 0 1-2.11-2.789V9.314a.528.528 0 1 1 1.055 0v3.429a.265.265 0 0 0 .264.264h.791a.265.265 0 0 0 .264-.264V9.314a.528.528 0 0 1 1.055 0v3.429a.265.265 0 0 0 .264.264h.787a.265.265 0 0 0 .264-.264V9.314a.528.528 0 0 1 1.055 0v4.484a2.907 2.907 0 0 1-2.11 2.789v5.365h3.693V9.314a.527.527 0 0 1 .857-.411c.094.075 2.308 1.907 2.308 7.164v.495a1.363 1.363 0 0 1-.4.97l-1.179 1.176v3.24h3.429a2.377 2.377 0 0 0 2.374-2.374v-9.011a3.935 3.935 0 0 0-1.507-3.108Z"
            fill={props?.color ?? "#4e5a6b"}
            data-name="Group 111271"
        />
    </svg>
)

export default HotelIcon
