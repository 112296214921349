import * as React from "react"

export const TransportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111088">
      <path
        data-name="icons8-public-transportation (1)"
        d="M8.82 3a5.39 5.39 0 0 0-5.385 5.385v1.843A1.073 1.073 0 0 0 2 11.256v3.59a1.077 1.077 0 0 0 1.436 1.029v12.253a1.435 1.435 0 0 0 1.436 1.436h.718a1.435 1.435 0 0 0 1.436-1.436h5.744v-5.384a4.573 4.573 0 0 1 .834-2.638l.638-2.73a4.76 4.76 0 0 1 .243-.747l-8.895.011V8.385a3.235 3.235 0 0 1 3.23-3.231h13.641a3.235 3.235 0 0 1 3.231 3.231v5.407a4.628 4.628 0 0 1 3.59 2.033v-4.569a1.077 1.077 0 0 0-1.082-1.077 1.065 1.065 0 0 0-.359.066v-1.86A5.39 5.39 0 0 0 22.461 3Zm5.026 3.59a1.077 1.077 0 1 0 0 2.154h3.59a1.077 1.077 0 1 0 0-2.154Zm4.94 8.615a3.241 3.241 0 0 0-3.147 2.5l-.688 2.95-.013.069a3.2 3.2 0 0 0-.733 2.023v6.1a.718.718 0 0 0 .718.718h1.436a.718.718 0 0 0 .718-.718v-.718h10.051v.718a.718.718 0 0 0 .718.718h1.436a.718.718 0 0 0 .718-.719v-6.1a3.2 3.2 0 0 0-.732-2.022l-.015-.07-.689-2.954a3.239 3.239 0 0 0-3.145-2.5Zm0 2.154h6.633a1.065 1.065 0 0 1 1.047.832l.308 1.324h-9.345l.309-1.324a1.066 1.066 0 0 1 1.048-.832Zm-9.607 3.59a1.436 1.436 0 1 1-1.436 1.436 1.435 1.435 0 0 1 1.436-1.436Zm8.257 2.151a1.077 1.077 0 1 1-1.077 1.077 1.077 1.077 0 0 1 1.077-1.077Zm9.333 0a1.077 1.077 0 1 1-1.077 1.077 1.077 1.077 0 0 1 1.077-1.077Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)


