import { borderRadius, borderRadiusAlignments, remCalc } from "../../utils";
import { Medium, SemiBold } from "../../utils/font";

export const searchBarStyle = {
    noDataFoundText: {
        color: "text.secondary",
        fontSize: remCalc(14),
        mt: "8px",
        mb: "-8px",
        fontFamily: SemiBold,
        textAlign: "center"
    },
    memberBox: {
        display: "flex",
        flexDirection: "column",
        gap: 3,
        mb: 1,
        maxHeight: '150px',
        height: "auto",
        Overflow: "auto",
        borderRadius: borderRadiusAlignments.borderSixteen
    },
    formlabel: {
        "& .MuiPaper-elevation MuiPaper-rounded": {
            borderRadius: borderRadiusAlignments?.borderTwelve
        },
        "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
            fontSize: remCalc(14),
            fontFamily: Medium,
            color: '#4E5A6B'
        },
    },
    toggleContainer: {
        padding: "6px 16px 16px 16px",
        gap: 2,
        borderRadius: "16px !important"
    },
    dialog: {
        borderRadius: borderRadius,
        "& .MuiPopover-paper": {
            marginTop: "12px",
            // borderRadius: borderRadius,
            // borderRadius: borderRadiusAlignments?.borderTwelve,
            boxShadow: '0px 2px 12px #00000014',
            // height: "226px",
            borderRadius: borderRadiusAlignments.borderSixteen,
        },
    },
    mainToggle2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        padding: '2px',
        boxShadow: "- 5px 0px 5px rgba(0, 0, 0, 0.2)",
        backdropFilter: 'blur(5px)', /* Blur effect with 5px blur radius */

    },
    button: {
        border: 'none',
        borderRadius: '100px',
        padding: '10px 20px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
        color: '#091B29',
    },
    activeButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: '100px',
        color: '#091B29',
        padding: "6px 16px",
        "&:hover": {
            backgroundColor: '#FFFFFF',
        },
    },
    inactiveButton: {
        padding: "6px 16px",
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        borderRadius: '100px',

    },
    main: {
        background: "rgba(0, 0, 0, 0.4)", // Transparent black background
        backdropFilter: "blur(10px)", // Blur effect to create glass-like appearance
        borderRadius: "20px", // Rounded corners
        width: "100%",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        padding: "16px",

        marginBottom: "0", // Remove margin bottom
    },
    othermain: {
        background: "rgba(0, 0, 0, 0.4)", // Transparent black background
        backdropFilter: "blur(10px)", // Blur effect to create glass-like appearance
        borderRadius: "20px", // Rounded corners
        width: "100%",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        marginTop: "30px",
    },
    reset: {
        color: "#FFFFFF",
        position: { sm: "relative" },
        left: { sm: "10px", xl: 0, lg: 0, md: 0 },
        fontFamily: SemiBold,
    },
    search: {
        marginTop: "12px",
    },
    innerBox: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    button: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        width: "100%",
    },
    mainToggle: {
        display: "flex",
        Overflow: "auto"
    },
    transparentToggle: {
        borderRadius: "24px",
        backdropFilter: "blur(5px)",
        border: "0.25px solid #98A0AC",
        color: "#FFFFFF",
        padding: "10px 20px",
        margin: "0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontSize: remCalc(12),
        backgroundColor: "rgba(4, 4, 4, 0.29)", // Light transparent background
        "&:hover": {
            backgroundColor: "rgba(4, 4, 4, 0.29)",
        },
    },
    transparentToggleSelectedLeft: {
        borderRadius: "24px",
        backgroundColor: "#007bff",
        color: "#fff",
        padding: "10px 20px",
        margin: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontSize: remCalc(12),
        fontFamily: Medium,
        "&:hover": {
            backgroundColor: "#007bff",
        },
    },
    transparentToggleSelectedRight: {
        border: "0.25px solid #98A0AC",
        borderRadius: "24px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#007bff",
        color: "#fff",
        padding: "10px 20px",
        margin: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontSize: remCalc(12),
        fontFamily: Medium,
        "&:hover": {
            backgroundColor: "#007bff",
        },
    },
    searchBtn: {
        height: "48px",
        backgroundColor: "#007bff",
        color: "#fff",
        borderRadius: "24px",
        cursor: "pointer",
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    searchText: {
        fontFamily: Medium,
        fontSize: "1rem",
        marginInlineStart: "8px !important",
    },
    recentSearchContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    recentSearch: {
        backgroundColor: "rgba(0, 0, 0, 0.4)", // Transparent black background
        backdropFilter: "blur(10px)", // Blur effect to create glass-like appearance
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
        padding: "10px 20px",
        width: "200px", // Adjust width as needed
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "50% / 0 0 100px 100px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
    },
};
