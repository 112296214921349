import { Avatar, Box, Typography } from "@mui/material";
import { CompanyCardStyle } from "./style";
import { RightDropdownIcon } from "../../assets";

export const CompanyCard = ({
  src = "",
  Textlabel = "",
  descriptionlabel = "",
  arrow = false,
  borderHide = false,
  borderBottom = false,
  endAdornment,
  onClick = () => false,
  selected = false
}) => {
  return (
    // Main card and images
    <Box sx={selected ? CompanyCardStyle.topbox2 : CompanyCardStyle.topbox(borderHide, borderBottom)} onClick={onClick}>
      <Box sx={CompanyCardStyle.boximage}>
        <Avatar sx={CompanyCardStyle.image} variant="rounded" src={src} />
      </Box>
      {/* Card text content */}
      <Box sx={CompanyCardStyle.text}>
        <Typography sx={CompanyCardStyle.maintext}>{Textlabel}</Typography>
        <Typography sx={CompanyCardStyle.subtext}>
          {descriptionlabel}
        </Typography>
      </Box>
      {arrow &&
        <Box>
          {endAdornment}
        </Box>
      }
    </Box>
  );
};
