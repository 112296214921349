import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const StaysCardStyle = {
  stayscardmainbox: {
    borderRadius: borderRadius,
    border: "1px solid #CED3DD",
    backgroundColor: "#FFFF",
    boxShadow: "0px 0px 32px #0000000f",
    cursor: "pointer"
  },
  stayscardimage: {
    borderRadius: borderRadius,
    width: "100%",
    height: "206px"
  },
  staysCardRadioButton: {
    width: "100%",
    mt: "6px",
    ml: '10px',
  },
  dividerStyle: {
    height: "206px",
    border: "1px solid #E4E8EE",
    mt: "18px"
  },
  checkboxLabel: {
    marginLeft: '',
    marginRight: '',
    '& .MuiFormControlLabel-label': {
      fontSize: "0.875rem",
      fontFamily: SemiBold,
      color: "text.primary",
    }
  },
  stayscardimagebox: {
    padding: "16px 0px 16px 15px"
  },
  roomFacilitiesBox: {
    margin: "16px 16px 16px 16px",
    width: "100%"
  },

  bookingbox: {
    mt: "16px",
    mr: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "93%",
    justifyContent: "space-between"
  },
  tooltip: {
    "& .MuiTooltip-tooltip": {
      color: "#fff",
      maxWidth: 250,
      height: 50,
      alignItems: "center",
      display: "flex",
      fontSize: "0.75rem",
      fontFamily: SemiBold
    }
  },
  iconData: {
    height: "128px",
    overflow: "auto"
  },
  roomName: {
    fontSize: "1.125rem",
    fontFamily: Medium,
    color: "#091B29"
  },
  chipData: {
    backgroundColor: "#EEF9EE",
    width: "165px",
    color: "#5AC782",
    borderRadius: borderRadius
  },
  chipUserIcon: {
    color: "text.success !important",
    backgroundColor: "#EEF9EE",
    mt: 1
  },
  icons: {
    display: "flex",
    alignItems: "center",
    mt: "9px"
  },
  doticongrey: {
    color: "#98A0AC",
    height: "6px",
    width: "6px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  icontext: {
    color: "#98A0AC",
    fontSize: "0.875rem",
    fontFamily: Regular,
    marginLeft: "4px",
    marginRight: "4px",
    marginTop: "2px"
  },
  moreText: {
    mt: "9px",
    color: "#1F74EA",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    cursor: "pointer"
  },
  cancelText: {
    color: "#FF9340",
    fontSize: "0.75rem",
    fontFamily: Regular
  },
  cancelTextbox: {
    display: "flex"
  },
  errorIcon: {
    ml: "6px",
    color: "#FF9340",
    height: "16px",
    width: "16px"
  },
  nightText: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: Regular,
    pt: 2
  },
  amountText: {
    color: "#091B29",
    fontSize: "1.25rem",
    fontFamily: Medium,
  },
  taxText: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: Medium,
  },
  staysCardButton: {
    backgroundColor: "#1F74EA",
    height: "41px",
    borderRadius: "21px",
    width: "100px",
    color: "#fff",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    border: "1px solid #E4E8EE",
    padding: '10px 0px',
    "&:hover": {
      backgroundColor: "#1F74EA"
    }
  },
  stayCardButtonBox: {
    bottom: "0px",
    mb: "12px"
  },
  gridCard: {
    display: "flex"
  },
  stayscardimageboxMob: {
    padding: "0px",
    position: "relative"
  },
  stayscardimageMob: {
    borderRadius: borderRadius,
    width: "100%",
    height: "165px",
    backgroundSize: "cover"

  },
  roomNameMob: {
    fontSize: "1rem",
    fontFamily: Medium,
    color: "#091B29"
  },
  titleSeperation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  roomFacilitiesBoxMob: {
    padding: "8px 16px 0px 16px",
    width: "100%"
  },
  moreTextMob: {
    color: "#1F74EA",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    cursor: "pointer"
  },
  chipDataMob: {
    backgroundColor: "#EEF9EE !important",
    maxWidth: "165px",
    color: "#5AC782 !important",
    borderRadius: borderRadius
  },
  chipUserIconMob: {
    color: "text.success !important",
    backgroundColor: "#EEF9EE",
    mt: 1
  },
  icontextMob: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: Regular,
    marginLeft: "6px"
  },
  iconsMob: {
    display: "flex",
    alignItems: "baseline",
    mt: "9px"
  },
  doticongreyMob: {
    color: "#98A0AC",
    height: "6px",
    width: "6px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  staysCardRadioButtonMob: {
    width: "100%",
    padding: "16px"
  },
  bookingboxMob: {
    padding: "16px 16px",
    display: "flex",
    // flexDirection: "column",
    alignItems: "flex-end",
    // height: "93%",
    justifyContent: "space-between"
  },
  nightTextMob: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: Regular,
    // pt: 2
  },
  amountTextMob: {
    color: "#091B29",
    fontSize: "1.25rem",
    fontFamily: Medium,
  },
  taxTextMob: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: SemiBold
  },
  cancelTextMob: {
    color: "#fff",
    fontSize: "0.75rem",
    fontFamily: Regular
  },
  successTextboxMob: {
    display: "flex",
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "text.light",
    borderRadius: "50px",
    padding: "4px 8px"
  },
  cancelTextboxMob: {
    display: "flex",
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "error.light",
    borderRadius: "50px",
    padding: "4px 8px"
  },
  errorIconMob: {
    ml: "6px",
    color: "#fff",
    height: "16px",
    width: "16px"
  },
  tooltipMob: {
    "& .MuiTooltip-tooltip": {
      color: "#fff",
      maxWidth: 250,
      height: 50,
      alignItems: "center",
      display: "flex",
      fontSize: "0.75rem",
      fontFamily: SemiBold
    }
  },
  roomsNotavailable: {
    fontSize: "1.25rem",
    fontFamily: Medium,
    color: "primary.light1",
    marginBottom: "2px",
    textAlign: {
      xs: "center",
      lg: "right",
      md: "right",
      sm: "right"
    }
  },
  roomsNotavailableStubText: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: "primary.light1",
    textAlign: {
      xs: "center",
      lg: "right",
      md: "right",
      sm: "right"
    }
  },
  roomsNotAvailableBoxMob: {
    padding: "16px 16px",
  },
  DividerMob: {
    width: "90%",
    borderBottom: "1px solid #CED3DD",
    // backgroundColor: "#CED3DD",
    padding: "8px",
    margin: "auto"
  },
  DividerMobRooms: {
    width: "90%",
    borderBottom: "1px solid #CED3DD",
    // backgroundColor: "#CED3DD",
    // paddingBottom: "8px",
    margin: "auto"

  }
};
