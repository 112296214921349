import * as React from "react"

export const SearchIconBooking = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <defs>
      <linearGradient
        id="b"
        x1={0.153}
        y1={16.034}
        x2={0.854}
        y2={15.333}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#a3ffff" />
        <stop offset={0.223} stopColor="#9dfbff" />
        <stop offset={0.53} stopColor="#8bf1ff" />
        <stop offset={0.885} stopColor="#6ee0ff" />
        <stop offset={1} stopColor="#63daff" />
      </linearGradient>
      <linearGradient
        id="c"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
      <radialGradient
        id="a"
        cx={-1.016}
        cy={-1.006}
        r={2.476}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.693} stopColor="#006185" />
        <stop offset={0.921} stopColor="#35c1f1" />
      </radialGradient>
    </defs>
    <g data-name="Group 110513" transform="translate(-108 -810)">
      <rect
        data-name="Rectangle 56286"
        width={64}
        height={64}
        rx={8}
        transform="translate(108 810)"
        fill="#f1f7ff"
      />
      <g transform="translate(120 822)">
        <path
          data-name="Path 100112"
          d="m29.586 26.758-2.829 2.829 6.3 6.3a.4.4 0 0 0 .566 0l2.262-2.262a.4.4 0 0 0 0-.566Z"
          fill="#199be2"
        />
        <path
          data-name="Path 100113"
          d="m30.894 28.065-2.829 2.829 3.506 3.506 2.829-2.829Z"
          transform="translate(-4.813 -4.813)"
          fill="url(#a)"
        />
        <circle
          data-name="Ellipse 129669"
          cx={12.8}
          cy={12.8}
          r={12.8}
          transform="translate(4 4)"
          fill="url(#b)"
        />
        <circle
          data-name="Ellipse 129670"
          cx={1.6}
          cy={1.6}
          r={1.6}
          transform="translate(8.8 15.2)"
          fill="url(#c)"
        />
        <circle
          data-name="Ellipse 129671"
          cx={1.6}
          cy={1.6}
          r={1.6}
          transform="translate(15.2 15.2)"
          fill="url(#c)"
        />
        <circle
          data-name="Ellipse 129672"
          cx={1.6}
          cy={1.6}
          r={1.6}
          transform="translate(21.6 15.2)"
          fill="url(#c)"
        />
      </g>
    </g>
  </svg>
)

