import * as React from "react";
export const Furnishing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g id="Group_112948" data-name="Group 112948" transform="translate(0.389)">
      <rect
        id="Rectangle_56050"
        data-name="Rectangle 56050"
        width={25}
        height={25}
        transform="translate(-0.389)"
        fill="none"
      />
      <path
        id="icons8-armchair"
        d="M7.842,6A2.747,2.747,0,0,0,5.105,8.737v1.484A2.326,2.326,0,0,0,3,12.526a2.3,2.3,0,0,0,1.684,2.187v2.866a3.166,3.166,0,0,0,1.684,2.789v1a.632.632,0,1,0,1.263,0v-.642c.07,0,.139.011.211.011h8.007c.069,0,.136-.006.2-.011v.642a.632.632,0,1,0,1.263,0v-1a3.164,3.164,0,0,0,1.691-2.784s0,0,0,0l0-2.873a2.3,2.3,0,0,0,1.68-2.186,2.326,2.326,0,0,0-2.105-2.305V8.737A2.747,2.747,0,0,0,15.842,6Zm0,1.263h8a1.465,1.465,0,0,1,1.474,1.474v1.551a2.748,2.748,0,0,0-2.105,2.66v1.311A2.287,2.287,0,0,0,14.158,14H9.526a2.287,2.287,0,0,0-1.053.258V12.947a2.748,2.748,0,0,0-2.105-2.66V8.737A1.465,1.465,0,0,1,7.842,7.263Zm2.526,2.947a.632.632,0,1,0,.632.632A.632.632,0,0,0,10.368,10.211Zm2.947,0a.632.632,0,1,0,.632.632A.632.632,0,0,0,13.316,10.211Zm-8,1.263h.317a.632.632,0,0,0,.163.006,1.461,1.461,0,0,1,1.414,1.468v4.632a.632.632,0,0,0,.632.632h8a.632.632,0,0,0,.632-.632V12.947a1.461,1.461,0,0,1,1.414-1.468.632.632,0,0,0,.16-.006h.32a1.053,1.053,0,0,1,0,2.105.632.632,0,0,0-.63.632l0,3.374a1.885,1.885,0,0,1-1.895,1.888H7.842a1.885,1.885,0,0,1-1.895-1.895V14.21a.632.632,0,0,0-.632-.632,1.053,1.053,0,1,1,0-2.105Zm4.211,3.789h4.632a1.043,1.043,0,0,1,1.053,1.053v.632H8.474v-.632A1.043,1.043,0,0,1,9.526,15.263Z"
        transform="translate(0.352 -1.5)"
        fill="#4e5a6b"
      />
    </g>
  </svg>
);
