
import { borderRadius } from "../../../utils";
import { Medium, Regular, SemiBold } from "../../../utils/font";

export const shortlistedStyle = {
    maincard: {
        height: "125px",
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        display: "flex",
        position: "relative",
        border: "1px solid #E4E8EE",
        boxShadow: 3,
        width: "100%",
    },
    image: {
        height: "125px",
        width: "100%",
        objectFit: "cover",
        borderRadius: borderRadius,
    },
    maintext: {
        padding: "12px",
        width: "100%",
    },
    smallicons: {
        display: "flex",
        alignItems: "center"
    },
    textheader: {
        color: "text.default",
        fontSize: "0.75rem",
        borderRadius: borderRadius,
        fontFamily: Medium,
        backgroundColor: "#5AC782",
        height: "21px"

    },
    textexp: {
        color: "text.default",
        fontSize: "0.75rem",
        borderRadius: borderRadius,
        fontFamily: Medium,
        backgroundColor: "#CED3DD",
        height: "21px"
    },
    doticon: {
        color: "#4E5A6B",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    units: {
        display: "flex",
        alignItems: "center",
        mt: "2px"
    },
    unittext: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

    },
    textcontent: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },

    icons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: "4px"

    },
    month: {
        fontFamily: Regular,
        fontSize: "1rem !important",

    },
    rupeestext: {
        fontSize: "1rem !important",
        fontFamily: Medium,
        color: "#091B29"
    },
    bedicon: {
        height: "20px",
        width: "20px"
    },
    texthead: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    headerText: {
        mt: "10px",
        display: "flex",
        justifyContent: "space-between"
    },
    amt: {
        fontFamily: Medium,
        fontSize: "0.75rem",
        color: "text.default",
        backgroundColor: "#000",
        height: "24px",
        maxWidth: "122px",
        width: "auto",
        padding: "8px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: "15px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"

    },
    month1: {
        fontFamily: Regular
    },
    pricebox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    imgChip: {
        position: "absolute",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        backgroundColor: "#30C4AB",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        right: 0,
        left: 0,
        textAlign: "center",
        margin: "0px auto",
        width: "80%",
        top: "8px",
        zIndex: "99",
        borderRadius: "50px",
        padding: "4px 8px"
    },
    texthead1: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        '@media screen and (min-width: 320px)': {
            width: "60%"
        },
        '@media screen and (min-width: 375px)': {
            width: "80%"
        },
        '@media screen and (min-width: 425px)': {
            width: "90%"
        },
        // maxWidth:{
        //    xs: "60px",
        // },
        // width:"100%"
    },
    unittext1: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth:{
        //     xs: "60px",
        //  },
        //  width:"100%"

    },
    icontext: {
        color: "#98A0AC",
        fontSize: "0.875rem",
        fontFamily: Regular,
        marginLeft: "4px",
        marginRight: "-10px",
        marginTop: "2px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

    },
    doticongrey: {
        color: "#CED3DD",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
    },

}
