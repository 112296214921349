import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const StaysMapCardStyle = {
    imageBox: {
        position: "relative"
    },
    image: {
        height: "166px",
        width: "100%",
        borderRadius: borderRadius
    },
    chip1: {
        position: "absolute",
        m: "12px 0px 0px 16px",
        backgroundColor: "#30C4AB",
        height: "24px",
        padding: "4px",
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
    },
    chip2: {
        position: "absolute",
        m: "12px 16px 0px 0px",
        right: 0,
        backgroundColor: "text.primary",
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        borderRadius: "15px",
        height: "24px",
        padding: "4px 12px 4px 12px"

    },
    month: {
        fontFamily: Regular,

    },
    mainBox: {
        backgroundColor: "primary.contrastText",
        // height: "392px",
        borderRadius: borderRadius,
        border: "1px solid #CED3DD"
    },
    text: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    textBox: {
        m: "4px 12px 12px 16px"
    },
    subtext: {
        color: "text.tertiary",
        fontSize: "0.875rem",
        fontFamily: Regular,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        mt: "4px"
    },
    ratingtext: {
        color: "text.tertiary",
        fontSize: "0.75rem",
        fontFamily: Regular,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    CheckIcon: {
        height: "16px",
        width: "16px",
        backgroundColor: "#5AC782",
        borderRadius: "15px",
        color: "text.default"
    },
    rating: {
        "& .MuiSvgIcon-root": {
            height: "14px",
            width: "14px"
        },
    },
    ratingBox: {
        display: "flex",
        alignItems: "center",
        mt: "16px",
        mb: "6px"
    },
    ratingBox1: {
        alignItems: "center",
        mt: "16px",
        mb: "6px"
    },
    iconBox: {
        display: "flex",
        mt: "10px",
    },
    canceltext: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
        ml: "6px"
    },
    roombox: {
        borderRadius: "10px",
        backgroundColor: "#F1F7FF",
        p: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px"
    },
    availabletext: {
        fontSize: "0.75rem",
        fontFamily: Medium,
        color: "#78B1FE",
        ml: "4px"
    },
    bed: {
        mt: "2px",
        color: "#78B1FE"
    },
    bb: {
        mt: "16px",
        display: "flex",
        justifyContent: "space-between"
    },
    roundBox: {
        backgroundColor: "primary.contrastText",
        borderRadius: "21px",
        width: "40px",
        height: "40px",
        border: "1px solid #1F74EA"
    },
    arrow: {
        height: "14px",
        margin: "12px",
        width: "14px",
        color: "#1F74EA"
    },
    bottombox: {
        display: "flex",
        justifyContent: "space-between"
    }

}