import * as React from "react"

export function PropertyArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={50}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 110377">
      <g filter="url(#a)">
        <rect
          data-name="Rectangle 56186"
          width={32}
          height={32}
          rx={16}
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <g data-name="Group 110379">
        <path
          data-name="Path 100053"
          d="M26.95 17.2a.7.7 0 0 0 0 .972l4.2 4.326-4.2 4.329a.7.7 0 0 0 0 .972.652.652 0 0 0 .943 0l4.667-4.813a.7.7 0 0 0 0-.972L27.888 17.2a.652.652 0 0 0-.943 0Z"
          fill="#091b29"
        />
        <path
          data-name="Line 1866"
          fill="none"
          stroke="#091b29"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M31.25 22.5H17"
        />
      </g>
    </g>
  </svg>
  )
}

