import * as React from "react"

const ParkIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 111271">
            <path
                data-name="icons8-lake (1)"
                d="M11.751 2.03a1.621 1.621 0 0 0-1.462.9.864.864 0 0 0-.037.084l-1.26 3.537a1.621 1.621 0 0 0 1.47 2.3h.488v1.833a.788.788 0 1 0 1.575 0V8.846h.488a1.621 1.621 0 0 0 1.473-2.3l-1.259-3.532a.864.864 0 0 0-.038-.087 1.621 1.621 0 0 0-1.438-.897Zm6.812 1.567a2.887 2.887 0 0 0-.787 5.662v2.475a.788.788 0 1 0 1.575 0V9.259a2.887 2.887 0 0 0-.787-5.662ZM5.9 9.373a4.288 4.288 0 0 0-4.4 4.287v1.749a6.57 6.57 0 0 0 6.563 6.563H18.3a4.2 4.2 0 0 0 0-8.4h-4.112a6.244 6.244 0 0 1-4.971-2.485A4.288 4.288 0 0 0 5.9 9.373Zm-.226 5.773H7.8a.525.525 0 0 1 0 1.05H5.7a.525.525 0 0 1-.022-1.05Zm9.45 1.575h2.126a.525.525 0 0 1 0 1.05h-2.1a.525.525 0 0 1-.022-1.05Zm-5.775 1.575h2.122a.525.525 0 0 1 0 1.05h-2.1a.525.525 0 1 1-.022-1.05Z"
                fill={props?.color ?? "#4e5a6b"}
            />
        </g>
    </svg>
)

export default ParkIcon
