import * as React from "react";
export const ConformIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.16}
        y1={0.16}
        x2={0.849}
        y2={0.849}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#21ad64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
    </defs>
    <g
      id="Group_111300"
      data-name="Group 111300"
      transform="translate(-923 -810)"
    >
      <rect
        id="Rectangle_56289"
        data-name="Rectangle 56289"
        width={64}
        height={64}
        rx={8}
        transform="translate(923 810)"
        fill="#e8ffe2"
      />
      <g id="icons8-verified-account" transform="translate(935 822)">
        <path
          id="Path_100114"
          data-name="Path 100114"
          d="M21.865,4.487,23.857,5.6l2.284.028A3.814,3.814,0,0,1,29.372,7.5l1.166,1.964L32.5,10.628a3.811,3.811,0,0,1,1.865,3.23l.028,2.284,1.118,1.992a3.814,3.814,0,0,1,0,3.73L34.4,23.857l-.028,2.284a3.814,3.814,0,0,1-1.865,3.23l-1.964,1.166L29.372,32.5a3.811,3.811,0,0,1-3.23,1.865l-2.284.028-1.992,1.118a3.814,3.814,0,0,1-3.73,0L16.143,34.4l-2.284-.028a3.814,3.814,0,0,1-3.23-1.865L9.462,30.538,7.5,29.372a3.811,3.811,0,0,1-1.865-3.23L5.6,23.857,4.487,21.865a3.814,3.814,0,0,1,0-3.73L5.6,16.143l.028-2.284A3.814,3.814,0,0,1,7.5,10.628L9.462,9.462,10.628,7.5a3.811,3.811,0,0,1,3.23-1.865L16.143,5.6l1.992-1.118A3.814,3.814,0,0,1,21.865,4.487Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_100115"
          data-name="Path 100115"
          d="M28.138,16.055,20,24.193l-4.138-4.138a1.6,1.6,0,0,0-2.262,0l-1.131,1.131a1.6,1.6,0,0,0,0,2.262l6.4,6.4a1.6,1.6,0,0,0,2.262,0l10.4-10.4a1.6,1.6,0,0,0,0-2.262L30.4,16.055A1.6,1.6,0,0,0,28.138,16.055Z"
          transform="translate(-1.6 -2.317)"
          opacity={0.05}
        />
        <path
          id="Path_100116"
          data-name="Path 100116"
          d="M19.251,29.666l-6.4-6.4a1.2,1.2,0,0,1,0-1.7l1.131-1.131a1.2,1.2,0,0,1,1.7,0l4.421,4.42,8.42-8.42a1.2,1.2,0,0,1,1.7,0l1.131,1.131a1.2,1.2,0,0,1,0,1.7l-10.4,10.4A1.2,1.2,0,0,1,19.251,29.666Z"
          transform="translate(-1.7 -2.417)"
          opacity={0.07}
        />
        <path
          id="Path_100117"
          data-name="Path 100117"
          d="M19.635,29.483l-6.4-6.4a.8.8,0,0,1,0-1.131l1.131-1.131a.8.8,0,0,1,1.131,0l4.7,4.7,8.7-8.7a.8.8,0,0,1,1.131,0l1.131,1.131a.8.8,0,0,1,0,1.131l-10.4,10.4A.8.8,0,0,1,19.635,29.483Z"
          transform="translate(-1.8 -2.517)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
