import * as React from "react"

export const ParkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111092">
      <path
        data-name="icons8-lake (1)"
        d="M15.668 2.706a2.162 2.162 0 0 0-1.95 1.195 1.153 1.153 0 0 0-.049.116l-1.68 4.715a2.161 2.161 0 0 0 1.96 3.058h.651v2.45a1.05 1.05 0 1 0 2.1 0v-2.45h.651a2.161 2.161 0 0 0 1.965-3.062l-1.679-4.711a1.153 1.153 0 0 0-.051-.116 2.162 2.162 0 0 0-1.918-1.195ZM24.75 4.79a3.85 3.85 0 0 0-1.05 7.55v3.3a1.05 1.05 0 1 0 2.1 0v-3.3a3.85 3.85 0 0 0-1.05-7.55Zm-16.878 7.7A5.717 5.717 0 0 0 2 18.212v2.332a8.76 8.76 0 0 0 8.75 8.75H24.4a5.6 5.6 0 1 0 0-11.2h-5.482a8.325 8.325 0 0 1-6.628-3.313 5.717 5.717 0 0 0-4.417-2.285Zm-.3 7.7H10.4a.7.7 0 1 1 0 1.4H7.6a.7.7 0 1 1-.029-1.4Zm12.6 2.1H23a.7.7 0 1 1 0 1.4h-2.8a.7.7 0 0 1-.029-1.4Zm-7.7 2.1H15.3a.7.7 0 0 1 0 1.4h-2.8a.7.7 0 1 1-.029-1.4Z"
        fill="#5ac782"
      />
    </g>
  </svg>
)


