import { SvgIcon } from "@mui/material"
import * as React from "react"

export const CancelIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#091b29",
      ...rootStyle,
    };
    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <g data-name="Group 110495">
          <path
            data-name="icons8-delete (3)"
            d="M17.366 3.999a.617.617 0 0 0-.43.19l-5.938 5.938L5.06 4.189a.617.617 0 1 0-.872.872l5.938 5.938-5.938 5.937a.617.617 0 1 0 .872.872l5.938-5.938 5.938 5.938a.617.617 0 1 0 .872-.872l-5.938-5.938 5.937-5.937a.617.617 0 0 0-.442-1.062Z"
            fill="currentColor"
          />
        </g>
      </SvgIcon>
    ) 
}

