import React from "react";
import SearchLocationInput from './search';
import { Box } from "@mui/material";
import { locationStyle } from "./style";

export const LocationParent = ({
    value = {},
    onChange = () => false,
    placeholder = '',
    parent_id = '',
    textFieldComponent,
    fieldStyle = {},
    boxStyle = {},
    label = ""
}) => {
    return (
        <Box sx={{ ...locationStyle.locationBox, ...boxStyle }}>
            <SearchLocationInput
                parent_id={`searchlocation_${parent_id}`}
                value={value?.address}
                handleChange={onChange}
                placeholder={placeholder}
                textFieldComponent={textFieldComponent}
                fieldStyle={fieldStyle}
                label={label}
            />
        </Box>
    );
};


