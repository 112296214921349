import * as React from "react"
export const PeakHourIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={16} {...props}>
    <g data-name="Group 114110" transform="translate(22042 5554)">
      <rect
        width={24}
        height={16}
        fill="#fef2d9"
        data-name="Rectangle 57664"
        rx={8}
        transform="translate(-22042 -5554)"
      />
      <path
        fill="#f5aa00"
        d="M-22031.949-5540a.274.274 0 0 1-.263-.356l1.385-4.54h-2.9a.276.276 0 0 1-.216-.448l5.266-6.553a.276.276 0 0 1 .481.248l-1.297 4.546h2.789a.277.277 0 0 1 .216.448l-5.244 6.552a.274.274 0 0 1-.217.103Z"
        data-name="Group 114100"
      />
    </g>
  </svg>
)

