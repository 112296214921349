import { Box, IconButton, InputAdornment } from '@mui/material';
import { ForgetPasswordStyle } from './style'
import { CustomButton, TextBox } from "../../../components";
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { AlertContext } from '../../../contexts';
import { AlertProps } from '../../../utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SignInStyle } from '../style';
import { MailForgot } from '../../../assets';

const initialState = {
    emailId: "",
    password: "",
    code: "",
    showPassword: false,
    error: {
        emailId: "",
        password: "",
        code: ""
    }
}


export const ForgetPassword = ({
    close = () => false,
    t=()=> false
}) => {
    const alert = React.useContext(AlertContext);
    const [data2, setData2] = React.useState({ ...initialState })
    const [newPass, setNewpass] = React.useState(false)

    const handleClickShowPassword = () => {
        updateData("showPassword", !data?.showPassword)
    };
    // form validations
    const validate2 = () => {
        var isValid = true;
        const error = data?.error;
        if (data?.emailId.length === 0) {
            isValid = false;
            error.emailId = "Email Id is Required";
        }
        if (data?.password?.length === 0) {
            isValid = false;
            error.password = "Password is Required";
        }
        setData({ ...data, error });
        return isValid;
    };
    const [data, setData] = React.useState({
        emailId: "",
        error: {
            emailId: ""
        }
    })

    const updateData = (key, value) => {
        let error = data.error
        error[key] = ""
        setData({ ...data, [key]: value, error })
    }
    const updateData2 = (key, value) => {
        let error = data2.error
        error[key] = ""
        setData2({ ...data2, [key]: value, error })
    }
    const validate = () => {
        var isValid = true;
        const error = data?.error;
        if (data?.emailId.length === 0) {
            isValid = false;
            error.emailId = "Email Id is Required";
        }

        setData({ ...data, error });
        return isValid;
    };
    const forgotPassword = async () => {
        if (validate()) {
            Auth.forgotPassword(data?.emailId)
                .then((data) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: 'code sent to your email id',
                    });
                    setNewpass(true)
                })
                .catch((err) => {
                    return alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: 'User Not Found',
                    });
                    // console.log(err);
                    // alert.setSnack({
                    //     ...alert,
                    //     open: true,
                    //     severity: AlertProps.severity.error,
                    //     msg: 'error',
                    // });
                });
        }
    }

    const Login = async () => {
        if (validate2()) {
            try {
                await Auth.forgotPasswordSubmit(
                    data2.emailId,
                    data2.code,
                    data2.password
                );
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: 'New password created successfully',
                });
                close()
            } catch (error) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: error?.message ?? 'error',
                });
            }
        } else {
            return false
        }
    }

    return (
        <>
            {newPass ?
                <Box sx={SignInStyle.mainBox}>
                    {/* input form name email & password  */}
                    <Box sx={SignInStyle.inputBarSx}>
                        <TextBox
                            isSearch
                            fieldStyle={SignInStyle.email}
                            label={t("Email ID")}
                            placeholder={("Enter email ID")}
                            type={"emailId"}
                            helperText={data2?.error?.emailId}
                            value={data2?.emailId}
                            onChange={(e) => updateData2("emailId", e.target.value)}
                            error={data2?.error?.emailId}
                        />
                    </Box>
                    <Box sx={SignInStyle.inputBarSx}>
                        <TextBox
                            isSearch
                            fieldStyle={SignInStyle.email}
                            label={t("Confirmation code")}
                            placeholder={("Enter Confirmation code")}
                            type={"text"}
                            helperText={data2?.error?.code}
                            value={data2?.code}
                            onChange={(e) => updateData2("code", e.target.value)}
                            error={data2?.error?.code}
                        />
                    </Box>
                    <Box sx={SignInStyle.inputBarSx}>
                        <TextBox
                            isSearch
                            fieldStyle={SignInStyle.email}
                            label={t("Password")}
                            placeholder={("Enter your password")}
                            type={data2?.showPassword ? 'text' : 'password'}
                            value={data2?.password}
                            helperText={data2?.error?.password}
                            error={data2?.error?.password}
                            onChange={(e) => updateData2("password", e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {data2?.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Box>

                    {/*create account button  */}
                    <CustomButton
                        variant="outlined"
                        size="medium"
                        label={t("Create New password")}
                        rootStyle={SignInStyle.button}
                        onClick={() => Login()}
                    >
                    </CustomButton>

                </Box> :
                <Box sx={ForgetPasswordStyle.mainBox}>
                    <Box sx={ForgetPasswordStyle.inputBarSx}>
                        <Box sx={ForgetPasswordStyle.mailIcon}>
                            <MailForgot />
                        </Box>
                        <TextBox
                            isSearch
                            fieldStyle={ForgetPasswordStyle.email}
                            label={t("Email ID")}
                            placeholder={("Enter email ID")}
                            type={"emailId"}
                            helperText={data?.error?.emailId}
                            value={data?.emailId}
                            onChange={(e) => updateData("emailId", e.target.value)}
                            error={data?.error?.emailId}
                        />
                    </Box>
                    {/*  button  */}
                    <CustomButton
                        variant="outlined"
                        size="medium"
                        label={t("Get the code")}
                        rootStyle={ForgetPasswordStyle.button}
                        onClick={forgotPassword}
                    />
                </Box>
            }

        </>
    )
}