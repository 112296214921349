import { borderRadius } from "../../utils";
import { Regular, SemiBold } from "../../utils/font";

export const InsideCardStyles = {
    parent: (props) => {
        return {
            backgroundColor: "#F2F4F7",
            borderTopLeftRadius: props?.data?.borderTopLeftRadius && borderRadius,
            borderBottomLeftRadius: props?.data?.borderBottomLeftRadius && borderRadius,
            borderTopRightRadius: props?.data?.borderTopRightRadius && borderRadius,
            borderBottomRightRadius: props?.data?.borderBottomRightRadius && borderRadius,
            padding: "8px",
            // width: {
            //     sm: "120px",
            //     md: "144px",
            //     lg: "124px",
            //     xl: "144px"
            // },
            width: "100%",
            height: "77px",
            display: "grid",
            alignItems: "center"
        }
    },

    subParent: {
        display: "flex",
        alignItems: "center"
    },
    text: {
        color: "text.tertiary",
        fontSize:  "0.75rem",
        fontFamily: Regular,
        marginInlineStart: "7px"
    },
    subText: {
        color: "text.primary",
        fontSize:  "0.875rem",
        fontFamily: SemiBold
    },
    icon: {
        height: "25px",
        width: "25px"
    }
}

