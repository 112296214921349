export const sliderStyle = {
    right1: {
        position: 'absolute', top: '141px', right: "-16px", zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    right2: {
        position: 'absolute', top: '110px', right: '57px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    right3: {
        position: 'absolute', top: '141px', right: '48px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    right4: {
        position: 'absolute', top: '74px', right: '8px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    right5: {
        position: 'absolute', top: '90px', right: '53px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    left1: {
        position: 'absolute', top: '141px', left: "-10px", zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    left2: {
        position: 'absolute', top: '110px', left: '72px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    left3: {
        position: 'absolute', top: '141px', left: '50px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    left4: {
        position: 'absolute', top: '70px', left: '4px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        },
        transform: "rotate(180deg)"
    },
    left5: {
        position: 'absolute', top: '90px', left: '72px', zIndex: 1, display: {
            xs: "none",
            sm: "block",
            md: "block"
        }
    },
    leftRtl1: {
        position: 'absolute',
        top: '141px',
        left: 5,
        zIndex: 1,
        display: {
            xs: "none",
            sm: "none",
            md: "block"
        }
    },
    leftRtl2: {
        position: 'absolute', top: '110px', left: '72px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "block"
        }
    },
    leftRtl3: {
        position: 'absolute', top: '141px', left: '72px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "block"
        }
    },
    leftRtl4: {
        position: 'absolute',
        top: '70px',
        left: '0px',
        zIndex: 1,
        display: {
            xs: "none",
            sm: "none",
            md: "block"
        },
        transform: "rotate(180deg)"
    },
    leftRtl5: {
        position: 'absolute', top: '90px', left: '72px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "block"
        }
    },
    rightRtl1: {
        position: 'absolute', top: '141px', right: 5, zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "none"
        }
    },
    rightRtl2: {
        position: 'absolute', top: '110px', right: '57px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "none"
        }
    },
    rightRtl3: {
        position: 'absolute', top: '141px', right: '54px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "none"
        }
    },
    rightRtl4: {
        position: 'absolute', top: '74px', right: '0px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block"
        }
    },
    rightRtl5: {
        position: 'absolute', top: '90px', right: '53px', zIndex: 1, display: {
            xs: "none",
            sm: "none",
            md: "none"
        }
    },
}