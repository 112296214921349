import * as React from "react"

export const HospitalICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M13.772 2a3.5 3.5 0 0 0-3.5 3.5v1.591H6.136a3.5 3.5 0 0 0-3.5 3.5V26.5a3.5 3.5 0 0 0 3.5 3.5h7.636a.955.955 0 0 0 .955-.955v-4.136a.637.637 0 0 1 .636-.636h1.273a.637.637 0 0 1 .636.636v4.136a.955.955 0 0 0 .955.955h7.636a3.5 3.5 0 0 0 3.5-3.5V10.591a3.5 3.5 0 0 0-3.5-3.5h-4.136V5.5a3.5 3.5 0 0 0-3.5-3.5Zm-.318 1.909h5.091a1.272 1.272 0 0 1 1.273 1.273v5.091a1.272 1.272 0 0 1-1.273 1.273h-5.091a1.272 1.272 0 0 1-1.273-1.273V5.182a1.272 1.272 0 0 1 1.273-1.273Zm2.545 1.273a.955.955 0 0 0-.955.955v.636h-.636a.955.955 0 1 0 0 1.909h.636v.636a.955.955 0 0 0 1.909 0v-.636h.636a.955.955 0 1 0 0-1.909h-.636v-.637A.955.955 0 0 0 16 5.182Zm-8.272 9.545H9a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H7.727a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637ZM7.727 18.545H9a.637.637 0 0 1 .636.636v1.273A.637.637 0 0 1 9 21.09H7.727a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636h-1.273a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637Zm5.091 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636v-1.272a.637.637 0 0 1 .636-.637ZM7.727 22.364H9a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H7.727a.637.637 0 0 1-.636-.636V23a.637.637 0 0 1 .636-.636Zm15.273 0h1.273a.637.637 0 0 1 .636.636v1.273a.637.637 0 0 1-.636.636H23a.637.637 0 0 1-.636-.636V23a.637.637 0 0 1 .636-.636Z"
      fill="#ff9340"
      data-name="Group 111089"
    />
  </svg>
)


