import { Box, Typography } from "@mui/material";
import React from 'react';
import { LocaitonIMG, TargetIMG } from "../../assets";
import { locationStyle } from "./style";

export const TextFieldAdornment = props => {
    const { value, handleChange, inputRef, placeholder, fieldStyle = {}, onClick = () => false, label = "" } = props
    const language = localStorage.getItem("i18nextLng");

    return (
        <Box sx={{ ...locationStyle.textFeild, ...fieldStyle }}>
            <Box sx={language === "ar" ? locationStyle.locationArabic : locationStyle.location}>
                <LocaitonIMG
                    rootStyle={locationStyle.pointer}
                />
            </Box>
            <Box overflow="hidden" width={"99%"}>
                {label?.length > 0 &&
                    <Box>
                        <Typography sx={locationStyle.text}>
                            {label}
                        </Typography>
                    </Box>
                }
                <Box
                    as='input'
                    sx={locationStyle.input}
                    ref={inputRef}
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                />
            </Box>
            <Box sx={locationStyle.target} onClick={onClick}>
                <TargetIMG
                    rootStyle={locationStyle.pointer}
                />
            </Box>
        </Box>

    )
}
