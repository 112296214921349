import React from "react";
// import { Login } from './login';

class LoginParent extends React.Component {
    render() {
        return <>dfd</>;
    }
}

export default LoginParent;
