import React from 'react'
import { Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BlueTick } from "../../../assets";
import { searchResultsStyles } from "./style";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts";
// import { currencyCalculation } from '../../../utils';
export const SearchResultCard = ({
    header = "",
    rate = "",
    currency = "",
    subheader = "",
    unitsAvailable = "",
    type = "",
    src = "",
    onClick = () => false,
    propertyId = '',
    variant = '',
    searches,
    filters,
    selected,
    revenue_type,
    types,
    t = () => false,
}) => {
    const history = useNavigate();
    const auth = React.useContext(AuthContext);
    // const [getCurrency, setGetCurrency] = React.useState(rate);
    // React.useEffect(() => {
    //     if (auth?.currency && Object.keys(auth?.currency)?.length > 0) {
    //         const updatesCurrency = currencyCalculation(rate, auth?.currency?.amount)
    //         setGetCurrency(updatesCurrency)
    //     }
    //     // eslint-disable-next-line
    // }, [auth?.currency]);

    const onRedirect = () => {
        history(variant === 'resident' ? auth?.clientRoutes?.residentView : auth?.clientRoutes?.commercialView, {
            state: {
                propertyId: propertyId, title: header,
                searches,
                filters,
                selected,
                revenue_type,
                types
            }
        });
    }
    return (
        // Main card  image section
        <Box sx={searchResultsStyles.mainBox} onClick={onRedirect}>
            <Box>
                {/* <Chip sx={searchResultsStyles.chip} label={type} /> */}
                <img src={src ?? "images/house.png"} alt="img" style={searchResultsStyles.img} />
            </Box>
            {/* text content */}
            <Box sx={searchResultsStyles.textbox}>
                {/* <Typography sx={searchResultsStyles.amt} >{`${currency} ${getCurrency}`}<span style={searchResultsStyles.month}>{t("/ Month")}</span></Typography> */}
                <Chip sx={searchResultsStyles.chip} label={type} />
                <Typography sx={searchResultsStyles.title}>{subheader}</Typography>
                <Typography sx={searchResultsStyles.subtitle}>{header}</Typography>
                <Box sx={searchResultsStyles.unitbox}>
                    <Box sx={searchResultsStyles.tick} >
                        <BlueTick />
                    </Box>
                    <Typography sx={searchResultsStyles.units}>{t(`${unitsAvailable} Units available`)}</Typography>
                </Box>
            </Box>
        </Box>
    )
}