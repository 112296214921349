export const dummyData = [
  {
    name: 'Abu Dhabi'
  },
  {
    name: 'Abu Dhabi'
  },
  {
    name: 'Abu Dhabi'
  },
  {
    name: 'Abu Dhabi'
  },

]

export const SearchOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
export const variants = {
  bedroom: [
    "Any", "Studio", 2, 3, '4+'
  ],
  bath: [
    "Any", 1, 2, 3, '4+'
  ]
}
export const Calendar = [
  'Month', 'Year'
]
export const propertyData = ['Aprtment', 'Bunglow', 'Barn', 'Boat', 'Cabin', 'Caravan', 'Aprtment', 'Bunglow', 'Barn', 'Boat', 'Cabin', 'Caravan']
export const detailCard = [
  {
    header: 'Sanctuary Falls, Jumeirah Golf Estate',
    subheader: 'Miraclz Tower by G square',
    buildDate: '2021',
    location: 'Arjan, Dubai',
    sqft: '1250',
    onebhk: '12',
    twobhk: '09',
    unitsAvailable: '25',
    updatedDate: '21st Feb 2023',
    rate: '$ 1,200.00',
    offer: '55%',
    type: 'Apartment'
  },
  {
    header: 'Sanctuary Falls, Jumeirah Golf Estate',
    subheader: 'Miraclz Tower by G square',
    buildDate: '2021',
    location: 'Arjan, Dubai',
    sqft: '1250',
    onebhk: '12',
    twobhk: '09',
    unitsAvailable: '25',
    updatedDate: '21st Feb 2023',
    rate: '$ 1,200.00',
    type: 'Villa'
  },
  {
    header: 'Sanctuary Falls, Jumeirah Golf Estate',
    subheader: 'Miraclz Tower by G square',
    buildDate: '2021',
    location: 'Arjan, Dubai',
    sqft: '1250',
    onebhk: '12',
    twobhk: '09',
    unitsAvailable: '25',
    updatedDate: '21st Feb 2023',
    rate: '$ 1,200.00',
    type: 'Apartment'
  },
  {
    header: 'Sanctuary Falls, Jumeirah Golf Estate',
    subheader: 'Miraclz Tower by G square',
    buildDate: '2021',
    location: 'Arjan, Dubai',
    sqft: '1250',
    onebhk: '12',
    twobhk: '09',
    unitsAvailable: '25',
    updatedDate: '21st Feb 2023',
    rate: '$ 1,200.00',
    offer: '55%',
    type: 'Villa'
  }
]
export const CountryDetails = [
  {
    url: "../images/indiaFlag.svg",
    label: "India",
    code: "in",
    center: ["77.216721", "28.644800"]
  },
  {
    url: "../images/australiaFlag.svg",
    label: "Australia",
    code: "au",
    center: ["151.209900", "-33.865143"]
  },
  {
    url: "../images/saudiFlag.svg",
    code: "sa",
    label: "Saudi Arabia",
    center: ["46.738586", "24.774265"]
  },
  {
    url: "../images/dubaiFlag.svg",
    label: "United Arab Emirates",
    code: "ae",
    center: ["55.296249", "25.276987"]
  },
]





export const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" }
];


// imagelist json
export const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    cols: 2,
  },
];

