import { borderRadius } from "../../utils";

export const StandardImageListStyle = {
    ImageListBox: {

    },
    image: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '100%',
        cursor: "pointer"
    }

}