export const NavbarStyle = {
  root: {
    flexGrow: 1,
    height: "100%"
  },
  content: {
    marginTop: {
      lg: "73px !important",
      md: "73px !important",
      sm: "55px !important",
      xs: "57px !important",

    },
    // height: "100vh",
    height: `calc(100vh - 58px)`,
    overflow: "auto",
    backgroundColor: '#FFFFFF',
    paddingLeft: {
      md: 0
    }
  },

  resContent: {
    // height: `calc(100vh - 58px)`,
    overflow: "auto",
    backgroundColor: '#FFFFFF',
    paddingLeft: {
      md: 0
    }
  },
  topNavbar: {
  },
  sideNavbar: {
    display: {
      md: "none"
    }
  }
}