import React from "react";
import { SignIn } from "./signIn"
import { withTranslation } from 'react-i18next';

class SignInParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <SignIn {...this.props} />;
  }
}
export default withTranslation('signIn')(SignInParent);


