import React from "react";
import { withNavBars } from "../../HOCs";
import { withTranslation } from 'react-i18next';

class StayParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <></>;
  }
}

const props = {
  key: 2
}

export default withNavBars(withTranslation('stayscreen')(StayParent), props);


