import { SvgIcon } from "@mui/material";
import * as React from "react"

export function LocationIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: props?.width ?? 24,
    height: props?.height ?? 24,
    color: props?.color ?? "#98a0ac",
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 108794"
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <path data-name="Rectangle 55731" fill="none" d="M0 0H24V24H0z" />
      <path
        data-name="icons8-place-marker (4)"
        d="M12.6 4a5.6 5.6 0 00-4.256 9.234s2.315 2.711 3.175 3.532a1.574 1.574 0 002.16 0c.972-.927 3.175-3.531 3.177-3.533A5.6 5.6 0 0012.6 4zm0 1.2a4.4 4.4 0 013.344 7.255s-2.29 2.679-3.091 3.443a.352.352 0 01-.5 0c-.661-.631-3.09-3.442-3.092-3.444A4.4 4.4 0 0112.6 5.2zm0 2a2.382 2.382 0 00-1.8.764 2.525 2.525 0 000 3.272 2.5 2.5 0 003.6 0 2.525 2.525 0 000-3.272 2.382 2.382 0 00-1.8-.764zm-4.491 6.977C6.291 14.7 5 15.581 5 16.8c0 2.1 3.823 3.2 7.6 3.2s7.6-1.1 7.6-3.2c0-1.218-1.29-2.1-3.107-2.623-.231.262-.548.62-.9 1.009 1.77.416 2.8 1.09 2.8 1.614 0 .816-2.493 2-6.4 2s-6.4-1.184-6.4-2c0-.524 1.035-1.2 2.808-1.616l-.892-1.007z"
        transform="translate(-1)"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
