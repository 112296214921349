import * as React from "react"

 export const SchoolIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M15.974 3a.952.952 0 0 0-.32.066L2.641 8.144a.952.952 0 1 0 .692 1.773L16 4.974l12.668 4.943a.952.952 0 1 0 .692-1.773L16.346 3.066A.952.952 0 0 0 15.974 3Zm-9.5 8.253a1.9 1.9 0 0 0-1.9 1.9v13.335a1.9 1.9 0 0 0 1.9 1.9h7.335a2.519 2.519 0 0 0 4.374 0h7.335a1.9 1.9 0 0 0 1.9-1.9V13.157a1.9 1.9 0 0 0-1.9-1.9h-5.709a2.857 2.857 0 0 0-2.855 2.852V26.17a.952.952 0 1 1-1.9 0V14.109a2.857 2.857 0 0 0-2.857-2.857Z"
      fill="#78b1fe"
      data-name="Group 111084"
    />
  </svg>
)

