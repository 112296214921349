import { MyBookingCardStyle } from "./style";
import { Box, Typography, Chip, Divider, Avatar, Stack, Grid } from "@mui/material";
import { CustomButton } from "../Button";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import { getTimeWithAmPm } from "../../utils/common";

const MyBookingCard = ({
  url = "",
  startIcon,
  endIcon,
  onView = () => false,
  onCancel = () => false,
  booking_status = "",
  title = "",
  location = "",
  date = "",
  time = "",
  booking_no = '',
  t = () => false,
  val = [],
  propname = "",
  hidden = "",
  description = ""
}) => {

  return (
    <>
      <Box sx={MyBookingCardStyle?.root}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3}>
            <Stack direction={"row"} alignItems={"center"} >
              <Avatar
                src={val?.facility_assets?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"}
                sx={MyBookingCardStyle.image}
              />
              <Stack sx={MyBookingCardStyle?.titleStack}>
                <Typography sx={MyBookingCardStyle.text1}>
                  {title}
                </Typography>
                <Typography sx={MyBookingCardStyle.text2}>
                  {description ?? ""} {propname ? "•" : ""} {propname ?? ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"space-between"}>
              {booking_no &&
                <Typography sx={MyBookingCardStyle.textdate}>{booking_no ?? ""}</Typography>
              }
              {date && <Typography sx={MyBookingCardStyle.textdate}>
                {moment(date).format("DD-MMM-YY")}, {moment(date).format("dddd")}
              </Typography>
              }
              <Typography sx={MyBookingCardStyle.textdate}>
                {/* {time} */}
                {val?.period === "Hourly" ? `${getTimeWithAmPm(val?.booked_slots?.[0]?.[0])} - ${moment(val?.booked_slots?.[val?.booked_slots?.length - 1]?.[1], "hh:mm:ss").add(1, "seconds").format("hh:mm A")}` : `${moment(val?.booking_start_time).format("DD MMM YY")} - ${moment(val?.booking_end_time).utc().format("DD MMM YY")}`}
                

                

                {/* {console.log("val?", val)} */}


                
              </Typography>
              {booking_status === 'Booked' ? (
                <Chip label={"Booked"} sx={MyBookingCardStyle.chipbooked} />
              ) : booking_status === 'Cancelled' ? (
                <Chip
                  label={"Cancelled"}
                  sx={MyBookingCardStyle.chipcancel}
                />
              ) : booking_status === 'Contacted Stay Team' ? <Chip
                label={"Contacted Stay Team"}
                sx={MyBookingCardStyle.chipcontacted}
              /> : null}
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction={"row"} alignItems={"center"} sx={localStorage?.i18nextLng === "ar" ? MyBookingCardStyle?.buttonStacksArabic : MyBookingCardStyle?.buttonStacks}>
              {booking_status === 'Booked' ? (
                <CustomButton
                  variant="outlined"
                  size="medium"
                  label={t("Cancel Booking")}
                  rootStyle={MyBookingCardStyle.button1}
                  onClick={(val) => onCancel(val)}
                  startIcon={startIcon}
                  endIcon={endIcon}
                ></CustomButton>
              ) : ""}
              <CustomButton
                variant="outlined"
                size="medium"
                label={t("View Booking")}
                rootStyle={MyBookingCardStyle.button2}
                onClick={(val) => onView(val)}
                startIcon={startIcon}
                endIcon={endIcon}
                val={val}
              ></CustomButton>
            </Stack>

          </Grid>

        </Grid>
      </Box>
      {hidden && <Divider sx={MyBookingCardStyle.Divider} />}
    </>
  );
};
export default withTranslation()(MyBookingCard)


