export const sortOptions = [
  {
    label: "High-Low Price",
    value: "High-Low",
  },
  { label: "Low-High Price", value: "Low-High" },
]

export const minOptions = [{
  value: 0,
  label: 0
}, {
  value: 20000,
  label: 20000
}, {
  value: 50000,
  label: 50000
}, {
  value: 80000,
  label: 80000
}, {
  value: 100000,
  label: 100000
}
]

export const maxOptions = [{
  value: "Any",
  label: "Any"
}, {
  value: 20000,
  label: 20000
}, {
  value: 50000,
  label: 50000
}, {
  value: 80000,
  label: 80000
}, {
  value: 100000,
  label: 100000
}
]
