import * as React from "react"

export const BathtabIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <g data-name="Group 96235">
        <path
          data-name="icons8-bathtub (1)"
          d="M6.866 3.011a2.552 2.552 0 0 0-2.544 2.544v3.634H3.051a.545.545 0 0 0-.13 1.076l.788 4.592a2.369 2.369 0 0 0 1.707 1.879.545.545 0 0 0 1.053.082h8.057a.545.545 0 0 0 1.053-.082 2.37 2.37 0 0 0 1.712-1.881l.788-4.592a.545.545 0 0 0-.13-1.076H5.416V5.555a1.45 1.45 0 0 1 2.9-.086 1.639 1.639 0 0 0-1.04 1.174.308.308 0 0 0 .302.368h2.572a.308.308 0 0 0 .308-.366 1.638 1.638 0 0 0-1.054-1.18 2.55 2.55 0 0 0-2.538-2.454Zm-2.837 7.267H16.97l-.754 4.394a1.263 1.263 0 0 1-1.172 1.05h-.011a.545.545 0 0 0-.08.008H6.047a.545.545 0 0 0-.1-.008 1.263 1.263 0 0 1-1.165-1.049Z"
          fill="#98a0ac"
          stroke="#98a0ac"
          strokeWidth={0.25}
        />
      </g>
    </g>
  </svg>
)


