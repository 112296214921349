import { Box, Stack, Typography } from "@mui/material";
import { LocationCardStyle } from "./style";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const LocationCard = ({ id = "", url = "", locationlabel = "", propertylabel = "", color = "", onClick = () => false, t=()=>false }) => {
  const language = localStorage.getItem("i18nextLng")
  return (
    <>
      {/* main card and logo */}
      {/* <Box sx={LocationCardStyle.maincard} onClick={() => onClick(id)}>
        <Box sx={{ ...LocationCardStyle.iconbox, backgroundColor: color }}>
          {url}
        </Box>
        <Box sx={LocationCardStyle.textcontent}>
          <Box sx={LocationCardStyle.text}>
            <Typography sx={LocationCardStyle.text1}>{locationlabel}</Typography>
            <Typography sx={LocationCardStyle.text2}>{propertylabel}</Typography>
          </Box>
          <Box sx={LocationCardStyle.arrowicon}>
            {language === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
          </Box>
        </Box>
      </Box> */}
      <Box sx={LocationCardStyle.maincard} onClick={() => onClick(id)}>
        <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
          <Box sx={{ ...LocationCardStyle.iconbox }}>
            {url}
          </Box>
          {/* <Stack spacing={"4px"} marginInlineStart={"12px"} direction={"column"}> */}
            {/* <Typography sx={LocationCardStyle.text1}>15</Typography> */}
            <Typography sx={LocationCardStyle.text2}>{locationlabel}</Typography>
          {/* </Stack>  */}
          <Box flexGrow={1} sx={LocationCardStyle.arrowicon}>
            {language === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
          </Box>
        </Stack>
      </Box>
    </>
  );
};
