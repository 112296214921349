import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const ShortlistCardUnitStyle = {
    maincard: {
        height: "110px",
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        display: "flex",
        position: "relative",
        border: "1px solid #E4E8EE",
    },
    image: {
        height: "100%",
        width: "170px",
        objectFit: "cover",
        borderRadius: borderRadius
    },
    maintext: {
        padding: "12px",
        width: "100%"
    },
    smallicons: {
        display: "flex",
        alignItems: "center"
    },
    textheader: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,

    },
    doticon: {
        color: "#4E5A6B",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    units: {
        display: "flex",
        alignItems: "center",
        padding: "4px 12px 12px 0px"

    },
    unittext: {
        fontSize: "0.75rem !important",
        fontFamily: SemiBold,
        color: "#4E5A6B"

    },
    textcontent: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
    },
    chipdata: {
        position: "absolute",
        backgroundColor: "hsla(170, 61%, 48%, 1)",
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: Medium,
        height: "24px",
        width: "75px",
        left: 0,
        marginTop: "10px",
        marginLeft: "10px",
        "& .MuiChip-label": {
            overflow: "inherit",
            padding: "4px"
        }

    },
    chipdataRtl: {
        position: "absolute",
        backgroundColor: "hsla(170, 61%, 48%, 1)",
        color: "primary.contrastText",
        fontSize: "0.75rem",
        fontFamily: Medium,
        height: "24px",
        width: "75px",
        right: 0,
        marginTop: "10px",
        marginInlineStart: "10px",
        zIndex: 9,
        "& .MuiChip-label": {
            overflow: "inherit",
            padding: "4px"
        }

    },
    icons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"

    },
    month: {
        fontFamily: Regular,
        fontSize: "1rem !important",

    },
    rupeestext: {
        fontSize: "1rem !important",
        fontFamily: Medium,
        color: "#091B29"
    },
    bedicon: {
        height: "20px",
        width: "20px"
    },
    doticongrey: {
        color: "#CED3DD",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    icontext: {
        color: "#4E5A6B",
        fontSize: "0.875rem !important",
        fontFamily: Regular,
        marginLeft: "4px",
        marginRight: "4px",
        marginTop: "2px"
    },
    headerText: {
        display: "flex",
        justifyContent: "space-between"
    }

}
