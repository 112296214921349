import { SvgIcon } from "@mui/material";
import * as React from "react"

export const DeleteIcon = (props) => {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return(
    <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
    <g data-name="Group 111256" transform="translate(-215.5 -10)">
      <rect
        data-name="Rectangle 56948"
        width={24}
        height={24}
        rx={4}
        transform="translate(215.5 10)"
        fill="#ffecec"
      />
      <path
        data-name="icons8-trash (2)"
        d="M227.15 15a2.458 2.458 0 0 0-2.422 2.1h-2.395a.471.471 0 0 0-.168 0h-1.14a.525.525 0 1 0 0 1.05h.749l.881 9.11a1.932 1.932 0 0 0 1.916 1.74h5.158a1.931 1.931 0 0 0 1.916-1.74l.882-9.11h.749a.525.525 0 1 0 0-1.05h-1.14a.525.525 0 0 0-.168 0h-2.4a2.458 2.458 0 0 0-2.418-2.1Zm0 1.05a1.387 1.387 0 0 1 1.35 1.05h-2.7a1.387 1.387 0 0 1 1.35-1.05Zm-4.322 2.1h8.644l-.872 9.009a.868.868 0 0 1-.871.791h-5.158a.869.869 0 0 1-.871-.791Zm3.089 1.742a.525.525 0 0 0-.517.533v5.25a.525.525 0 1 0 1.05 0v-5.25a.525.525 0 0 0-.533-.533Zm2.45 0a.525.525 0 0 0-.517.533v5.25a.525.525 0 1 0 1.05 0v-5.25a.525.525 0 0 0-.533-.533Z"
        fill="#ff4b4b"
      />
    </g>
  </SvgIcon>
  )
 
}

