import { Typography, Chip, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { VacantCardStyle } from "./style";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { BathtabIcon, Floor, HandWashIcon } from "../../assets/components";
import { HotelbedIcon } from "../../assets/components";
import { HomeIcon } from "../../assets/components";
import { CustomButton } from "../Button";
import { HomePlusIcon, WhiteTick } from "../../assets/components";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { withTranslation } from "react-i18next";
import { CarouselSliders } from "../imageCaurosel";

const VacantCard = ({
  startIcon,
  endIcon,
  onShortlist = () => false,
  hotelname = "",
  unit = "",
  src = "",
  sqft = "",
  bed = "",
  room = "",
  bathtab = "",
  price = "",
  month = "",
  available = "",
  type = "",
  id = '',
  isSelected = false,
  floorText = "",
  onClickViewDetails = () => false,
  onClick = () => false,
  t=()=> false,
  variant = "",
  Washroom = "",
  logo = "",
  OpenSingleImageModal = () => false,
  item = {}
}) => {
  return (
    <>
      {/* image and label */}
      {/* <Grid container>
        <Grid>

        </Grid>
      </Grid> */}
      <Box sx={VacantCardStyle.maincard}>
        <Chip label={type} sx={VacantCardStyle.chipdata} />
        <Box sx={VacantCardStyle.image}>
          <CarouselSliders data={item?.unit_assets} logo={logo ?? "images/house.png"} onClick={OpenSingleImageModal} />
        </Box>

        {/* <Box>
          <img
            src={logo ?? "images/house.png"}
            alt="house"
            style={VacantCardStyle.image}
            onClick={() => OpenSingleImageModal(logo)}
          />
        </Box> */}
        {/* text content  */}
        <Box sx={VacantCardStyle.parentBox}>
          <Box sx={VacantCardStyle.maintext}>
            <Typography sx={VacantCardStyle.textheader}>{hotelname}</Typography>
            <Box sx={VacantCardStyle.units}>
              <Typography sx={VacantCardStyle.unittext}>{unit}</Typography>
              <FiberManualRecordIcon sx={VacantCardStyle.doticon} />
              <Typography sx={VacantCardStyle.unittext}>{sqft}</Typography>
            </Box>
            {
              variant === "resident" ?
                <>
                  <Box sx={VacantCardStyle.icons}>
                    <HotelbedIcon />
                    <Typography sx={VacantCardStyle.icontext}>{bed}</Typography>
                    <FiberManualRecordIcon sx={VacantCardStyle.doticongrey} />
                    <BathtabIcon />
                    <Typography sx={VacantCardStyle.icontext}>{bathtab}</Typography>
                    <FiberManualRecordIcon sx={VacantCardStyle.doticongrey} />
                    <HomeIcon />
                    <Typography sx={VacantCardStyle.icontext}>{room}</Typography>
                  </Box>
                </>
                :
                <>
                  <Box sx={VacantCardStyle.icons}>
                    <Floor />
                    <Typography sx={VacantCardStyle.icontext}>{floorText}</Typography>
                    <Typography sx={VacantCardStyle.icontext}>{bathtab}</Typography>
                    <FiberManualRecordIcon sx={VacantCardStyle.doticongrey} />
                    <HandWashIcon />
                    <Typography sx={VacantCardStyle.icontext}>{Washroom}</Typography>
                  </Box>
                </>
            }
          </Box>

          <Stack>
            <Stack direction={"row"} spacing={0.5}>
              <Typography sx={VacantCardStyle.rupeestext}>
                {`${item?.currency_symbol ?? ""} ${price ?? "0"}`}
              </Typography>
              <Typography sx={VacantCardStyle.month}>{`/ ${month}`}</Typography>
            </Stack>

            <Typography sx={VacantCardStyle.availabletext}>
              {available} Available Now
            </Typography>
          </Stack>

          <Box sx={VacantCardStyle.buttonbox}>
            {isSelected ? <CustomButton
              variant="contained"
              size="medium"
              label="Shortlisted"
              rootStyle={VacantCardStyle.selectedbutton}
              onClick={() => onShortlist({
                hotelname,
                unit,
                src,
                sqft,
                bed,
                room,
                bathtab,
                price,
                month,
                available,
                type,
                id,
                isSelected,
                logo
              })}
              startIcon={<WhiteTick rootStyle={VacantCardStyle.whiteTick} />}
              color='primary'
              endIcon={endIcon}
            ></CustomButton> : <CustomButton
              variant="outlined"
              size="medium"
              label={t("Shortlist")}
              rootStyle={VacantCardStyle.mainbutton}
              onClick={() => onShortlist({
                hotelname,
                unit,
                src,
                sqft,
                bed,
                room,
                bathtab,
                price,
                month,
                available,
                type,
                id,
                isSelected,
                logo
              })}
              startIcon={<HomePlusIcon />}
              endIcon={endIcon}
            ></CustomButton>}

            <CustomButton
              variant="outlined"
              size="medium"
              label={t("View Details")}
              rootStyle={VacantCardStyle.subbutton}
              onClick={onClickViewDetails}
              startIcon={startIcon}
              endIcon={<NavigateNextIcon />}
            ></CustomButton>
          </Box>
        </Box>

      </Box>
    </>
  );
};
export default withTranslation()(VacantCard)