import { SvgIcon } from "@mui/material"
import * as React from "react"

export const CloseIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: props.width ? props?.width : 14,
      height: props.height ? props?.height : 14,
      color:"#091b29",
      ...rootStyle,
    };
    return(
<SvgIcon
    xmlns="http://www.w3.org/2000/svg"
        sx={rootSx}
    {...rest}
  >
        <path
      data-name="icons8-delete (3)"
      d="M13.365 0a.617.617 0 0 0-.43.19L6.997 6.128 1.059.19a.617.617 0 1 0-.872.872L6.125 7 .187 12.937a.617.617 0 1 0 .872.872l5.938-5.938 5.938 5.938a.617.617 0 1 0 .872-.872L7.869 6.999l5.937-5.937A.617.617 0 0 0 13.364 0Z"
      fill="#091b29"
    />
  </SvgIcon>
    ) 
}


