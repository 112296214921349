
import * as React from "react";
export const RightCircleArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={50}
    height={50}
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <filter
        id="Rectangle_56186"
        x={0}
        y={0}
        width={50}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} input="SourceAlpha" />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_116082"
      data-name="Group 116082"
      transform="translate(-145 -560)"
    >
      <g
        transform="matrix(1, 0, 0, 1, 145, 560)"
        filter="url(#Rectangle_56186)"
      >
        <rect
          id="Rectangle_56186-2"
          data-name="Rectangle 56186"
          width={32}
          height={32}
          rx={16}
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
      <g
        id="Group_110379"
        data-name="Group 110379"
        transform="translate(-0.25 2.5)"
      >
        <path
          id="Path_100053"
          data-name="Path 100053"
          d="M7.2,4.2a.7.7,0,0,0,0,.972l4.2,4.326L7.2,13.827a.7.7,0,0,0,0,.972.652.652,0,0,0,.943,0l4.667-4.813a.7.7,0,0,0,0-.972L8.138,4.2a.652.652,0,0,0-.943,0Z"
          transform="translate(160.25 570.5)"
          fill="#091b29"
        />
      </g>
    </g>
  </svg>
);

