import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },

});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });
export const usersAPI = createApi({
  reducerPath: "userAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    // Get All User API
    getAllUsers: builder.query({
      query: (payload) => ({ url: `user`, method: "GET" }),
      transformResponse: (response, meta, arg) => {
        if (response.code === 200 && response.is_success) {
          return response.data;
        }
        return [];
      },
    }),

    // Get User API
    getUser: builder.query({
      query: (payload) => ({
        url: `${payload.id ? `/${payload.id}/` : "/"}`,
        method: "GET",
      })
    }),

    // Create User API
    createUser: builder.mutation({
      query: ({ email = "", name = "", country_code = "", contact_number = "" }) => ({
        url: `user/`,
        method: "POST",
        body: { email, name, country_code, contact_number }
      }),
    }),

    // Update User API
    updateUser: builder.mutation({
      query: (payload) => ({
        url: `user/${payload.id}/`,
        method: "PATCH",
        body: payload.body,
      }),
    }),

    // Delete User API
    deleteUser: builder.mutation({
      query: ({ id }) => ({ url: `user/${id}/`, method: "DELETE" }),
    }),

    listData: builder.mutation({
      query: (payload) => ({ url: `api/v1/search/property`, method: "POST", body: payload }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    listSingleData: builder.mutation({
      query: (payload) => ({ url: `api/v1/search/single_property`, method: "POST", body: payload }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    lastSearch: builder.mutation({
      query: (payload) => ({ url: `api/v1/search/last`, method: "POST", body: payload }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    propertyNear: builder.mutation({
      query: (payload) => ({ url: `api/v1/search/near_by`, method: "POST", body: payload }),
      transformResponse: (response, meta, arg) => {
        return response;
      },
    }),

    propertyTypeMaster: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/property_type`,
        method: "POST",
        body: payload
      })
    }),
    propertyTypeMasterNew: builder.mutation({
      query: (payload) => ({
        url: `api/v1/search/by_property_type`,
        method: "POST",
        body: payload
      })
    }),

    unitTypeMaster: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/unit_type`,
        method: "POST",
        body: payload
      })
    }),

    shortlistUnit: builder.mutation({
      query: (payload) => ({
        url: `api/v1/shortlist/unit`,
        method: "POST",
        body: payload
      })
    }),

    shortlistUnitDelete: builder.mutation({
      query: (payload) => ({
        url: `api/v1/shortlist/unit`,
        method: "DELETE",
        body: payload
      })
    }),

    shortlistedUnits: builder.mutation({
      query: (payload) => ({
        url: `api/v1/shortlist/unit_list`,
        method: "POST",
        body: payload
      })
    }),

    unitsDetails: builder.mutation({
      query: (payload) => ({
        url: `api/v1/search/unit`,
        method: "POST",
        body: payload
      })
    }),
    contactSales: builder.mutation({
      query: (payload) => ({
        url: `api/v1/shortlist/contact_sales`,
        method: "POST",
        body: payload
      })
    }),
    popularSearch: builder.mutation({
      query: (payload) => ({
        url: `api/v1/search/popular`,
        method: "POST",
        body: payload
      })
    }),
    clusterSearch: builder.mutation({
      query: (payload) => ({
        url: `api/v1/search/property_location`,
        method: "POST",
        body: payload
      })
    }),
    nearBySearch: builder.mutation({
      query: (payload) => ({
        url: `api/v1/search/location_near_by`,
        method: "POST",
        body: payload
      })
    }),
    countryMaster: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/country`,
        method: "POST",
        body: payload
      })
    }),
    assets: builder.mutation({
      query: (payload) => ({
        url: `api/v1/assets/property`,
        method: "POST",
        body: payload
      })
    }),
    bookingUnits: builder.mutation({
      query: (payload) => ({
        url: `api/v1/booking/unit`,
        method: "POST",
        body: payload
      })
    }),
    category: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/unit_category`,
        method: "POST",
        body: payload
      })
    }),
    currencyMaster: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/currency`,
        method: "POST",
        body: payload
      })
    }),
    amenityCategory: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/amenity_category`,
        method: "POST",
        body: payload
      })
    }),
    putUser: builder.mutation({
      query: (payload) => ({
        url: `api/v1/auth/user_sync`,
        method: "PUT",
        body: payload
      })
    }),
    getOldUserManual: builder.mutation({
      query: (payload) => ({
        url: `api/v1/auth/user_country`,
        method: "POST",
        body: payload
      })
    }),
    areaMetric: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/area_metric`,
        method: "POST",
        body: payload
      })
    }),
    unitCategoryByProperty: builder.mutation({
      query: (payload) => ({
        url: `api/v1/master/unit_category_by_property`,
        method: "POST",
        body: payload
      })
    }),
    singleUnitAsset: builder.mutation({
      query: (payload) => ({
        url: `api/v1/assets/unit`,
        method: "POST",
        body: payload
      })
    }),
    companyGet: builder.mutation({
      query: (payload) => ({
        url: `api/v1/company/get`,
        method: "POST",
        body: payload
      })
    }),

  }),
});

// Export hooks for usage in functional components
export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useListDataMutation,
  useLastSearchMutation,
  useListSingleDataMutation,
  usePropertyTypeMasterMutation,
  usePropertyTypeMasterNewMutation,
  useUnitTypeMasterMutation,
  useShortlistUnitMutation,
  useShortlistUnitDeleteMutation,
  useShortlistedUnitsMutation,
  usePropertyNearMutation,
  useUnitsDetailsMutation,
  useContactSalesMutation,
  usePopularSearchMutation,
  useClusterSearchMutation,
  useNearBySearchMutation,
  useCountryMasterMutation,
  useAssetsMutation,
  useBookingUnitsMutation,
  useCategoryMutation,
  useCurrencyMasterMutation,
  useAmenityCategoryMutation,
  usePutUserMutation,
  useGetOldUserManualMutation,
  useAreaMetricMutation,
  useUnitCategoryByPropertyMutation,
  useSingleUnitAssetMutation,
  useCompanyGetMutation
} = usersAPI;
