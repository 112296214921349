import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { alertStyleCard } from "./style";
import { Stack, Typography } from "@mui/material";
import RedDelete from "../../assets/components/redDelete";
import Myfavourites from "../../assets/components/myFavourites";

export const Alerts = (props) => {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onclose();
    setOpen(false);
  };

  return (
    <Snackbar
      id="main_alert_snackbar"
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      sx={props?.style && alertStyleCard?.favouriteBox}
    >
      <MuiAlert
        id="main_alert"
        severity={props.severity}
        elevation={6}
        variant="filled"
        {...props}
        sx={props?.style && alertStyleCard?.favouriteText}
      >
        <Stack direction={"row"} alignItems={"center"}>
          {props?.style &&
            <Myfavourites />
          }
          <Stack marginInline={2}>
            <Typography>{props.msg}</Typography>
            <Typography sx={alertStyleCard.subText}>{props.submsg}</Typography>
          </Stack>
          {props?.style &&
            <RedDelete />
          }
        </Stack>
      </MuiAlert>
    </Snackbar>
  );
};
