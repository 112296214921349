import * as React from "react"
export const ShortlistTick = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 112449" transform="translate(-1097.5 -431)">
      <rect
        width={40}
        height={40}
        fill="#1f74ea"
        data-name="Rectangle 57326"
        rx={20}
        transform="translate(1097.5 431)"
      />
      <g fill="#fff" data-name="icons8-ok (5)">
        <path d="M1117.5 460.45c-4.935 0-8.95-4.015-8.95-8.95s4.015-8.95 8.95-8.95 8.95 4.015 8.95 8.95-4.015 8.95-8.95 8.95Zm-3.375-9.45a.726.726 0 0 0-.513 1.238l2.25 2.25a.72.72 0 0 0 .513.212.72.72 0 0 0 .513-.212l4.5-4.5a.726.726 0 0 0-1.026-1.025l-3.987 3.987-1.738-1.737a.72.72 0 0 0-.512-.213Z" />
        <path
          fill="#0071f2"
          d="M1117.5 442.6c-4.907 0-8.9 3.993-8.9 8.9s3.993 8.9 8.9 8.9 8.9-3.993 8.9-8.9-3.993-8.9-8.9-8.9m-1.125 12.15a.77.77 0 0 1-.548-.227l-2.25-2.25a.776.776 0 0 1 1.096-1.096l1.702 1.702 3.951-3.952a.77.77 0 0 1 .548-.227.776.776 0 0 1 .549 1.323l-4.5 4.5a.77.77 0 0 1-.548.227m1.125-12.25c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9Zm-1.125 12.15a.673.673 0 0 0 .477-.198l4.5-4.5a.675.675 0 0 0-.955-.954l-4.022 4.023-1.773-1.773a.675.675 0 1 0-.954.954l2.25 2.25a.673.673 0 0 0 .477.198Z"
        />
      </g>
    </g>
  </svg>
)
