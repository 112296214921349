
import React from "react";
import { withNavBars } from "../../HOCs";
import { withTranslation } from 'react-i18next';

class BookingCompletedParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <></>;
  }
}


export default withNavBars(withTranslation('bookingCompleted')(BookingCompletedParent));




