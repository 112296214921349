import * as React from "react"

export const ApartmentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 111261" transform="translate(-691 -150)">
      <rect
        data-name="Rectangle 57032"
        width={40}
        height={40}
        rx={8}
        transform="translate(691 150)"
        fill="#78b1fe"
        opacity={0.15}
      />
      <g data-name="icons8-3-star-hotel (1)">
        <path
          data-name="Path 100393"
          d="M722.333 183h-22.666v-16L711 163l11.333 4Z"
          fill="#199be2"
        />
        <path
          data-name="Path 100394"
          d="m715.933 158.533.533 1.267 1.4.133-1.066.867.333 1.4-1.2-.733-1.2.733.333-1.4-1.067-.867 1.4-.133ZM711 157l.6 1.4 1.533.133-1.2 1 .333 1.467-1.267-.8-1.266.8.333-1.467-1.2-1 1.534-.133Zm-4.934 1.533-.533 1.267-1.4.133 1.067.867-.333 1.4 1.2-.733 1.2.733-.334-1.4 1.067-.867-1.4-.133Z"
          fill="#e52030"
        />
        <path
          data-name="Path 100395"
          d="M722.333 167.667a.665.665 0 0 1-.222-.038L711 163.707l-11.111 3.926a.667.667 0 1 1-.444-1.257l11.333-4a.666.666 0 0 1 .444 0l11.333 4a.667.667 0 0 1-.222 1.3Z"
          fill="#51bbf5"
        />
        <path
          data-name="Rectangle 57121"
          fill="#0078d4"
          d="M699.667 175h22.666v8h-22.666z"
        />
        <path
          data-name="Rectangle 57122"
          fill="#005094"
          d="M709 177h4v5.999h-4z"
        />
        <path data-name="Rectangle 57123" fill="#005094" d="M709 169h4v4h-4z" />
        <path
          data-name="Rectangle 57124"
          fill="#005094"
          d="M715.666 169h4v4h-4z"
        />
        <path
          data-name="Rectangle 57125"
          fill="#005094"
          d="M715.666 177h4v4h-4z"
        />
        <path
          data-name="Rectangle 57126"
          fill="#005094"
          d="M702.333 169h4v4h-4z"
        />
        <path
          data-name="Rectangle 57127"
          fill="#005094"
          d="M702.333 177h4v4h-4z"
        />
      </g>
    </g>
  </svg>
)

 