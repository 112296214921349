import React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { AuthContext, ThemeContext } from "./contexts";
import { Themes } from "./utils";

export const AppTheme = (props) => {
  const i18nextLng = localStorage.getItem("i18nextLng")
  const auth = React.useContext(AuthContext)
  const language = localStorage.getItem("i18nextLng")
  let [theme, setTheme] = React.useState({
    name: Themes.default,
    language: i18nextLng ?? ""
  });

  React.useEffect(() => {
    if (i18nextLng) {
      setTheme({
        ...theme,
        language: i18nextLng ?? ""
      })
    }
    // eslint-disable-next-line
  }, [auth])

  const giveMeTheme = () => {
    let currentThemeJson;

    //Geting the theme json for  the selected theme
    switch (theme.name) {
      case Themes.default:
        currentThemeJson = require("./themes/default.json");
        break;

      case Themes.dark:
        currentThemeJson = require("./themes/dark.json");
        break;

      default:
        currentThemeJson = require("./themes/default.json");
        break;
    }

    //Creating a Theme with json
    let currentTheme = createTheme(currentThemeJson);

    //Making theme work with responsive font size
    currentTheme = responsiveFontSizes(currentTheme);

    return currentTheme;
  };
  
  React.useEffect(() => {
    if (language === 'ar') {
      document.getElementsByTagName("HTML")[0].dir = "rtl";
      // document.getElementsByTagName("HTML")[0].lang = "ar"
    } else {
      document.getElementsByTagName("HTML")[0].dir = "ltr";
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={giveMeTheme()}>{props.children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};