
import * as React from "react"

export const MinusIcon = (props) => {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={33} height={33} {...props}>
      <g data-name="Group 111563" transform="translate(-144 -566)">
        <rect
          data-name="Rectangle 56186"
          width={32}
          height={32}
          rx={16}
          transform="translate(144.5 566.5)"
          fill="#fff"
          stroke="#e4e8ee"
        />
        <path
          data-name="Line 1990"
          fill="none"
          stroke="#091b29"
          strokeWidth={1.5}
          d="M156.5 582.5h8"
        />
      </g>
    </svg>
    )
}


