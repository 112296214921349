import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { StandardImageListStyle } from './style'

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const StandardImageList = ({
  data = [],
  handleImageClick = () => false
}) => {
  //eslint-disable-next-line 
  const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;
  const getVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  return (
    <ImageList
      sx={StandardImageListStyle?.ImageListBox}
      variant="quilted"
      cols={4}
      rowHeight={240}
    >
      {data?.map((item, index) => (


        <ImageListItem key={item?.img} cols={item?.cols || 1} rows={item?.rows || 1}>
          {youtubeRegex.test(item?.img) ?
            <iframe
              title={"myframe"}
              style={StandardImageListStyle.image}
              src={"https://www.youtube.com/embed/" + getVideoId(item?.img)}>
            </iframe>
            :

            <img
              {...srcset(item?.img, 220, item?.rows, item?.cols)}
              alt={item?.title}
              loading="lazy"
              onClick={() => handleImageClick(index)}
            />
          }
        </ImageListItem>
      ))}
    </ImageList>
  );
}


