import React from 'react';
import { TopNavBar } from './topNavbar';
import { withTranslation } from 'react-i18next';

class TopNavbarParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return (
            <TopNavBar {...this?.props}/>
        )
    }
}
export default withTranslation('topNavbar')(TopNavbarParent);

