import { SvgIcon } from "@mui/material"
import * as React from "react"

export const BedroomIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 19.722,
      height: 16.747,
      color:"#4e5a6b",
      ...rootStyle,
    };

    return(
      <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      {...rest}
    >
      <path
        data-name="Rectangle 56315"
        fill="#e4e8ee"
        opacity={0.3}
        d="M.938 6.59h18.34v7.096H.938z"
      />
      <path
        d="M4.197 0a2.72 2.72 0 0 0-2.709 2.709V6.74A2.694 2.694 0 0 0 .01 9.112v5.3a.739.739 0 0 0 0 .24v1.359a.739.739 0 1 0 1.478 0v-.739h16.747v.739a.739.739 0 1 0 1.478 0v-1.359a.739.739 0 0 0 0-.24v-5.3a2.694 2.694 0 0 0-1.478-2.372V2.709A2.72 2.72 0 0 0 15.526 0Zm0 1.478h11.329a1.221 1.221 0 0 1 1.231 1.231V6.4h-.985v-.246a2.72 2.72 0 0 0-2.709-2.709h-1.232a2.683 2.683 0 0 0-1.97.882 2.683 2.683 0 0 0-1.97-.882H6.659A2.72 2.72 0 0 0 3.95 6.157V6.4h-.985V2.709a1.221 1.221 0 0 1 1.232-1.231ZM6.66 4.926h1.231A1.221 1.221 0 0 1 9.12 6.157V6.4H5.428v-.246a1.221 1.221 0 0 1 1.231-1.228Zm5.172 0h1.231a1.221 1.221 0 0 1 1.231 1.231V6.4H10.6v-.246a1.221 1.221 0 0 1 1.231-1.228ZM2.72 7.881h14.283a1.221 1.221 0 0 1 1.231 1.231v4.679H1.488V9.112A1.221 1.221 0 0 1 2.72 7.881Z"
        fill="currentColor"
      />
     </SvgIcon>
    )
  
}

