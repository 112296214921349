import { SvgIcon } from "@mui/material"
import * as React from "react"

export const TargetIMG = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98A0AC",
      ...rootStyle,
    };
    return(
        <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    sx={rootSx}
    {...rest}
  >
    <path
      d="M8.237.25A.545.545 0 0 0 7.7.803v1.3a6.186 6.186 0 0 0-5.605 5.606h-1.3a.545.545 0 1 0 0 1.09h1.3a6.186 6.186 0 0 0 5.606 5.606v1.3a.545.545 0 1 0 1.09 0v-1.3a6.186 6.186 0 0 0 5.606-5.606h1.3a.545.545 0 1 0 0-1.09h-1.3a6.186 6.186 0 0 0-5.606-5.606v-1.3A.545.545 0 0 0 8.237.25Zm-.056 2.922a.545.545 0 0 0 .132 0 5.076 5.076 0 0 1 5.017 5.02.545.545 0 0 0 0 .132 5.076 5.076 0 0 1-5.02 5.017.515.515 0 0 0-.133 0 5.076 5.076 0 0 1-5.017-5.02.545.545 0 0 0 0-.132 5.076 5.076 0 0 1 5.021-5.017Zm.069 3.265a1.813 1.813 0 0 0-1.362.581 1.906 1.906 0 0 0 0 2.473 1.887 1.887 0 0 0 2.723 0 1.906 1.906 0 0 0 0-2.473 1.813 1.813 0 0 0-1.361-.581Zm0 1.09a.654.654 0 0 1 .547.214.819.819 0 0 1 0 1.025.654.654 0 0 1-.547.214.654.654 0 0 1-.547-.214.819.819 0 0 1 0-1.025.654.654 0 0 1 .547-.214Z"
      fill="currentColor"
      stroke="#98a0ac"
      strokeWidth={0.5}
    />
  </SvgIcon>
    )
   
}

