import { SvgIcon } from "@mui/material"
import * as React from "react"

export const AccountIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98a0ac",
      ...rootStyle,
    };
    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <path
          data-name="icons8-account (1)"
          d="M5.6 0a3.5 3.5 0 1 0 3.5 3.5A3.508 3.508 0 0 0 5.6 0Zm0 1.05A2.45 2.45 0 1 1 3.15 3.5 2.442 2.442 0 0 1 5.6 1.05ZM1.575 8.4A1.583 1.583 0 0 0 0 9.975v.42a3.174 3.174 0 0 0 1.648 2.587A7.366 7.366 0 0 0 5.6 14a7.366 7.366 0 0 0 3.952-1.018 3.174 3.174 0 0 0 1.648-2.587v-.42A1.583 1.583 0 0 0 9.625 8.4Zm0 1.05h8.05a.517.517 0 0 1 .525.525v.42a2.133 2.133 0 0 1-1.163 1.7 6.382 6.382 0 0 1-3.387.853 6.382 6.382 0 0 1-3.387-.853 2.133 2.133 0 0 1-1.163-1.7v-.42a.517.517 0 0 1 .525-.525Z"
          fill="currentColor"
        />
      </SvgIcon>
    )
}

