import { Box, Typography } from "@mui/material";
import { SlotsCardStyle } from "./style";
import { PeakHourIcon } from "../../assets";
export const SlotsCard = ({
  booked = false,
  time = "",
  slot = "",
  selection = () => false,
  peakHour = false,
  val = {},
  index = "",
  data = {},
  t = () => false
}) => {
  return (
    <>
      {booked ?
        <Box sx={SlotsCardStyle.bookedcard}>
          <Typography sx={SlotsCardStyle.textbooked}>&#x202a;{time}&#x202c;</Typography>
          <Typography sx={SlotsCardStyle.textbooking}>{t("Booked")}</Typography>
        </Box>
        :
        <>
          {val?.slot_detail?.operational_status === "Under Maintenance" ?
            <Box sx={SlotsCardStyle.underMaintenanceCard}>
              <Typography sx={SlotsCardStyle.textUnderMaintenance}>&#x202a;{time}&#x202c;</Typography>
              <Typography sx={SlotsCardStyle.underMaintenancetext}>{t("Under Maintenance")}</Typography>
            </Box>
            :

            <Box sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.maincardselected : SlotsCardStyle.maincard} onClick={() => selection(val, index)}>
              <Typography sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.textselected : SlotsCardStyle.text}>&#x202a;{time}&#x202c;</Typography>

              {data?.viewFacilityBookingAmenity?.is_overbooking ?
                <Box sx={SlotsCardStyle.textslot}>

                  {/* <Typography sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.texthourselected : SlotsCardStyle.texthour}>{data?.viewFacilityBookingAmenity?.period === "Hourly" ? data?.viewFacilityBookingAmenity
                ?.slot_partition : "24"} {t("Hours")}</Typography>

              <FiberManualRecordIcon sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.doticonselected : SlotsCardStyle.doticon} /> */}

                  <Typography sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.textpersonselected : SlotsCardStyle.textperson}>{val?.slot_detail?.book_count}/{data?.viewFacilityBookingAmenity?.participants_count} {t("Slot available")}</Typography>
                </Box>
                :
                <Box sx={SlotsCardStyle.textslot}>
                  {/* Don't delete it may be used in future */}

                  {/* <Typography sx={data?.selectedSlotIndex?.includes(index) ? SlotsCardStyle.textpersonselected : SlotsCardStyle.textperson}>{slot}/{slot} {t("available")}</Typography> */}
                </Box>
              }


              {peakHour &&
                <Box sx={SlotsCardStyle.peakHourIcon}>
                  <PeakHourIcon />
                </Box>
              }
              {/* {data?.viewFacilityBookingAmenity?.is_overbooking &&
            <Typography sx={SlotsCardStyle.overBookingIcon}>OB</Typography>
          } */}
            </Box>
          }
        </>
      }



    </>
  );
};


