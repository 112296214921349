import { borderRadius } from "../../../utils";

export const imageGridMobilestyle = {
    image: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '180px',
        cursor: "pointer",

    },
    imageLast: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        // width: '85px',
        width:"100%",
        opacity: "12",
        backgroundColor: '#1D1D1D73',
        objectFit: 'cover',
        height: '85px',
        cursor: "pointer",
        position: "relative",
    },
    lastBox: {
        opacity: 12,
        backgroundColor: "#1D1D1D73",
        position: "absolute",
        height: "85px",
        width: "100%",
        zIndex: 9,
        borderRadius: borderRadius,
    },
    imageFirst: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '85px',
        cursor: "pointer",
    },

    last: {
        background: '#00000082',
        position: 'absolute',
        width: '100%',
        height: '180px',
        borderRadius: borderRadius
    },
    text: {
        position: 'absolute',
        top: "44%",
        bottom: '0px',
        left: '0px',
        right: '0px',
        textAlign: 'center',
        color: '#fff',
        fontSize: {
            xl: '2.0rem'
        }
    },
    image2: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '180px',
        cursor: "pointer"

    },
    main: {
        gridTemplateColumns: 'repeat(auto-fill,minmax(280px,1fr)) !important'
    },
    imageCount: {
        position: "relative"
    }
}