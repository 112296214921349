import * as React from "react";
export const RoomAndBed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g id="Group_112948" data-name="Group 112948" transform="translate(-0.025)">
      <rect
        id="Rectangle_56050"
        data-name="Rectangle 56050"
        width={25}
        height={25}
        transform="translate(0.025)"
        fill="none"
      />
      <path
        id="icons8-empty-bed_1_"
        data-name="icons8-empty-bed (1)"
        d="M3.562,7.978A.571.571,0,0,0,3,8.558V19.606a.571.571,0,1,0,1.143,0v-.571H17.857v.571a.571.571,0,1,0,1.143,0V16.558a1.342,1.342,0,0,0-1.333-1.333H9.1v-.381a2.294,2.294,0,0,0-2.286-2.286H5.286a2.214,2.214,0,0,0-1.143.353V8.558a.571.571,0,0,0-.58-.58ZM5.286,13.7H6.809a1.135,1.135,0,0,1,1.143,1.143v.381H4.143v-.381A1.135,1.135,0,0,1,5.286,13.7ZM4.143,16.367H8.43a.572.572,0,0,0,.185,0h9.051a.182.182,0,0,1,.19.19v1.333H4.143Z"
        transform="translate(1.5 -1.578)"
        fill="#4e5a6b"
        stroke="#98a0ac"
        strokeWidth={0.25}
      />
    </g>
  </svg>
);
