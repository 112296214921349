import { Box, Divider, Typography } from "@mui/material";
import { ReviewCardStyle } from "./style";
import Rating from "@mui/material/Rating";

export const ReviewCard = ({
  imgData = [],
  image = "",
  content = "",
  date = "",
  reviewerName = "",
  rating = 0
}) => {
  return (
    <>
      {/* star rating date & paragraph */}
      <Box sx={ReviewCardStyle.reviewCardBox}>
        <Box sx={ReviewCardStyle.headerCardBox}>
          <Rating
            name="size-medium"
            sx={ReviewCardStyle.rating}
            value={rating}
            precision={0.5}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={ReviewCardStyle.reviewCardDivider}
          />
          <Typography sx={ReviewCardStyle.dateText}>{date}</Typography>
        </Box>
        <Typography sx={ReviewCardStyle.paraText}>
          
            <div dangerouslySetInnerHTML={{__html: content}}></div>
          
        </Typography>
        {/* image mapping */}
        {image && (
          <Box sx={ReviewCardStyle.imageBox}>
            {imgData?.map((item, index) => {
              return (
                <>
                  <Box sx={ReviewCardStyle.mainImageBox}>
                    { }
                    <img
                      src={item?.img}
                      alt="house"
                      style={ReviewCardStyle.reviewImage}
                    />
                    {imgData?.length - 1 === index && (
                      <Box sx={ReviewCardStyle.viewMoreBox}>
                        <Box sx={ReviewCardStyle.backgroundBox}>
                          <Typography sx={ReviewCardStyle.viewMoreText} noWrap>
                            View More
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              );
            })}
          </Box>
        )}
        <Typography sx={ReviewCardStyle.reviewerText}>
          - {reviewerName}
        </Typography>
      </Box>
    </>
  );
};
