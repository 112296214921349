import { Box, Typography, Avatar } from "@mui/material";
import React from "react";
import { CountryCardStyle } from "./style";

export const CountryCard = ({ url = "", label = "", country = false, selected = false, label2 = "", selection = () => false, value = '' }) => {
  return (
    <>
      {country ?
        <Box sx={label === selected ? CountryCardStyle.maincardSelectedCountry : CountryCardStyle.maincardsx} onClick={() => selection(value)}>
          <Avatar variant="rounded" src={url}></Avatar>
          <Typography
            sx={country ? CountryCardStyle.text : CountryCardStyle.textlanguage}
          >
            {label}
          </Typography>
        </Box>
        :
        <Box sx={label === selected ? CountryCardStyle.maincardSelectedLanguage : CountryCardStyle.cardsx} onClick={() => selection(value)} >
          <Typography sx={CountryCardStyle.text}>{label}</Typography>
          <Typography
            sx={country ? CountryCardStyle.text : CountryCardStyle.textlanguage}
          >
            {label2}
          </Typography>
        </Box>
      }
    </>
  );
};
