import * as React from "react"


export const HomePlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.159}
    height={19.811}
    {...props}
    
  >
    <path
      data-name="Subtraction 34"
      d="M16.9 19.811H1.261A1.267 1.267 0 0 1 0 18.541v-9.7a3.8 3.8 0 0 1 1.442-2.989L8.611.163a.755.755 0 0 1 .936 0l7.17 5.689a3.8 3.8 0 0 1 1.442 2.993v9.7a1.267 1.267 0 0 1-1.259 1.266Zm-8.639-6.886v3.721H9.9v-3.721h3.721v-1.638H9.9V7.566H8.261v3.721H4.54v1.638Z"
      fill="#1f74ea"
    />
  </svg>
)

