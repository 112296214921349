import * as React from "react";
export const BookingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_57204"
          data-name="Rectangle 57204"
          width={24}
          height={24}
          transform="translate(431 21)"
          fill="#fff"
          stroke="#707070"
          strokeWidth={1}
        />
      </clipPath>
    </defs>
    <g
      id="Group_111010"
      data-name="Group 111010"
      transform="translate(-431 -21)"
      clipPath="url(#clip-path)"
    >
      <path
        id="icons8-combi-ticket"
        d="M8.95,3A2.208,2.208,0,0,0,6.7,5.142v.428H8.05V5.142a.9.9,0,0,1,1.8,0v.428H8.05V9.213a.676.676,0,0,0,1.35,0V7.5a.676.676,0,0,1,1.35,0V9.213a1.981,1.981,0,0,1-2.025,1.928A1.981,1.981,0,0,1,6.7,9.213V5.571H6.475A2.422,2.422,0,0,0,4,7.927v6a2.422,2.422,0,0,0,2.475,2.357h13.05A2.422,2.422,0,0,0,22,13.926v-6a2.422,2.422,0,0,0-2.475-2.357H11.2V5.142A2.208,2.208,0,0,0,8.95,3Zm4.275,4.713h4.95a.643.643,0,1,1,0,1.285h-4.95a.643.643,0,1,1,0-1.285Zm0,2.571h4.95a.643.643,0,1,1,0,1.285h-4.95a.643.643,0,1,1,0-1.285Zm0,2.571h3.6a.643.643,0,1,1,0,1.285h-3.6a.643.643,0,1,1,0-1.285ZM8.554,17.568l7.232,3.209a2.549,2.549,0,0,0,1.04.223,2.49,2.49,0,0,0,2.246-1.362l1.017-2.07Z"
        transform="translate(430 21)"
        fill="#98a0ac"
      />
    </g>
  </svg>
);
