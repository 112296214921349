import * as React from "react";
export const GreenClock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      id="Group_111658"
      data-name="Group 111658"
      transform="translate(-815.001 -287)"
    >
      <g
        id="Rectangle_57075"
        data-name="Rectangle 57075"
        transform="translate(815.001 287)"
        fill="#fff"
        stroke="#707070"
        strokeWidth={1}
        opacity={0}
      >
        <rect width={16} height={16} stroke="none" />
        <rect x={0.5} y={0.5} width={15} height={15} fill="none" />
      </g>
      <path
        id="icons8-clock_3_"
        data-name="icons8-clock (3)"
        d="M11,4a7,7,0,1,0,7,7A7.008,7.008,0,0,0,11,4Zm1.6,9.3a.526.526,0,0,1-.743,0l-1.75-1.75a.526.526,0,0,1-.154-.371V7.325a.525.525,0,1,1,1.05,0v3.633l1.6,1.6A.525.525,0,0,1,12.6,13.3Z"
        transform="translate(812 284)"
        fill="#5ac782"
      />
    </g>
  </svg>
);
