import { config } from "./config";

export const Amplifyconfiguration = {
    identityPoolId: config.cognito_identity_pool_id,
    region: config.cognito_region,
    userPoolId: config.cognito_user_pool_id,
    userPoolWebClientId: config.cognito_user_pool_web_client_id,
    oauth: {
        domain: config.cognito_domain,
        scope: [
            // 'phone',
            'email',
            'profile',
            'openid',
            // 'aws.cognito.signin.user.admin',
        ],
        // redirectSignIn: config.amplifyUrls.redirectSignInUrl,
        // redirectSignOut: config.amplifyUrls.redirectSignOutUrl,
        responseType: 'token',
    },
};