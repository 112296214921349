import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { subMonths, addMonths, subYears, addYears, endOfMonth } from "date-fns";
import { Button, Grid, IconButton } from "@mui/material";
import { LeftArrowIcon, RightArrowIcon } from "../../assets/components/leftArrow";
import { SemiBold } from "../../utils/font";
import { remCalc } from "../../utils";
import "./styles.css";

export const DatePickerReact = ({
  onChange = () => false,
  selected = "",
  fieldStyle = {},
  placeholder = '',
  minDate = "",
  maxDate = "",
  onlyMonth = false,
}) => {
  const [startDate, setStartDate] = useState(selected);
  const [selectingYear, setSelectingYear] = useState(true);
  const [selectingMonthYear, setSelectingMonthYear] = useState(false);

  const handleDateChange = (date) => {
    if (selectingYear) {
      setStartDate(date);
      setSelectingMonthYear(true);
      setSelectingYear(false);
    } else if (selectingMonthYear) {
      if (onlyMonth) {
        // If onlyMonth is true, set the selected date to the last day of the chosen month
        setStartDate(endOfMonth(date));
        onChange(endOfMonth(date));
      } else {
        setStartDate(date);
        setSelectingMonthYear(false);
        setSelectingYear(false);
        onChange(date);
      }
    } else {
      setStartDate(date);
      onChange(date);
    }
  };

  const toggleYearPicker = () => {
    setSelectingYear((prevState) => !prevState); // Toggle selectingYear state
  };

  const prevMonthClick = () => {
    setStartDate((prevDate) => subMonths(prevDate, 1)); // Move to previous month
  };

  const nextMonthClick = () => {
    setStartDate((prevDate) => addMonths(prevDate, 1)); // Move to next month
  };

  const prevYearClick = () => {
    setStartDate((prevDate) => subYears(prevDate, 1)); // Move to previous year
  };

  const nextYearClick = () => {
    setStartDate((prevDate) => addYears(prevDate, 1)); // Move to next year
  };

  const CustomDatePickerHeader = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <IconButton onClick={selectingYear ? prevYearClick : prevMonthClick}>
              <LeftArrowIcon color={"#98A0AC"} width={24} height={24} viewBox="0 0 40 40" />
            </IconButton>
            <Button variant="text" sx={{
              color: "#091B29", fontFamily: SemiBold, fontSize: remCalc(12), "&:hover": {
                backgroundColor: "transparent", // Change to desired background color on hover
              },
            }} onClick={toggleYearPicker}>

              {startDate ? startDate.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              }) : 'Select Date'}
            </Button>
            <IconButton onClick={selectingYear ? nextYearClick : nextMonthClick}>
              <RightArrowIcon color={"#98A0AC"} width={24} height={24} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <DatePicker
      inline
      selected={startDate}
      onChange={handleDateChange}
      dayClassName={() => "example-datepicker-day-class"}
      monthClassName={() => "example-datepicker-month-class"}
      yearClassName={() => "example-datepicker-year-class"}
      popperClassName="example-datepicker-class"
      minDate={minDate ?? new Date()}
      dateFormat={"dd-MMM-yyyy"}
      placeholderText={placeholder}
      showMonthYearPicker={selectingMonthYear || onlyMonth} // Show month and year picker if onlyMonth is true
      showYearPicker={selectingYear}
      renderCustomHeader={CustomDatePickerHeader} // Pass custom header component
    />
  );
};
