import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { remCalc } from "../../utils";
import { Regular, SemiBold } from "../../utils/font";

const Container = styled(Box)(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  borderRadius: '8px',
  border: `1px solid ${error ? '#FF4B4B' : '#E4E8EE'}`,
  backgroundColor: '#FFFFFF',
  width: '100%',
  height: "58px"
}));

const Label = styled(Typography)(({ theme, error }) => ({
  color: error ? '#FF4B4B' : "#98A0AC",
  fontSize: remCalc(12),
  fontFamily: Regular,
  marginTop: "5px",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: '0px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 0px 10px 8px',
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: "#091B29"
  },
  '& .MuiOutlinedInput-input': {
    padding: '0',
  },
}));

export const TextBoxNew = ({
  label = "",
  value = "",
  endAdornment,
  startAdornment,
  onClick = () => { },
  onChange = () => { },
  placeholder = "Type to search...",
  error = false,
  errorMsg = ""
}) => {
  return (
    <Box>
      <Container error={error}>
        {startAdornment}
        <Box flex="1">
          <Label error={error}>{error ? errorMsg : label}</Label>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </Box>
        {endAdornment}
      </Container>
    </Box>
  );
};
