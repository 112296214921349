import * as React from "react"

export const HomeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <g data-name="Group 96235">
        <path
          data-name="icons8-home (7)"
          d="M10.444 3a.538.538 0 0 0-.315.116l-5.1 4.019A2.694 2.694 0 0 0 4 9.249V16.1a.905.905 0 0 0 .9.9h3.59a.905.905 0 0 0 .9-.9v-3.59a.171.171 0 0 1 .179-.179h1.795a.171.171 0 0 1 .179.179v3.59a.905.905 0 0 0 .9.9h3.59a.905.905 0 0 0 .9-.9V9.249A2.694 2.694 0 0 0 15.9 7.135l-5.1-4.019A.539.539 0 0 0 10.444 3Zm.018 1.224 4.768 3.757a1.613 1.613 0 0 1 .616 1.268v6.673h-3.231v-3.41a1.265 1.265 0 0 0-1.256-1.256H9.564a1.265 1.265 0 0 0-1.256 1.256v3.41H5.077V9.249a1.613 1.613 0 0 1 .616-1.268Z"
          fill="#98a0ac"
          stroke="#98a0ac"
          strokeWidth={0.25}
        />
      </g>
    </g>
  </svg>
)


