import { SvgIcon } from "@mui/material"
import * as React from "react"

export const RightArrow = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#1f74ea",
      ...rootStyle,
    };
    return(
<SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
    <g data-name="Group 110379">
      <path
        data-name="Path 100053"
        d="M.2.2a.7.7 0 0 0 0 .972l4.2 4.326L.2 9.827a.7.7 0 0 0 0 .972.652.652 0 0 0 .943 0L5.81 5.986a.7.7 0 0 0 0-.972L1.138.2a.652.652 0 0 0-.943 0Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
    ) 
}

