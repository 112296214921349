import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },

});

const baseQueryWithRetry = retry(baseQuery);
export const assetsAndLinksAPI = createApi({
    reducerPath: "assetsAndLinksAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["assetsAndLinks"],
    endpoints: (builder) => ({

        // getAllFacilities
        getAllAssets: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/assets/client`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),

    }),
});

// Export hooks for usage in functional components
export const {
    useGetAllAssetsMutation

} = assetsAndLinksAPI;
