import { borderRadiusAlignments, remCalc } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const facilityBillStyle = {
    main: {
        backgroundColor: '#FFFFFF',
        borderRadius: borderRadiusAlignments?.borderSixteen,
        border: '1px solid #E4E8EE',
        boxShadow: '0px 0px 32px #0000000F',
        // height:"100% !important",
        // display: "flex",
        // width: "388px"
    },
    main1: {
        boxShadow: "none!important",
    },
    db: {
        backgroundColor: "#F2F4F7",
        borderRadius: borderRadiusAlignments?.borderTwelve,
        height: "72px",
        m: "0px 16px 0px 12px",
        flexDirection: "row",
        padding: "12px",
        alignItems: "center",
        justifyContent: "start",
    },
    smallFont: {
        fontFamily: Regular,
        fontSize: '0.875rem',
        color: '#4E5A6B',

    },
    smallFontText: {
        fontFamily: Regular,
        fontSize: '14px',
        color: '#4E5A6B',
        // width: {
        //     sm: "92%",
        //     xs: "92%"
        // }

    },
    smallFontPerHr: {
        fontFamily: Regular,
        fontSize: '14px',
        color: 'text.tertiary',
    },
    smallFontTime: {
        mb: "6px"

    },

    headtext: {
        fontFamily: Medium,
        fontSize: '1rem',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: 'text.primary'
    },
    textBox: {
        ml: "8px"
    },
    bold: {
        fontFamily: Medium,
        fontSize: '1rem',
        color: "text.primary"
    },
    bold1: {
        fontFamily: Medium,
        fontSize: '0.875rem',
        color: "text.primary"

    },
    sb: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ac: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px'
    },
    fc: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        justifyContent: "space-between"
    },
    fc1: {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        justifyContent: "space-between",
        // height: "377px"
        minHeight: (props) => props?.minHeight ?? "373px",
    },
    image: {
        width: "100%",
        height: '48px',
        borderRadius: borderRadiusAlignments?.borderTwelve
    },
    divider: {
        borderStyle: 'dashed'
    },
    fullDivider: {
        mr: '-15px',
        ml: '-15px'
    },
    bolt: {
        m: "2px 0px 0px 4px"
    },
    rightText: {
        textAlign: "right",
        fontSize: "0.875rem",
        fontFamily: Medium
    },
    rightText2: {
        textAlign: "right",
        fontSize: "0.875rem",
        fontFamily: SemiBold
    },
    hourRate: {
        // display: "flex",
        // alignItems: "center",
        textAlign: "right",
        justifyContent: "end"
    },
    hourFont: {
        fontSize: "1rem",
        color: "text.primary",
        textAlign: "right"
    },
    smallFont1: {
        fontFamily: SemiBold,
        backgroundColor: "#FEF2D9",
        borderRadius: "9px",
        fontSize: '0.75rem',
        color: '#F5AA00',
        cursor: 'pointer',
        padding: "2px 4px 2px 4px",
        display: "flex",
        alignItems: "center"
    },
    //   smallFont1: {
    //     fontFamily: Regular,
    //     fontSize:  "0.875rem",
    //     color: '#4E5A6B',
    //     display: "flex"
    // },
    bookings: {
        mb: "16px"
    },
    textBoxOverbooking: {
        width: 50
    },
    participantCountText: {
        fontSize: remCalc(14),
    },
    errorText: {
        color: "error.light",
        fontSize: remCalc(14),
        fontFamily: Regular,
        marginTop: "8px"
    },
    overbookcountBox: {
        padding: "6px 0px 6px"
    }
}