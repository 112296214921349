import React from "react";
import { withNavBars } from "./../../HOCs";

class HomeParent extends React.Component {
  render() {
    return <>
      dkn
    </>;
  }
}

export default withNavBars(HomeParent);
