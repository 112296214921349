import React from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DefautTab = ({
  tabs = [],
  handleChange = () => false,
  value = "",
  variant = '',
  tabBottomColor = ""
}) => {
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))(({ theme }) => ({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      width: 400,
      backgroundColor: tabBottomColor?.length > 0 ? tabBottomColor : theme.palette.primary.main,
    },
  }));

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontSize:  "0.875rem",
      marginRight: 1,
      color: theme.palette.primary.secondary,
      "&.Mui-selected": {
        color: "#091B29",
        fontSize:  "0.875rem",
        fontWeight: 600,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      variant={variant ?? "static"}
      aria-label="secondary tabs example"
    >
      {tabs?.map((val) => (
        <StyledTab label={val} />
      ))}
    </StyledTabs>
  );
};
