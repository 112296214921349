import * as React from "react";

export const PlusIcon = (props) => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={33} {...props}>
    <g data-name="Group 111562" transform="translate(-154 -586)">
      <rect
        data-name="Rectangle 56186"
        width={32}
        height={32}
        rx={16}
        transform="translate(154.5 586.5)"
        fill="#fff"
        stroke="#e4e8ee"
      />
      <path
        data-name="Path 100378"
        d="M174.784 602.967h-3.834v3.816h-1.116v-3.816H166v-1.134h3.834v-3.834h1.116v3.834h3.834Z"
        fill="#091b29"
      />
    </g>
  </svg>
)
};
