import * as React from "react"

export const CricketIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M16.415 3a.686.686 0 0 0-.482.216L5.908 13.889a.686.686 0 0 0 .015.955l.808.813-3.4 3.4a1.144 1.144 0 0 0 1.618 1.618l3.4-3.4.808.809a.686.686 0 0 0 .955.014L20.784 8.067a.686.686 0 0 0 .169-.751 7.347 7.347 0 0 0-4.27-4.269.686.686 0 0 0-.268-.046Zm-9.037.757a2.5 2.5 0 0 0-1.885.8 2.643 2.643 0 0 0 0 3.427 2.615 2.615 0 0 0 3.77 0 2.643 2.643 0 0 0 0-3.427 2.5 2.5 0 0 0-1.885-.8Zm9.206.769a5.735 5.735 0 0 1 2.888 2.89l-9.816 9.222-2.294-2.294Zm-9.206.6a1.044 1.044 0 0 1 .859.341 1.275 1.275 0 0 1 0 1.6 1.044 1.044 0 0 1-.859.341 1.044 1.044 0 0 1-.859-.341 1.275 1.275 0 0 1 0-1.6 1.044 1.044 0 0 1 .859-.337Z"
      fill="#78b1fe"
      data-name="Group 111058"
    />
  </svg>
)


