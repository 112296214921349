import * as React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { imageGridMobilestyle } from './style';

import { ImageGrid } from './utils';
import { AuthContext } from '../../../contexts';




export const QuiltedImageListMobile = ({ propertyId = "", unitId = "", images = [], OpenSingleImageModal = () => false }) => {
  const auth = React.useContext(AuthContext)

  const [mappedItems, setMappedItems] = React.useState([])

  //eslint-disable-next-line 
  const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;
  const getVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  React.useEffect(() => {
    if (images?.length > 0) {
      let data = ImageGrid(images)
      setMappedItems(data)
    }
    //eslint-disable-next-line 
  }, [images])

  const seeFullImages = () => {

    let queryParams;

    if (unitId?.length > 0) {

      queryParams = new URLSearchParams({ unitId }).toString();
      window.open(`${auth?.clientRoutes?.imageList}?${queryParams}`, '_blank');

    } else {
      queryParams = new URLSearchParams({ propertyId }).toString();
      window.open(`${auth?.clientRoutes?.imageList}?${queryParams}`, '_blank');

    }
  }

  const length = Object.keys(mappedItems).length




  return (
    // <Hidden smUp>
    <Box>
      {mappedItems?.length === 1 &&
        <Grid container>
          <Grid item sm={12} xs={12}>
            {
              youtubeRegex.test(mappedItems?.[0]?.img) ?
                <iframe
                  title={"myframe"}
                  style={imageGridMobilestyle.image}
                  src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[0]?.img)}>
                </iframe>
                :
                <Box
                  as='img'
                  // {...srcset(item.img, 180, item.rows, item.cols)}
                  alt={mappedItems?.[0]?.title}
                  src={mappedItems?.[0]?.img}
                  loading="lazy"
                  sx={imageGridMobilestyle.image}
                  onClick={() => OpenSingleImageModal(mappedItems?.[0]?.img)}
                />
            }
          </Grid>
        </Grid>
      }
      {mappedItems?.length === 2 &&
        <Grid container>
          <Grid item sm={6} xs={6}>
            {
              youtubeRegex.test(mappedItems?.[0]?.img) ?
                <iframe
                  title={"myframe"}

                  style={imageGridMobilestyle.image}
                  src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[0]?.img)}>
                </iframe>
                :
                <Box
                  as='img'
                  // {...srcset(item.img, 180, item.rows, item.cols)}
                  alt={mappedItems?.[0]?.title}
                  src={mappedItems?.[0]?.img}
                  loading="lazy"
                  sx={imageGridMobilestyle.image}
                  onClick={() => OpenSingleImageModal(mappedItems?.[0]?.img)}
                />
            }
          </Grid>
          <Grid item sm={6} xs={6}>
            {
              youtubeRegex.test(mappedItems?.[1]?.img) ?
                <iframe
                  title={"myframe"}

                  style={imageGridMobilestyle.imageFirst}
                  src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[1]?.img)}>
                </iframe>

                :
                <Avatar src={mappedItems?.[1]?.img} alt={mappedItems?.[1]?.title} sx={imageGridMobilestyle.imageFirst} />
            }
          </Grid>
        </Grid>
      }

      {
        mappedItems?.length === 3 &&
        <Grid container spacing={1}>
          <Grid item sm={9} xs={9}>
            {
              youtubeRegex.test(mappedItems?.[0]?.img) ?
                <iframe
                  title={"myframe"}

                  style={imageGridMobilestyle.image}
                  src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[0]?.img)}>
                </iframe>
                :
                <Box
                  as='img'
                  // {...srcset(item.img, 180, item.rows, item.cols)}
                  alt={mappedItems?.[0]?.title}
                  src={mappedItems?.[0]?.img}
                  loading="lazy"
                  sx={imageGridMobilestyle.image}
                  onClick={() => OpenSingleImageModal(mappedItems?.[0]?.img)}
                />

            }
          </Grid>
          <Grid item sm={3} xs={3}>
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12}>
                {
                  youtubeRegex.test(mappedItems?.[1]?.img) ?
                    <iframe
                      title={"myframe"}

                      style={imageGridMobilestyle.imageFirst}
                      src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[1]?.img)}>
                    </iframe>

                    :
                    // <Box
                    //   as='img'
                    //   {...srcset(item.img, 180, item.rows, item.cols)}
                    //   alt={mappedItems?.[1]?.title}
                    //   loading="lazy"
                    //   sx={imageGridMobilestyle.image}
                    //   onClick={() => OpenSingleImageModal(mappedItems?.[1]?.img)}
                    // />
                    <Avatar src={mappedItems?.[1]?.img} alt={mappedItems?.[1]?.title} sx={imageGridMobilestyle.imageFirst} />
                }
              </Grid>
              <Grid item sm={12} xs={12}>
                {
                  youtubeRegex.test(mappedItems?.[2]?.img) ?
                    <iframe
                      title={"myframe"}

                      style={imageGridMobilestyle.imageLast}
                      src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[2]?.img)}>
                    </iframe>

                    :
                    // might be used in futur pls Don't Delete

                    // <Box sx={imageGridMobilestyle?.imageCount}>
                    //   <Box sx={imageGridMobilestyle?.lastBox}>
                    //     <Typography sx={imageGridMobilestyle?.text}>{length > 3 ? `${"+"} ${length - 3}` : ""}</Typography>
                    //   </Box>
                    //   <Avatar src={mappedItems?.[2]?.img} alt={mappedItems?.[2]?.title} sx={imageGridMobilestyle?.imageLast} >

                    //   </Avatar>
                    // </Box>

                    <Avatar src={mappedItems?.[2]?.img} alt={mappedItems?.[2]?.title} sx={imageGridMobilestyle?.imageLast} >

                    </Avatar>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }

      {
        mappedItems?.length > 3 &&
        <Grid container spacing={1}>
          <Grid item sm={9} xs={9}>
            {
              youtubeRegex.test(mappedItems?.[0]?.img) ?
                <iframe
                  title={"myframe"}

                  style={imageGridMobilestyle.image}
                  src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[0]?.img)}>
                </iframe>
                :
                <Box
                  as='img'
                  // {...srcset(item.img, 180, item.rows, item.cols)}
                  alt={mappedItems?.[0]?.title}
                  src={mappedItems?.[0]?.img}
                  loading="lazy"
                  sx={imageGridMobilestyle.image}
                  onClick={() => OpenSingleImageModal(mappedItems?.[0]?.img)}
                />
            }
          </Grid>
          <Grid item sm={3} xs={3}>
            <Grid container spacing={1}>
              <Grid item sm={12} xs={12}>
                {
                  youtubeRegex.test(mappedItems?.[1]?.img) ?
                    <iframe
                      title={"myframe"}

                      style={imageGridMobilestyle.imageFirst}
                      src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[1]?.img)}>
                    </iframe>

                    :
                    // <Box
                    //   as='img'
                    //   {...srcset(item.img, 180, item.rows, item.cols)}
                    //   alt={mappedItems?.[1]?.title}
                    //   loading="lazy"
                    //   sx={imageGridMobilestyle.image}
                    //   onClick={() => OpenSingleImageModal(mappedItems?.[1]?.img)}
                    // />
                    <Avatar src={mappedItems?.[1]?.img} alt={mappedItems?.[1]?.title} sx={imageGridMobilestyle.imageFirst} />
                }
              </Grid>
              <Grid item sm={12} xs={12}>
                {
                  youtubeRegex.test(mappedItems?.[2]?.img) ?
                    <iframe
                      title={"myframe"}

                      style={imageGridMobilestyle.imageLast}
                      src={"https://www.youtube.com/embed/" + getVideoId(mappedItems?.[2]?.img)}>
                    </iframe>

                    :
                    // <Box
                    //   as='img'
                    //   // {...srcset(item.img, 180, item.rows, item.cols)}
                    //   alt={mappedItems?.[2]?.title}
                    //   loading="lazy"
                    //   sx={imageGridMobilestyle.image}
                    //   onClick={() => OpenSingleImageModal(mappedItems?.[2]?.img)}
                    // />
                    <Box sx={imageGridMobilestyle?.imageCount} onClick={seeFullImages}>
                      <Box sx={imageGridMobilestyle?.lastBox}>
                        <Typography sx={imageGridMobilestyle?.text}>{length > 3 ? `${"+"} ${length - 3}` : ""}</Typography>
                      </Box>
                      <Avatar src={mappedItems?.[2]?.img} alt={mappedItems?.[2]?.title} sx={imageGridMobilestyle?.imageLast} >

                      </Avatar>




                    </Box>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    </Box>

  );
}



