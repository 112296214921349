import * as React from "react"

export const TickICon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 111861">
      <path
        data-name="icons8-verified-account (1)"
        d="M44 24a7.507 7.507 0 0 0-3.912-6.58 7.482 7.482 0 0 0-9.508-9.508 7.49 7.49 0 0 0-13.16 0 7.482 7.482 0 0 0-9.508 9.508 7.49 7.49 0 0 0 0 13.16 7.482 7.482 0 0 0 9.508 9.508 7.49 7.49 0 0 0 13.16 0 7.482 7.482 0 0 0 9.508-9.508A7.507 7.507 0 0 0 44 24Zm-11.439-3.439-10 10a1.5 1.5 0 0 1-2.122 0l-5-5a1.5 1.5 0 0 1 2.121-2.121l3.94 3.939 8.939-8.939a1.5 1.5 0 0 1 2.123 2.121Z"
        fill="#5ac782"
      />
    </g>
  </svg>
)

