
import * as React from "react";
export const LeftArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={props?.viewBox ?? false} width={props?.width ?? 40} height={props?.height ?? 40} {...props}>
    <g data-name="Group 110377">
      <g
        fill="#fff"
        stroke="#ced3dd"
        data-name="Rectangle 56186"
        transform="rotate(180 20 20)"
      >
        <rect width={40} height={40} stroke="none" rx={20} />
        <rect width={39} height={39} x={0.5} y={0.5} fill="none" rx={19.5} />
      </g>
      <g data-name="Group 110379">
        <path
          fill={props?.color ?? "#091b29"}
          stroke={props?.color ?? "#091b29"}
          strokeWidth={0.25}
          d="M22.887 27.243a.894.894 0 0 0 0-1.237l-5.339-5.507 5.34-5.506a.894.894 0 0 0 0-1.237.83.83 0 0 0-1.2 0l-5.939 6.125a.894.894 0 0 0 0 1.237l5.938 6.125a.83.83 0 0 0 1.2 0Z"
          data-name="Path 100053"
        />
      </g>
    </g>
  </svg>
);

export const RightArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width={props?.width ?? 40} height={props?.height ?? 40} {...props}>
    <g data-name="Group 110377">
      <g
        fill="#fff"
        stroke="#ced3dd"
        data-name="Rectangle 56186"
        transform="rotate(180 20 20)"

      >
        <rect width={40} height={40} stroke="none" rx={20} />
        <rect width={39} height={39} x={0.5} y={0.5} fill="none" rx={19.5} />
      </g>
      <g data-name="Group 110379">
        <path
          fill={props?.color ?? "#091b29"}
          stroke={props?.color ?? "#091b29"}
          strokeWidth={0.25}
          d="M17.113 27.243a.894.894 0 0 1 0-1.237l5.339-5.507-5.34-5.506a.894.894 0 0 1 0-1.237.83.83 0 0 1 1.2 0l5.939 6.125a.894.894 0 0 1 0 1.237l-5.938 6.125a.83.83 0 0 1-1.2 0Z"
          data-name="Path 100053"
        />
      </g>
    </g>
  </svg>
);
