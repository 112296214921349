
import React from "react";
import { CreateNewAccount } from "./createNewAccount"
import { withTranslation } from 'react-i18next';

class CreateNewAccountParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <CreateNewAccount {...this.props}/>;
  }
}
export default withTranslation('CreateNewAccount')(CreateNewAccountParent);
