import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Typography } from '@mui/material';
import { imageGridstyle } from './style';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ImageGrid } from './utils';
import { AuthContext } from '../../contexts';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const QuiltedImageList = ({ propertyId = "", images = [], OpenSingleImageModal = () => false }) => {
  const auth = React.useContext(AuthContext)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLap = useMediaQuery(theme.breakpoints.down('lg'))
  const isLarge = useMediaQuery(theme.breakpoints.up('xl'))
  const [mappedItems, setMappedItems] = React.useState([])

  //eslint-disable-next-line 
  const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/i;
  const getVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
  React.useEffect(() => {
    if (images?.length > 0) {
      let data = ImageGrid(images)
      setMappedItems(data)
    }
    //eslint-disable-next-line 
  }, [images])

  const seeFullImages = () => {
    const queryParams = new URLSearchParams({ propertyId }).toString();
    window.open(`${auth?.clientRoutes?.imageList}?${queryParams}`, '_blank');

  }

  return (
    <ImageList
      variant="quilted"
      gap={8}
      cols={isMobile ? 12 : isLarge ? 8 : isLap ? 4 : 6}
      rowHeight={180}
    >
      {mappedItems?.slice(0, 6)?.map((item, index) => {
        // console.log(mappedItems?.length > mappedItems?.slice(0, 6)?.length && mappedItems?.length - 1 === index);
        return (
          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
            {mappedItems?.length > 6 && mappedItems?.slice(0, 6)?.length - 1 === index ?
              <Box >
                <Box sx={imageGridstyle.last}
                  onClick={seeFullImages}
                >
                  <Typography sx={imageGridstyle.text}>{"See all images"}</Typography>
                </Box>
                {youtubeRegex.test(item?.img) ?
                  <iframe
                    title={"myframe"}

                    style={imageGridstyle.image2}
                    src={"https://www.youtube.com/embed/" + getVideoId(item?.img)}>
                  </iframe> :
                  <Box
                    as='img'
                    {...srcset(item.img, 180, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                    sx={imageGridstyle.image2}
                    onClick={() => OpenSingleImageModal(item.img)}
                  />
                }
              </Box>
              :
              youtubeRegex.test(item?.img) ?
                <iframe
                  title={"myframe"}
                  style={imageGridstyle.image}
                  src={"https://www.youtube.com/embed/" + getVideoId(item?.img)}>
                </iframe>
                :
                <Box
                  as='img'
                  {...srcset(item.img, 180, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                  sx={imageGridstyle.image}
                  onClick={() => OpenSingleImageModal(item.img)}
                />
            }


          </ImageListItem>
        )
      })}
    </ImageList>
  );
}



