import { Avatar, Box, Typography } from "@mui/material";
import { PastBookingCardStyle } from "./style";
export const PastBookingCard = ({
  src = "",
  gamelabel = "",
  place = "",
  date = "",
}) => {
  return (
    // Main card and images
    <Box sx={PastBookingCardStyle.mainBox}>
      <Box sx={PastBookingCardStyle.mainimage}>
        {/* <img src={src} alt="game logo" style={PastBookingCardStyle.image} /> */}
        <Avatar src={src} sx={PastBookingCardStyle.image} />
      </Box>
      {/* Card Text content */}
      <Box sx={PastBookingCardStyle.text}>
        <Typography sx={PastBookingCardStyle.text1}>{gamelabel}</Typography>
        <Typography sx={PastBookingCardStyle.text2}>{place}</Typography>
        <Typography sx={PastBookingCardStyle.text3}>{date}</Typography>
      </Box>
    </Box>
  );
};
