import { Grid, Box, Button, Stack, Typography, Hidden, Popover, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React from 'react';
import { CustomButton } from '../Button';
import { SearchIcon } from '../../assets/components';
import { withTranslation } from 'react-i18next';
import { searchBarStyle } from './style';

const SearchBar = ({
  toggle = '',
  handleToggle = () => false,
  onRedirect = () => false,
  RentSearch,
  BuySearch,
  isToggle = false,
  onReset = () => false,
  updateState = () => false,
  t = () => false,
  categoryOptions = [],
  selectCategoryValue = ""
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRadio, setSelectedRadio] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleCount = isToggle ? 5 : 7
  return (
    <>
      <Hidden smDown>

        <Grid
          container
          rowSpacing={2}
          sx={isToggle ? searchBarStyle.main : searchBarStyle.othermain}
        >
          <Grid item xs={12} overflow={"auto"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {isToggle &&
              <Box sx={searchBarStyle.mainToggle2}>
                <>
                  <Button
                    sx={{
                      ...searchBarStyle.button,
                      ...(toggle === 'Rent' ? searchBarStyle.activeButton : searchBarStyle.inactiveButton),
                    }}
                    onClick={() => handleToggle('Rent')}
                  >
                    {t('Rent')}
                  </Button>
                  <Button
                    sx={{
                      ...searchBarStyle.button,
                      ...(toggle === 'Buy' ? searchBarStyle.activeButton : searchBarStyle.inactiveButton),
                    }}
                    onClick={() => handleToggle('Buy')}
                  >
                    {t('Buy')}
                  </Button>
                </>
              </Box>
            }

            <Box sx={searchBarStyle.mainToggle}>
              {selectedRadio &&
                <Button
                  sx={
                    searchBarStyle.transparentToggleSelectedLeft}
                >
                  {selectedRadio?.label}
                </Button>
              }
              {categoryOptions?.length > 0 &&
                categoryOptions?.slice(0, toggleCount)?.map((i) => {
                  return (<>
                    <Button
                      sx={
                        i.value === selectCategoryValue
                          ? searchBarStyle.transparentToggleSelectedLeft
                          : searchBarStyle.transparentToggle
                      }
                      onClick={() => {
                        setSelectedRadio(null)
                        updateState("category", "category", i)
                      }}
                    >
                      {i?.label}
                    </Button>

                  </>
                  )
                })}
              {categoryOptions?.length > toggleCount &&
                <Button
                  sx={

                    searchBarStyle.transparentToggle
                  }
                  onClick={handleClick}
                >
                  +{categoryOptions?.length - toggleCount}
                </Button>
              }
            </Box>
          </Grid>

          {isToggle ? (
            <Grid item container xs={12} sm={12} lg={12} md={12} xl={12} spacing={1}>
              {toggle === 'Rent' ? RentSearch : BuySearch}
            </Grid>
          ) : (
            <Grid item container xs={12} sm={12} lg={12} md={12} xl={12} spacing={1}>
              {BuySearch}
            </Grid>
          )}
          {/* <Grid item xs={3} sm={2} lg={2} md={2} sx={searchBarStyle.button}>
            <CustomButton
              label='Reset'
              rootStyle={searchBarStyle.reset}
              onClick={onReset}
            />
            <Stack
              direction={'row'}
              spacing={1}
              sx={searchBarStyle.searchBtn}
              alignItems={'center'}
              p={2}
              onClick={onRedirect}
            >
              <SearchIcon rootStyle={searchBarStyle.search} />
              <Typography sx={searchBarStyle.searchText}>{t('Search')}</Typography>
            </Stack>
          </Grid> */}
        </Grid>
        {/* <Box sx={searchBarStyle.recentSearchContainer}>
          <Box sx={searchBarStyle.recentSearch}>
            <Typography>{t('Recent Search')}</Typography>
          </Box>
        </Box> */}
      </Hidden>
      <Hidden smDown>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={searchBarStyle.dialog}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <Box sx={searchBarStyle.toggleContainer}>

            <>

              <Box
                sx={searchBarStyle.memberBox}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={searchBarStyle.formlabel}
                  >
                    {categoryOptions?.length > 5 ?
                      <>
                        {categoryOptions?.slice(5, categoryOptions?.length)?.map((val) => {
                          return (
                            <FormControlLabel onChange={() => {
                              updateState("category", "category", val)
                              setSelectedRadio(val)
                              handleClose()
                            }} value={val?.value} control={<Radio checked={selectCategoryValue === val?.value} />} label={val?.label}
                              sx={searchBarStyle.formlabel}
                            />
                          )
                        })}
                      </>
                      :
                      <Box sx={searchBarStyle.noDataFound}>
                        {/* <NoDataImageMob width={"100%"} height={150} /> */}
                        <Typography sx={searchBarStyle.noDataFoundText}>{t("No Category Found")}</Typography>
                      </Box>
                    }
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          </Box>
        </Popover>
      </Hidden>
    </>
  );
};

export default withTranslation()(SearchBar);
