import { borderRadius } from "../../utils";
import { SemiBold } from "../../utils/font";


export const PopularFacilitiesCardStyle = {
    maincard: {
        borderRadius: borderRadius,
        height: "40px",
        backgroundColor: "text.default",
        border: "1px solid #E4E8EE",
        display: "Flex",
        padding: "8px",
        marginInlineEnd: "8px"
        // boxShadow: "0px 0px 32px #0000000f",

    },
    text: {
        fontSize: "0.875rem !important",
        fontFamily: SemiBold,
        color: "text.primary",
        marginInlineStart: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    }
}