export const simpleSelectStyles = {
  autocomplete: {
    "& fieldset": {
      border: "none",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "0px !important",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      fontSize:  "0.875rem",
      padding: "0px 0px",
    },
  },
  margin: {
    margin: "0px",
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      paddingRight: "20px !important",
      '&:hover fieldset': {
        borderColor: 'border.default'
      },
    },
  },
}