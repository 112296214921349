import { borderRadius } from "../../utils";
import { Medium, Regular } from "../../utils/font";

export const slotSelectorStyle = {
    main: {
        boxShadow: '0px 0px 32px #0000000F',
        border: '1px solid #E4E8EE',
        borderRadius: borderRadius
    },
    month: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: '#98A0AC'
    },
    slotCard: {
        height: 'auto',
        maxHeight: "450px",
        overflow: 'auto',
        '::-webkit-scrollbar': {
            display: 'none'
        }
    },
    monthSelected: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: '#5078E1'
    },
    fontSmall: {
        fontSize: "0.75rem",
        fontFamily: Medium,
        color: '#98A0AC'
    },
    date: {
        fontSize: "1.125rem",
        fontFamily: Medium,
        color: 'text.secondary'
    },
    dateSelected: {
        fontSize: "1.125rem",
        fontFamily: Medium,
        color: '#5078E1'
    },
    calendar: {
        width: "45px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        m: 1,
        padding: '5px',
        borderRadius: borderRadius,
        cursor: 'pointer'
    },
    calenderSelected: {
        width: "45px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        m: 1,
        backgroundColor: '#5078E10D',
        padding: '5px',
        borderRadius: borderRadius
    },
    bottomSlots: {
        mt: {
            lg: "14px",
            md: "14px",
            sm: "14px",
            xs: "8px"
        },
        minHeight: "392px",
        height: "auto"
        // display: 'flex', flexDirection: 'column', gap: 1
    },
    bottomSlotsDaily: {
        mt: {
            lg: "14px",
            md: "14px",
            sm: "14px",
            xs: "8px"
        },
        minHeight: "464px",
        height: "auto"

    },
    noData: {
        color: "#999",
        textAlign: "center",
        // marginTop: "20vh",
        fontSize: "0.875rem",
        fontFamily: Medium,
        marginLeft: "12px",

    },
    noDataBox: {
        display: "flex",
        justifyContent: "center"
    }
}