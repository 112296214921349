import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { CloseIcon } from "../../assets";
import { MapBoxStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../contexts";

const FacilityPopupCard = ({
    popupInfo = {},
    onViewButton = false,
    t = () => false,
    date,
    selected
}) => {
    const history = useNavigate()
    const auth = React.useContext(AuthContext)


    const onView = (item) => {
        if (onViewButton) {
            history(auth?.clientRoutes?.viewDetails, { state: { id: item, date: date, selected: selected } });
        } else {
            return false
        }
    };

    return (
        <Box onClick={() => onView(popupInfo)}>
            <Stack direction={"row"} alignItems={"center"}>
                <Avatar src={popupInfo?.logo} sx={MapBoxStyle?.facilityCardImages} />
                <Stack direction={"row"} justifyContent={"space-between"} sx={MapBoxStyle?.facilityCardRightBox}>
                    <Stack>
                        <Typography sx={MapBoxStyle?.facilityCardHead}>{popupInfo?.name}</Typography>
                        <Typography sx={MapBoxStyle?.facilityCardSubHead}>{popupInfo?.company_name}</Typography>
                        <Typography sx={MapBoxStyle?.facilityCardChip}>{`${popupInfo?.facility_count} ${popupInfo?.facility_count > 20 ? `+ ${t("Facilities available")}` : t("Facilities available")}`}</Typography>
                    </Stack>
                    <CloseIcon />

                </Stack>

            </Stack>
        </Box>
    )
}
export default (withTranslation('popup')(FacilityPopupCard));