import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { ShortlistCardStyle } from "./style"
import InfiniteScroll from "react-infinite-scroll-component";
import { NotDataImageWeb } from "../../assets/components/noDataImageWeb";
import { withTranslation } from "react-i18next";


export const ShortlistwebCard = ({
    data = [],
    fetchMoreDataBooking = () => null,
    t = () => false
}) => {
    return <Box sx={ShortlistCardStyle?.parentBox} >
        {
            data?.length > 0 ?
                <InfiniteScroll
                    dataLength={data?.length ?? 0}
                    next={fetchMoreDataBooking}
                    hasMore={true}
                    style={ShortlistCardStyle?.infiniteScroll}
                    height={`calc(100vh - 165px)`}
                >
                    {data?.map(e => {
                        return (
                            <Grid container spacing={2} sx={ShortlistCardStyle?.stackParent}>
                                <Grid item xs={3} display={"flex"} >
                                    <Box sx={ShortlistCardStyle?.imgBox}>
                                        <img width={"100%"} height={"100%"} src={e?.logo} alt={"No Img"} style={ShortlistCardStyle?.imgBox}></img>
                                    </Box>
                                    <Box sx={ShortlistCardStyle?.shortlistDetails}>
                                        <Typography>{e?.name}</Typography>
                                        <Stack direction="row" sx={ShortlistCardStyle?.stackChild}>
                                            <Typography sx={ShortlistCardStyle?.unitDetails}>
                                                {e?.unit_no}</Typography>
                                            <Box sx={ShortlistCardStyle?.dot}></Box>
                                            <Typography sx={ShortlistCardStyle?.unitDetails}>
                                                {`${e?.total_area} ${t("Sq.Ft")}`}</Typography>
                                            <Box sx={ShortlistCardStyle?.dot}></Box>
                                            <Typography sx={ShortlistCardStyle?.unitDetails}>
                                                {e?.type}</Typography>
                                        </Stack>

                                    </Box>
                                    <Typography sx={ShortlistCardStyle?.code}>{e?.code}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={ShortlistCardStyle?.amnt}>
                                        {e?.currency_symbol} {e?.price}</Typography>

                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={ShortlistCardStyle?.unitChip}>
                                        {e?.unit_category}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                                    <Button sx={ShortlistCardStyle?.viewBtn}>
                                        <Typography>{t("View Unit")}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    })
                    }
                </InfiniteScroll>
                :
                < center style={{ height: "calc(100vh - 165px" }} >
                    <NotDataImageWeb />
                </center>
        }

    </Box >

}
export default withTranslation('shortlist')(ShortlistwebCard);