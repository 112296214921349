import { SvgIcon } from "@mui/material"
import * as React from "react"

export const MenuIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 20,
      height: 25,
      color:"#98a0ac",
      ...rootStyle,
    };
  return(
    <SvgIcon
    data-name="Component 77 \u2013 4"
    xmlns="http://www.w3.org/2000/svg"
     sx={rootSx}
    {...rest}
  >
    <rect
      data-name="Rectangle 57046"
      width={32}
      height={32}
      rx={10}
      fill="none"
    />
    <path
      data-name="icons8-list (1)"
      d="M7.579 5.474A1.594 1.594 0 0 0 6 7.053v3.158a1.6 1.6 0 0 0 1.579 1.579h3.158a1.6 1.6 0 0 0 1.579-1.579V7.053a1.6 1.6 0 0 0-1.579-1.579Zm7.368 2.105a1.053 1.053 0 1 0 0 2.105h10a1.053 1.053 0 1 0 0-2.105Zm-7.368 5.263A1.6 1.6 0 0 0 6 14.421v3.158a1.6 1.6 0 0 0 1.579 1.579h3.158a1.6 1.6 0 0 0 1.579-1.579v-3.158a1.6 1.6 0 0 0-1.579-1.579Zm7.368 2.105a1.053 1.053 0 1 0 0 2.105h10a1.053 1.053 0 1 0 0-2.105Zm-7.368 5.264A1.6 1.6 0 0 0 6 21.79v3.158a1.6 1.6 0 0 0 1.579 1.579h3.158a1.6 1.6 0 0 0 1.579-1.579V21.79a1.6 1.6 0 0 0-1.579-1.579Zm7.368 2.105a1.053 1.053 0 1 0 0 2.105h10a1.053 1.053 0 1 0 0-2.105Z"
      fill="currentColor"
    />
  </SvgIcon>
  )  
}

