import * as React from "react"
const Myfavourites = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    data-name="icons8-favourite (3)"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 32V0h32v32Zm16.572-8.875a6.3 6.3 0 1 0 6.3-6.3 6.29 6.29 0 0 0-6.3 6.3Z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Group 116308">
      <g data-name="Group 116307">
        <path
          fill="#ff4b4b"
          d="M10.001 4.667A7.348 7.348 0 0 0 2.668 12c0 2.731 2.058 5.3 4.568 7.939s5.6 5.308 8.059 7.768a1 1 0 0 0 1.414 0c2.461-2.461 5.549-5.131 8.059-7.768s4.566-5.205 4.566-7.938a7.348 7.348 0 0 0-7.333-7.333c-2.318 0-4.335 1.244-6 3.358-1.665-2.114-3.682-3.358-6-3.358Z"
          data-name="Path 101143"
        />
      </g>
    </g>
    <g data-name="Group 116309">
      <path
        fill="#5ac782"
        d="M22.875 17.792a5.333 5.333 0 1 0 5.333 5.333 5.333 5.333 0 0 0-5.333-5.333Zm3.252 3.737-3.879 3.879a.485.485 0 0 1-.686 0l-1.939-1.94a.485.485 0 0 1 .686-.686l1.6 1.6 3.533-3.539a.485.485 0 1 1 .686.686Z"
        data-name="Path 101144"
      />
    </g>
  </svg>
)
export default Myfavourites
