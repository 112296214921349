import { SvgIcon } from "@mui/material"
import * as React from "react"

export const CommercialIcon=(props)=> {
    const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    color:"#98a0ac",
    ...rootStyle,
  };
  return (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
     sx={rootSx}
    {...rest}
  >
    <defs>
      <style>{".b{fill:currentColor}"}</style>
    </defs>
    <path
      d="M16.528 8.715V3.3a.9.9 0 1 0-1.8 0v.451l-5.2-1.3a1.794 1.794 0 0 0-.439-.051h-.68A1.8 1.8 0 0 0 6.6 4.2v6.32h-.9a.9.9 0 0 0-.9.9v9.022h11.728v-.9h2.707V8.715Z"
      transform="translate(-3.899 -2.4)"
      style={{
        fill: "#ced3dd",
        opacity: 0.35,
      }}
    />
    <path
      className="b"
      d="M14.43 4.512h-1.8v2.707h1.8v1.8h-1.8v1.8h1.8v1.8h-1.8v1.8h1.8v1.8h-1.8v1.8h3.609V6.312a1.8 1.8 0 0 0-1.809-1.8ZM9.022 7.217H1.8a1.8 1.8 0 0 0-1.8 1.8v9.022h10.826V9.017a1.8 1.8 0 0 0-1.804-1.8ZM4.511 18.043H1.8v-1.8h2.711Zm0-3.609H1.8v-1.8h2.711Zm4.511 3.609H6.315v-1.8h2.707Zm0-3.609H6.315v-1.8h2.707Zm-4.511-3.608H1.8V9.017h2.711Zm4.511 0H6.315V9.017h2.707ZM4.509 3.608h1.804v1.804H4.509zM9.021 3.608h1.804v1.804H9.021z"
    />
  </SvgIcon>
  )
}

