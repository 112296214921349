import { Box, Icon, Typography } from "@mui/material";
import { PopularFacilitiesCardStyle } from "./style";

export const PopularFacilitiesCard = ({ url = "", title = "" }) => {
  return (
    <>
      <Box sx={PopularFacilitiesCardStyle.maincard}>
        <Icon>{url}</Icon>
        <Typography sx={PopularFacilitiesCardStyle.text}>{title}</Typography>
      </Box>
    </>
  );
};
