import * as React from "react"

export const BackButton = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 110377">
      <g
        data-name="Rectangle 56186"
        transform="rotate(180 20 20)"
        fill="#fff"
        stroke="#ced3dd"
      >
        <rect width={40} height={40} rx={20} stroke="none" />
        <rect x={0.5} y={0.5} width={39} height={39} rx={19.5} fill="none" />
      </g>
      <g data-name="Group 110379">
        <path
          data-name="Path 100053"
          d="M22.887 27.243a.894.894 0 0 0 0-1.237l-5.339-5.507 5.34-5.506a.894.894 0 0 0 0-1.237.83.83 0 0 0-1.2 0l-5.939 6.125a.894.894 0 0 0 0 1.237l5.938 6.125a.83.83 0 0 0 1.2 0Z"
          fill="#091b29"
          stroke="#091b29"
          strokeWidth={0.25}
        />
      </g>
    </g>
  </svg>
)


