import { borderRadius, remCalc } from "../../../utils";
import { Medium, SemiBold } from "../../../utils/font";

export const propertyCardStyle = {
   searchMain: {
      // width: 'auto',
      // height: '150px',
      position: 'relative',
      borderRadius: borderRadius,
      opacity: 1,
      boxShadow: "0px 0px 32px #0000000f",
      cursor: "pointer"
   },
   propertyMain: {
      // width: 'auto',
      // height: '300px',
      position: 'relative',
      borderRadius: borderRadius,
      opacity: 1,
      boxShadow: "0px 0px 32px #0000000f",
      cursor: "pointer"

   },
   text: {
      position: "absolute",
      bottom: "0px",
      padding: '7px',
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, #091B2900,#000000BF)',
   },
   propertyText: {
      position: "absolute",
      bottom: "0px",
      padding: '16px',
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      color: "#fff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      width: '100%',
      backgroundImage: 'linear-gradient(180deg, #091B2900,#000000BF)',

   },
   desc: {
      position: 'absolute',
      bottom: '5px',
      fontSize: "0.75rem !important",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
   },
   propertyHeader: {
      fontFamily: Medium,
      fontSize: "1.125rem !important",
      marginBottom: '10px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",

   },
   propertyHeaderwrap: {
      fontFamily: Medium,
      fontSize: "1.125rem !important",
      marginBottom: '10px',
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",

   },
   height2: {
      height: "150px",
      width: "100%",
      borderRadius: borderRadius,
   },
   height: {
      height: "100%",
      width: "100%",
      borderRadius: borderRadius,
   },
   avatarBox: {
      display: "flex",
      justifyContent: "center",
   },
   avatar: {
      display: "flex",
      justifyContent: "center",

   },
   desc1: {
      position: 'absolute',
      bottom: '8px',
      fontSize: remCalc(12),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      mt: 32,
   },
   desc2: {
      position: 'absolute',
      mt: "32px",
      marginInlineStart: "95px",
      fontSize: remCalc(12),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontFamily: SemiBold,
      '@media screen and (min-width: 320px)': {
         width: "35px"
      },
      '@media screen and (min-width: 375px)': {
         width: "65px"
      },
      '@media screen and (min-width: 425px)': {
         width: "65px"
      },
   },
   divider: {
      position: 'absolute',
      mt: "35px",
      marginInlineStart: "85px",
      height: '12px',
      border: '1px solid #ffff'
   },

}