import * as React from "react"

export const Hamburger = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 112111">
      <path
        data-name="icons8-menu (1)"
        d="M4.8 6a.8.8 0 1 0 0 1.6h14.4a.8.8 0 1 0 0-1.6Zm0 5.2a.8.8 0 1 0 0 1.6h14.4a.8.8 0 1 0 0-1.6Zm0 5.2a.8.8 0 1 0 0 1.6h14.4a.8.8 0 1 0 0-1.6Z"
        fill="#0071f2"
      />
    </g>
  </svg>
)


