import * as React from "react"

export const LocaitonIMG = (props) => (
  <svg
    data-name="Group 110327"
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}
  >
    <g data-name="Group 111429">
      <path data-name="Rectangle 52046" fill="none" d="M0 0h16v16H0z" />
      <path
        data-name="icons8-location (4)"
        d="M7.877.5A6.373 6.373 0 0 0 3.03 11.015s2.713 3.1 3.679 4.017a1.694 1.694 0 0 0 2.326 0c1.1-1.05 3.68-4.018 3.68-4.018A6.372 6.372 0 0 0 7.877.5Zm0 1.125a5.246 5.246 0 0 1 3.991 8.658c-.005.006-2.644 3.024-3.6 3.94a.548.548 0 0 1-.773 0c-.8-.766-3.6-3.931-3.6-3.94a5.247 5.247 0 0 1 3.992-8.657Zm0 3a2.234 2.234 0 0 0-1.687.717 2.368 2.368 0 0 0 0 3.068 2.343 2.343 0 0 0 3.373 0 2.368 2.368 0 0 0 0-3.068 2.234 2.234 0 0 0-1.686-.716Zm0 1.125a1.037 1.037 0 0 1 .845.338 1.246 1.246 0 0 1 0 1.574A1.037 1.037 0 0 1 7.877 8a1.037 1.037 0 0 1-.845-.338 1.246 1.246 0 0 1 0-1.574 1.037 1.037 0 0 1 .845-.337Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)


