import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CustomButton, DialogBox, TextBox } from "../../components";
import { Box } from "@mui/material";
import { SignInStyle } from "./style"
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CreateNewAccount } from '..';
import { ForgetPassword } from './forgetPassword.js';
// import { useNavigate } from 'react-router-dom';

export const SignIn = (props) => {
    const { isPadding, loading, data, updateData = () => false, Login = () => false, handleClickShowPassword = () => false } = props
    const { t } = props
    // const history = useNavigate()
    // const auth = React.useContext(AuthContext)
    const [createAccountModal, setcreateAccountModal] = React.useState(false);
    const [ForgetPasswordModal, setForgetPasswordModal] = React.useState(false);

    const handlecreateAccountModalClose = () => {
        setcreateAccountModal(false);
    };
    const handleSignInOpen = () => {
        setcreateAccountModal(true);
    };
    const forgetPasswordModalClose = () => {
        setForgetPasswordModal(false);
    };
    const forgetpasswordOpen = () => {
        setcreateAccountModal(false);
        setForgetPasswordModal(true);
        // forgotPassword()
    };
    const CreateNewAccountHeader = (
        <Box>
            <Typography sx={SignInStyle.headerText} >{t("Create New Account")}</Typography>
        </Box>
    );
    const forgetPasswordHeader = (
        <Box>
            <Typography sx={SignInStyle.headerText} >{t("Forget Password")}</Typography>
        </Box>
    );
    return (
        <Box >
            <Box sx={isPadding ? SignInStyle.mainBoxpadding : SignInStyle.mainBox}>
                {/* input form name email & password  */}
                <Box sx={SignInStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={SignInStyle.email}
                        label={t("Email ID")}
                        placeholder={t("Enter email ID")}
                        type={"emailId"}
                        helperText={data?.error?.emailId}
                        value={data?.emailId}
                        onChange={(e) => updateData("emailId", e.target.value)}
                        error={data?.error?.emailId}

                    />
                </Box>
                <Box sx={SignInStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={SignInStyle.email}
                        label={t("Password")}
                        placeholder={t("Enter your password")}
                        type={data?.showPassword ? 'text' : 'password'}
                        value={data?.password}
                        helperText={data?.error?.password}
                        error={data?.error?.password}
                        onChange={(e) => updateData("password", e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {data?.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
                <Typography
                    sx={SignInStyle.forgetPasswordSx}
                >
                    {t("Did you forget your password?")} <span onClick={() => forgetpasswordOpen()}>{t("Click Here")}</span>
                </Typography>
                {/*create account button  */}
                <CustomButton
                    variant="outlined"
                    size="medium"
                    label={t("Sign in")}
                    rootStyle={SignInStyle.button}
                    onClick={() => Login()}
                    fullwidth
                    load={loading}
                    disabled={loading}
                >
                </CustomButton>
                <Typography sx={SignInStyle.create} onClick={() => handleSignInOpen()}
                >{t("Create New Account")}</Typography>
            </Box>
            {/* dialogBox */}
            <DialogBox
                open={createAccountModal}
                handleClose={handlecreateAccountModalClose}
                maxWidth="xs"
                component={<CreateNewAccount onClose={handlecreateAccountModalClose} />}
                header={CreateNewAccountHeader}
                height={"100% !important"}
            />
            <DialogBox
                open={ForgetPasswordModal}
                handleClose={forgetPasswordModalClose}
                maxWidth="xs"
                height={"100% !important"}
                component={<ForgetPassword
                    close={forgetPasswordModalClose}
                    t={t}
                />}
                header={forgetPasswordHeader}
            />
            <DialogBox
                open={ForgetPasswordModal}
                handleClose={forgetPasswordModalClose}
                maxWidth="xs"
                component={<ForgetPassword
                    close={forgetPasswordModalClose}
                    t={t}
                />}
                header={forgetPasswordHeader}
            />
        </Box>
    )
}



