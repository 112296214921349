import * as React from "react"

export const PlayIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M11.775 3a1.365 1.365 0 0 0-1.243.739.675.675 0 0 0-.033.076L9.406 6.878a1.411 1.411 0 0 0 1.275 1.937h.419v1.575a.675.675 0 1 0 1.35 0V8.815h.418a1.411 1.411 0 0 0 1.275-1.937L13.05 3.815a.675.675 0 0 0-.033-.076A1.365 1.365 0 0 0 11.775 3Zm0 1.308c.009 0 .018.012.03.035l1.067 2.989a.675.675 0 0 0 .033.075c.026.052.022.058-.036.058h-2.187c-.058 0-.062-.006-.036-.058a.675.675 0 0 0 .033-.075l1.067-2.989c.011-.021.02-.035.029-.035Zm5.85.007a2.475 2.475 0 0 0-.675 4.853v2.122a.675.675 0 1 0 1.35 0V9.17a2.474 2.474 0 0 0-.675-4.855Zm0 1.35A1.125 1.125 0 1 1 16.5 6.79a1.125 1.125 0 0 1 1.125-1.125Zm-10.949 3.6A3.686 3.686 0 0 0 3 12.941v1.5a5.635 5.635 0 0 0 5.625 5.625H17.4a3.6 3.6 0 1 0 0-7.2h-3.525a5.323 5.323 0 0 1-4.26-2.13 3.675 3.675 0 0 0-2.939-1.471Zm0 1.35a2.324 2.324 0 0 1 1.861.93 6.677 6.677 0 0 0 5.34 2.67H17.4a2.25 2.25 0 0 1 0 4.5H8.625A4.265 4.265 0 0 1 4.35 14.44v-1.5a2.316 2.316 0 0 1 2.325-2.325Zm-.3 3.6a.675.675 0 1 0 0 1.35h1.8a.675.675 0 1 0 0-1.35Zm8.55 1.35a.675.675 0 1 0 0 1.35h1.8a.675.675 0 1 0 0-1.35Zm-4.95.9a.675.675 0 1 0 0 1.35h1.8a.675.675 0 1 0 0-1.35Z"
      fill="#78b1fe"
      data-name="Group 111058"
    />
  </svg>
)

