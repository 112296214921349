import * as React from "react"

export const ParkingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
    <g data-name="Group 112087">
      <path data-name="Rectangle 57191" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="icons8-parking (1)"
        d="M7.889 5A2.9 2.9 0 0 0 5 7.889v10.222A2.9 2.9 0 0 0 7.889 21h10.222A2.9 2.9 0 0 0 21 18.111V7.889A2.9 2.9 0 0 0 18.111 5Zm0 1.333h10.222a1.546 1.546 0 0 1 1.556 1.556v10.222a1.546 1.546 0 0 1-1.556 1.556H7.889a1.546 1.546 0 0 1-1.556-1.556V7.889a1.546 1.546 0 0 1 1.556-1.556ZM11 8.556a.667.667 0 0 0-.667.667V14a.667.667 0 0 0 0 .216v3a.667.667 0 1 0 1.333 0v-2.438h1.778a3.111 3.111 0 1 0 0-6.222Zm.667 1.333h1.778a1.778 1.778 0 1 1 0 3.556h-1.778Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

