import * as React from "react"

export const ShortlistIcon = (props) => (
  <svg
    data-name="Group 112512"
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    {...props}
  >
    <path
      data-name="Subtraction 45"
      d="M19.353 21H5.146A1.151 1.151 0 0 1 4 19.846v-8.809a3.451 3.451 0 0 1 1.31-2.72l6.514-5.169a.684.684 0 0 1 .851 0l6.514 5.169a3.45 3.45 0 0 1 1.311 2.719v8.81A1.151 1.151 0 0 1 19.353 21ZM7.75 13.346v1.5h3.688v3.411h1.623v-3.41h3.688v-1.5h-3.688V9.935h-1.623v3.411Z"
      fill="#98a0ac"
    />
  </svg>
)

