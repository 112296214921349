import { SvgIcon } from "@mui/material";
import * as React from "react"

export const SearchIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 28,
      height: 28,
      color:"#98A0AC",
      ...rootStyle,
    };
    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <path
          d="M5.9 0a5.9 5.9 0 1 0 3.548 10.6l3.163 3.162a.814.814 0 1 0 1.151-1.151L10.6 9.448A5.886 5.886 0 0 0 5.9 0Zm0 1.627a4.273 4.273 0 0 1 3.112 7.2.814.814 0 0 0-.182.182A4.272 4.272 0 1 1 5.9 1.627Z"
          fill="#fff"
        />
      </SvgIcon>
    )

}

