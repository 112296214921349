import { IconButton, Box } from '@mui/material';
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { RightCircleArrow } from '../../assets';
import useDrag from './drag';
import { sliderStyle } from './style';
import './slider.css'
import { LeftArrowIcon } from '../../assets/components/leftArrow';

export const Scroller = ({ children, isOther = 0 }) => {
  const { dragStart, dragStop, dragMove } = useDrag()
  const handleDrag = ({ scrollContainer }) => ev =>
    dragMove(ev, posDiff => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff
      }
    })


  return (
    <ScrollMenu
      RightArrow={isOther === 1 ? RightArrow1 : isOther === 2 ? RightArrow2 : isOther === 3 ? RightArrow3 : isOther === 4 ? RightArrow4 : isOther === 5 ? RightArrow5 : null}
      onMouseDown={() => dragStart}
      onMouseUp={() => dragStop}
      onMouseMove={handleDrag}
      LeftArrow={isOther === 1 ? LeftArrow1 : isOther === 2 ? LeftArrow2 : isOther === 3 ? LeftArrow3 : isOther === 4 ? LeftArrow4 : isOther === 5 ? LeftArrow5 : null}

    >
      {children}
    </ScrollMenu>
  );
}


const RightArrow1 = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.rightRtl1 : sliderStyle.right1}>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()}>
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}



const RightArrow2 = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.rightRtl2 : sliderStyle.right2}>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()}>
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}


const RightArrow3 = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.rightRtl3 : sliderStyle.right3}>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()}>
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}


const RightArrow4 = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.rightRtl4 : sliderStyle.right4}>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()}>
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}
const RightArrow5 = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.rightRtl5 : sliderStyle.right5}>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()}>
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}
const LeftArrow1 = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.leftRtl1 : sliderStyle.left1}>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}>
        <LeftArrowIcon />
      </IconButton>
    </Box>

  );
}
const LeftArrow2 = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.leftRtl2 : sliderStyle.left2}>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}>
        <LeftArrowIcon />
      </IconButton>
    </Box>

  );
}
const LeftArrow3 = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.leftRtl3 : sliderStyle.left3}>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}>
        <LeftArrowIcon />
      </IconButton>
    </Box>

  );
}
const LeftArrow4 = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.leftRtl4 : sliderStyle.left4}>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}>
        {/* <LeftArrowIcon /> */}
        <RightCircleArrow />
      </IconButton>
    </Box>

  );
}
const LeftArrow5 = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box sx={language === 'ar' ? sliderStyle.leftRtl5 : sliderStyle.left5}>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}>
        <LeftArrowIcon />
      </IconButton>
    </Box>

  );
}






