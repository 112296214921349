import {
  Grid,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import React from "react";
import { lstingSearchStyle } from "./style";
import {
  Amenities,
  AreaIcon,
  Building,
  LocationIcon,
  ParkingIcon,
  Vacant,
} from "../../assets";
import { CustomButton } from "../Button";
import { withTranslation } from "react-i18next";
import { CarouselSliders } from "../imageCaurosel";
import { AuthContext } from "../../contexts";
import { InsideCard } from "../InsideCard";

const PropertyDetailCardNew = ({
  propertyId = '',
  header = "",
  subheader = "",
  buildDate = "",
  location = "",
  sqft = "",
  onebhk = "",
  twobhk = "",
  unitsAvailable = "",
  updatedDate = "",
  rate = "",
  currency = '',
  paymentType = '',
  offer = "",
  type = "",
  src = '',
  t = () => false,
  logo = '',
  variant = "",
  onClick = () => false,
  val,
  handleImageClick = () => false,
  searches,
  filters,
  selected,
  revenue_type,
  types
}) => {
  const auth = React.useContext(AuthContext)
  const history = useNavigate();
  const language = localStorage.getItem("i18nextLng")


  const onView = () => {
    history(variant === 'resident' ? auth?.clientRoutes?.residentView : auth?.clientRoutes?.commercialView, {
      state: {
        propertyId: propertyId,
        title: header,
        searches,
        filters,
        selected,
        revenue_type,
        types,
        breadscrumb: location
      }
    });
  };

  const data = [
    {
      icon: <AreaIcon />,
      name: t("Property Area"),
      value: sqft,
      borderTopLeftRadius: true

    },
    {
      icon: <Building />,
      name: t("Year Built"),
      value: buildDate,
    },
    {
      icon: <Vacant />,
      name: t("Total Units"),
      value: val?.val?.total_unit_count ?? "-",
      borderTopRightRadius: true
    },
    {
      icon: <Vacant />,
      name: t("Vacant Units"),
      value: unitsAvailable,
      borderBottomLeftRadius: true
    },
    {
      icon: <Amenities />,
      name: t("Amenities"),
      value: val?.is_community > 0 ? "Yes" : "No",
    },
    {
      icon: <ParkingIcon />,
      name: t("Parking"),
      value: val?.parking ?? "-",
      borderBottomRightRadius: true
    }

  ]

  return (
    <Box sx={lstingSearchStyle.main}>
      {/* Main Card */}
      <Grid container sx={lstingSearchStyle.inherit}>
        {/* Left Segment */}
        <Grid item xs={12} sm={12} lg={3.8} sx={lstingSearchStyle.relative}>
          <CarouselSliders data={val?.property_assets} logo={logo} onClick={() => handleImageClick(val)} />

          < Chip label={type} sx={language === "ar" ? lstingSearchStyle.chipArabic : lstingSearchStyle.chip} />
        </Grid>
        {/* Right Segment */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={8.2}
          sx={lstingSearchStyle.pl}
        >
          <Grid container height={"100%"}>
            {/* Right 1st Segment */}
            <Grid item xs={8.7} sx={lstingSearchStyle.firstHalf}>
              {/* 1 */}
              <Box sx={lstingSearchStyle.displayFlex}>
                <Box>
                  <Typography sx={lstingSearchStyle.firstHeading}>
                    {subheader}
                  </Typography>
                </Box>
                {val?.is_community &&
                  <Box sx={lstingSearchStyle.floatRight}>
                    < Chip label={"Community"} sx={lstingSearchStyle.chip2} />
                    <LocationIcon color={"#1F74EA"} />

                  </Box>
                }
              </Box>


              {/* 2 */}

              <Stack direction={"row"} alignItems={"center"}>
                {/* <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <Building />
                  <Typography sx={lstingSearchStyle.subheadingCustom}>
                    <Typography as="span" sx={lstingSearchStyle.normalBlack}>
                      {buildDate} <span style={lstingSearchStyle.span}>Build</span>
                    </Typography>
                  </Typography>
                </Stack> */}

                <Stack direction={"row"} alignItems={"center"}>
                  <LocationIcon />
                  <Typography sx={lstingSearchStyle.subheadingCustom}>
                    <Box as="span" sx={lstingSearchStyle.normalBlack}>
                      {location}
                    </Box>
                  </Typography>
                </Stack>

                <Typography sx={lstingSearchStyle.underline} onClick={() => onClick(val)}>
                  {t("View on Map")}
                </Typography>

              </Stack>


              <Grid container spacing={0.9} rowSpacing={0.7} maxWidth={"464px"}>
                {data?.map((val) => {
                  return (
                    <>
                      <Grid item xs={4}>
                        <InsideCard data={val} />
                      </Grid>
                    </>
                  )
                })}
              </Grid>



              <Box sx={lstingSearchStyle.stackbox}>
                {val?.val?.client_logo?.length > 0 && <Box sx={lstingSearchStyle.logo}>
                  <img src={val?.val?.client_logo} alt="logo" style={lstingSearchStyle.img} />
                </Box>}
                <Typography sx={lstingSearchStyle.subheading}>
                  {header}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={0.3} />
            {/* Right 2nd Segment */}
            <Grid item xs={3} sx={lstingSearchStyle.secondHalf}>

              <Box sx={lstingSearchStyle.alignEnd}>
                {rate !== null && <>
                  <Typography sx={lstingSearchStyle.normalBlackWithRegular}>
                    {t("Starts From")}
                  </Typography>
                  <Typography>
                    {currency} {rate}
                    <Box as="span" sx={lstingSearchStyle.subheading}>
                      {" "}
                      / {paymentType}
                    </Box>
                  </Typography>
                  <Typography sx={lstingSearchStyle.subheading}>
                    + {t("taxes & charges")}
                  </Typography>
                </>}

                {offer && (
                  <Typography sx={lstingSearchStyle.red}>
                    {t("Upto")} {offer} {t("offer")}
                  </Typography>
                )}
              </Box>
              <Box sx={lstingSearchStyle.viewButton}>
                <CustomButton
                  label={t("View Details")}
                  variant="contained"
                  color="primary"
                  // endIcon={
                  //   <RightArrow rootStyle={lstingSearchStyle.rightArrow} />
                  // }
                  onClick={() => onView()}
                  rootStyle={lstingSearchStyle.btn}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation("propertyDetailCardNew")(PropertyDetailCardNew)