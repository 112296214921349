import { SearchResultsCardStyle } from "./style";
import {
  Typography,
  Avatar,
} from "@mui/material";
import React from "react";
import { CustomButton } from "../Button";
import { withTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { CarouselSliders } from "../imageCaurosel";

const SearchResultsCard = ({
  startIcon,
  endIcon,
  chiplabel = "",
  headtitle = "",
  subtitle = "",
  url = "",
  val,
  id,
  date,
  onClickQuickView = () => false,
  onClickViewDetails = () => false,
  t = () => false,
  handleImageClick = () => false,

}) => {
  return (
    // main card images and chip label
    <Box sx={SearchResultsCardStyle.main}>
      <Box >
        {/* <Chip label={chiplabel} sx={SearchResultsCardStyle.chip} /> */}
        <Box sx={SearchResultsCardStyle?.topBox}>
          <Typography sx={SearchResultsCardStyle.chip}>{chiplabel}</Typography>
          {
            url ?
              <CarouselSliders img_style={SearchResultsCardStyle.image} data={val?.property_assets} logo={url} onClick={() => handleImageClick(val)}/>
              :
              <Box>
                <Box sx={SearchResultsCardStyle.image}>
                  <Box sx={SearchResultsCardStyle.avatar} >
                    <Avatar />
                  </Box>
                  <Box sx={SearchResultsCardStyle.avatarBox}>
                    <Typography >{t("No Data Found")}</Typography>
                  </Box>
                </Box>
              </Box>
          }
        </Box>
      </Box>
      {/* card title content */}

      <Box sx={SearchResultsCardStyle.cardcontent}>
        <Typography sx={SearchResultsCardStyle.maintext}>
          {headtitle}
        </Typography>
        <Typography sx={SearchResultsCardStyle.subText}>{subtitle}</Typography>
      </Box>
      {/* {card buttons} */}
      <Box sx={SearchResultsCardStyle.cardaction}>
        <CustomButton
          variant="outlined"
          size="medium"
          label={t("Quick View")}
          rootStyle={SearchResultsCardStyle.button1}
          onClick={() => onClickQuickView()}
          startIcon={startIcon}
          endIcon={endIcon}
        ></CustomButton>
        <CustomButton
          variant="outlined"
          size="medium"
          label={t("View Details")}
          rootStyle={SearchResultsCardStyle.button2}
          onClick={() => onClickViewDetails()}
          startIcon={startIcon}
          endIcon={endIcon}
        ></CustomButton>
      </Box>
    </Box>
  );
};
export default withTranslation("searchResultsCard")(SearchResultsCard)


