import * as React from "react"

export const RoomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M3.6 4.999a.614.614 0 0 0-.6.622v11.864a.614.614 0 1 0 1.227 0v-.614h15.546v.614a.614.614 0 1 0 1.227 0v-3.272a1.441 1.441 0 0 0-1.432-1.432h-.175a2.377 2.377 0 0 0 .38-1.227 2.464 2.464 0 0 0-2.455-2.455h-6.545a2.447 2.447 0 0 0-1.763.753c-.01-.012-.018-.024-.029-.036A2.239 2.239 0 0 0 7.3 9.099a2.239 2.239 0 0 0-1.686.717 2.363 2.363 0 0 0-.081 2.964h-1.3V5.621a.614.614 0 0 0-.633-.622Zm3.7 5.327a.934.934 0 0 1 .769.305 1.14 1.14 0 0 1 0 1.435.934.934 0 0 1-.769.305.934.934 0 0 1-.769-.305 1.14 1.14 0 0 1 0-1.435.934.934 0 0 1 .769-.305Zm3.477 0h6.545a1.228 1.228 0 1 1 0 2.455H9.545v-1.227a1.218 1.218 0 0 1 1.228-1.228Zm-6.55 3.682h15.341a.2.2 0 0 1 .2.2v1.432H4.227Z"
      fill="#78b1fe"
      data-name="Group 111058"
    />
  </svg>
)


