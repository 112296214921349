import * as React from "react";
import {Typography } from "@mui/material"
import {  SemiBold } from "../../utils/font";
import { remCalc } from "../../utils";

export const NoDataImageMob = (props) => {
  return  (
    <div>
      <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={365}
    height={393.369}
    viewBox="0 0 365 393.369"
    {...props}
  >
    <defs>
      <style>
        {
          ".a{fill:none;}.b{clip-path:url(#a);}.c{clip-path:url(#b);}.d{fill:#e4e8ee;opacity:0.43;}.e{fill:#fff;}.f,.n{fill:#011625;}.g{fill:#d5d5d5;}.h,.i{fill:#ccc6d9;}.i,.n,.o{fill-rule:evenodd;}.j{opacity:0.3;}.k{clip-path:url(#d);}.l,.o{fill:#1f74ea;}.m{fill:#e1dceb;}.p{fill:#98a0ac;font-size:20px;font-family:NunitoSans-Bold, Nunito Sans;font-weight:700;}"
        }
      </style>
      <clipPath id="a">
        <rect className="a" width={365} height={364.369} />
      </clipPath>
      <clipPath id="b">
        <path
          className="a"
          d="M30,174.845A145.811,145.811,0,1,0,175.811,29,145.828,145.828,0,0,0,30,174.845"
          transform="translate(-30 -29)"
        />
      </clipPath>
      <clipPath id="d">
        <rect className="a" width={133.232} height={130.03} />
      </clipPath>
    </defs>
    <g transform="translate(22794 15662)">
      <g transform="translate(-22794 -15662)">
        <g className="b" transform="translate(0 0)">
          <g transform="translate(37.101 35.536)">
            <g className="c">
              <rect
                className="d"
                width={293.318}
                height={293.386}
                transform="translate(-1.701 0.01) rotate(-0.334)"
              />
            </g>
          </g>
          <g className="b">
            <path
              className="e"
              d="M108.441,63.556H220.883A19.443,19.443,0,0,1,240.324,83V232.528a19.443,19.443,0,0,1-19.441,19.446H108.441A19.443,19.443,0,0,1,89,232.528V83a19.443,19.443,0,0,1,19.441-19.446"
              transform="translate(19.791 13.979)"
            />
            <path
              className="f"
              d="M105.954,83.556H154.8a5.955,5.955,0,0,1,0,11.911H105.954a5.955,5.955,0,0,1,0-11.911"
              transform="translate(22.157 18.286)"
            />
            <path
              className="g"
              d="M105.954,111.356h109.6a5.955,5.955,0,0,1,0,11.911h-109.6a5.955,5.955,0,0,1,0-11.911"
              transform="translate(22.157 24.273)"
            />
            <path
              className="g"
              d="M105.954,139.156h109.6a5.955,5.955,0,0,1,0,11.911h-109.6a5.955,5.955,0,0,1,0-11.911"
              transform="translate(22.157 30.261)"
            />
            <path
              className="g"
              d="M105.954,166.956h109.6a5.955,5.955,0,0,1,0,11.911h-109.6a5.955,5.955,0,0,1,0-11.911"
              transform="translate(22.157 36.248)"
            />
            <path
              className="g"
              d="M105.954,194.756h109.6a5.955,5.955,0,0,1,0,11.911h-109.6a5.955,5.955,0,0,1,0-11.911"
              transform="translate(22.157 42.236)"
            />
            <path
              className="e"
              d="M302.163,46.424h-73A6.329,6.329,0,0,0,223,52.908v36.12a6.329,6.329,0,0,0,6.159,6.484h73a6.328,6.328,0,0,0,6.159-6.484V52.908a6.328,6.328,0,0,0-6.159-6.484"
              transform="translate(48.614 10.289)"
            />
            <path
              className="h"
              d="M239.291,75.584A7.292,7.292,0,1,0,232,68.292a7.291,7.291,0,0,0,7.291,7.292"
              transform="translate(50.55 13.428)"
            />
            <path
              className="g"
              d="M259.291,61h24.3a7.292,7.292,0,0,1,0,14.584h-24.3a7.292,7.292,0,0,1,0-14.584"
              transform="translate(54.851 13.428)"
            />
            <path
              className="i"
              d="M198.553,252.485a75.577,75.577,0,0,0,42.279-12.851l49.453,47.22,17.936-20.814L260.5,220.475a75.945,75.945,0,1,0-61.948,32.009m64.775-75.961a64.295,64.295,0,1,1-64.3-64.309,64.3,64.3,0,0,1,64.3,64.309"
              transform="translate(27.02 21.949)"
            />
            <g className="j" transform="translate(159.6 132.888)">
              <g transform="translate(0 0)">
                <g className="k" transform="translate(0 0)">
                  <path
                    className="e"
                    d="M197.223,240.26c36.574,0,66.223-29.384,66.223-65.63S233.8,109,197.223,109,131,138.384,131,174.63s29.648,65.63,66.223,65.63"
                    transform="translate(-130.775 -109.122)"
                  />
                </g>
              </g>
            </g>
            <path
              className="l"
              d="M202.435,168.384l16.026-15.968a8.315,8.315,0,0,0-.165-11.637,8.435,8.435,0,0,0-11.706-.165l-16.061,15.968L174.5,140.614a8.43,8.43,0,0,0-12.043-.169,8.318,8.318,0,0,0,.171,11.971l16.053,15.968-16.053,15.968a8.318,8.318,0,0,0-.171,11.971,8.431,8.431,0,0,0,12.043-.169l16.061-15.968,16.062,15.968a8.435,8.435,0,0,0,11.5-.371,8.313,8.313,0,0,0,.338-11.432Z"
              transform="translate(35.063 30.012)"
            />
            <path
              className="m"
              d="M260.6,257.531l17.936-20.815,2.7,2.58a14.569,14.569,0,0,1,.919,19.839,13.084,13.084,0,0,1-9.233,4.592,12.951,12.951,0,0,1-9.614-3.625l-2.7-2.579Z"
              transform="translate(56.701 51.273)"
            />
            <path
              className="n"
              d="M66.921,97.762a22.986,22.986,0,0,0-.674-5.242C64.722,86.344,57.92,82.378,50.68,81.446s-14.762,1.19-17.663,6.563c-1.659,3.071-1.887,5.718-1.209,7.951a10.344,10.344,0,0,0,4.45,5.5c6.061,3.995,16.676,4.81,21.519,3.141a54.154,54.154,0,0,0,6.558-2.773c-1.218,6.984-5.757,13.6-11.816,19.56C39.353,134.34,18.922,144.175,7.8,147.791a1.193,1.193,0,0,0-.744,1.482,1.125,1.125,0,0,0,1.418.78c11.348-3.687,32.176-13.731,45.6-26.945C61.01,116.284,65.951,108.6,66.8,100.523c15.681-8.752,28.557-24.631,39.567-38a1.22,1.22,0,0,0-.12-1.67,1.1,1.1,0,0,0-1.6.125C94.083,73.8,81.827,89.046,66.921,97.762m-2.28,1.27a20.7,20.7,0,0,0-.592-5.923C62.731,87.764,56.67,84.6,50.4,83.8a20.261,20.261,0,0,0-10.89,1.36,9.528,9.528,0,0,0-4.519,4.018c-1.27,2.352-1.544,4.363-1.024,6.07a8,8,0,0,0,3.5,4.208c5.524,3.642,15.189,4.417,19.6,2.9a53.315,53.315,0,0,0,7.576-3.313"
              transform="translate(2.154 13.336)"
            />
            <path
              className="l"
              d="M286.291,178A7.292,7.292,0,1,1,279,185.292,7.291,7.291,0,0,1,286.291,178"
              transform="translate(60.659 38.627)"
            />
            <path
              className="o"
              d="M58.981,227.8a11.886,11.886,0,0,0,3.727-2.076,7.215,7.215,0,0,0,2.106-4.063c.44-1.943.616-4.012,1.149-5.958a1.865,1.865,0,0,1,.74-1.117,1.744,1.744,0,0,1,1.22-.357,1.772,1.772,0,0,1,1.523,1.039,2.205,2.205,0,0,1,.19.541c.038.18.063.74.1.972.1.57.185,1.14.265,1.712a16.737,16.737,0,0,0,1.249,5.28c1.131,2.379,2.265,3.833,3.8,4.479,1.487.623,3.264.506,5.535.017q.324-.082.642-.141a1.878,1.878,0,0,1,2.156,1.558,1.969,1.969,0,0,1-1.433,2.3c-.207.049-.411.094-.612.136-3.069.8-6.622,3.653-8.687,6.152-.635.771-1.567,2.925-2.518,4.3a5,5,0,0,1-2.152,1.919,1.844,1.844,0,0,1-1.125.053,1.9,1.9,0,0,1-1.107-.779,2.275,2.275,0,0,1-.369-.9,7.04,7.04,0,0,1-.035-.861c-.194-.7-.431-1.385-.6-2.094a12.14,12.14,0,0,0-2.18-4.169,7.6,7.6,0,0,0-3.277-2.815c-.183-.047-1.668-.43-2.192-.649a2.389,2.389,0,0,1-1.264-1.152,2.31,2.31,0,0,1-.2-1.282,2.131,2.131,0,0,1,.694-1.349,2.4,2.4,0,0,1,1.115-.557,14.481,14.481,0,0,1,1.543-.14m8.709-2.752c.068.162.142.323.22.487,1.656,3.483,3.509,5.429,5.762,6.372l.075.03a25.651,25.651,0,0,0-3.913,3.756,22.964,22.964,0,0,0-1.612,2.705,16.863,16.863,0,0,0-2.616-4.947,11.381,11.381,0,0,0-2.924-3.048,12.882,12.882,0,0,0,2.439-1.674,9.775,9.775,0,0,0,2.569-3.683"
              transform="translate(12.609 46.428)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
<Typography style={{fontSize:remCalc(14),fontFamily: SemiBold}}>No Data Found</Typography>
    </div>
  )
}
