import React from 'react';
import { TextBox } from '../textbox';
import { MobileNumberInputComponent } from '../mobile';
import { Grid } from '@mui/material';
import { enquirystyle } from './style';
import { CustomButton } from '../Button';
import { withTranslation } from 'react-i18next';

const InitialState = {
  name: "",
  email: "",
  desc: "",
  mobile: "",
  error: {
    name: "",
    email: "",
    desc: "",
    mobile: ""
  }
}

const EnquiryForm = ({
  disable = false,
  t=()=> false
}) => {

  const [data, setData] = React.useState({ ...InitialState });

  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };

  return (
    <Grid container spacing={2} sx={enquirystyle.main}>
      {/* name  */}
      <Grid item xs={12}>
        <TextBox
          label={t("Name")}
          placeholder={t("Name")}
          isReadonly={disable}
          value={data?.name}
          onChange={(e) => {
            updateState("name", e.target.value);
          }}
          isError={data?.error?.name?.length > 0}
          errorMessage={data?.error?.name}
        />
      </Grid>
      {/*email  */}
      <Grid item xs={12}>
        <TextBox
          label={t("Mail Id")}
          placeholder={t("Email Address")}
          value={data?.email}
          isReadonly={disable}
          onChange={(e) => {
            updateState("email", e.target.value);
          }}
          isError={data?.error?.email?.length > 0}
          errorMessage={data?.error?.email}
        />
      </Grid>
      {/* Mobile */}
      <Grid item xs={12}>
        <MobileNumberInputComponent
          label={t("Mobile")}
          placeholder={t("Mobile")}
          value={data?.mobile}
          handleChange={(value) => {
            updateState("mobile", value);
          }}
          isReadonly={disable}
        />
      </Grid>
      {/* Desc  */}
      <Grid item xs={12}>
        <TextBox
          label={t("Description/Requirement")}
          placeholder={""}
          isReadonly={disable}
          value={data?.desc}
          multiline
          rowheight='4'
          onChange={(e) => {
            updateState("desc", e.target.value);
          }}
          isError={data?.error?.desc?.length > 0}
          errorMessage={data?.error?.desc}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomButton label={t('Contact Sales')} color='primary' variant='contained' rootStyle={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
}
export default withTranslation()(EnquiryForm)