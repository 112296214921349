import * as React from "react"

export const SchoolBuilding = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M11.981 2.25a.714.714 0 0 0-.24.049l-9.76 3.809a.714.714 0 1 0 .519 1.33L12 3.73l9.5 3.708a.714.714 0 1 0 .519-1.33l-9.76-3.809a.714.714 0 0 0-.278-.049ZM4.854 8.439A1.428 1.428 0 0 0 3.43 9.867v10a1.428 1.428 0 0 0 1.424 1.427h5.5a1.889 1.889 0 0 0 3.281 0h5.5a1.428 1.428 0 0 0 1.428-1.428v-10a1.428 1.428 0 0 0-1.428-1.428h-4.281a2.143 2.143 0 0 0-2.142 2.142v9.046a.714.714 0 1 1-1.428 0v-9.044a2.143 2.143 0 0 0-2.14-2.143Z"
      fill={props?.color ?? "#4e5a6b"}
      data-name="Group 111271"
    />
  </svg>
)

