import { InputAdornment, TextField } from "@mui/material";
// import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/components/calendar";
import { DateStyle } from "./style";

const ExampleCustomInput = React.forwardRef(
  (
    {
      value = "",
      onClick = () => false,
      onChange = () => false,
      placeholder = "",
      fieldStyle = {},
      endAdornment = "",
    },
    ref
  ) => {

    return (
      <TextField
        sx={{ ...DateStyle.text, ...fieldStyle }}
        value={value}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {" "}
              <CalendarIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
    );
  }
);

export const DatePickerReactOld = ({ onChange = () => false, selected = "", fieldStyle = {}, placeholder = '', minDate = "", maxDate = "" }) => {
  return (
    <DatePicker
      // selected={selected ?? new Date(moment().format("YYYY-MM-DD"))}
      selected={selected}
      onChange={onChange}
      inline
      // customInput={<ExampleCustomInput fieldStyle={fieldStyle} placeholder={placeholder} />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      minDate={minDate ?? new Date()}
      dateFormat={"dd-MMM-yyyy"}
      placeholderText={placeholder}
    />
  );
};