import { SvgIcon } from "@mui/material"
import * as React from "react"

export const TimerIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#091b29",
      ...rootStyle,
    };
  return(
    <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
    <path
      data-name="Path 100109"
      d="M13.515 2.342A8 8 0 0 0 2.9 1.722l-.218-.219a.469.469 0 0 0-.794.253l-.33 1.929a.469.469 0 0 0 .542.541l1.93-.329a.469.469 0 0 0 .253-.794l-.238-.238a6.4 6.4 0 1 1-2.463 6.409.788.788 0 0 0-.849-.625.8.8 0 0 0-.717.956 8 8 0 1 0 13.499-7.263Z"
      fill="#98a0ac"
    />
    <path
      data-name="Path 100110"
      d="m10.419 10.234-.934-1.208a2.41 2.41 0 0 1-.492-1.254l-.337-3.773a.8.8 0 0 0-.8-.8.8.8 0 0 0-.8.8l-.3 3.91a2.4 2.4 0 0 0 .841 2.018l1.7 1.438a.8.8 0 0 0 1.132 0 .8.8 0 0 0-.01-1.131Z"
      fill="currentColor"
    />
  </SvgIcon>
  )  
 
}