import { Hidden, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useDrag from './drag';
import './slider.css'
import { CalenderStyle } from "./style"

export const CalenderScroller = ({ children, isOther = 0 }) => {
  const { dragStart, dragStop, dragMove } = useDrag()
  const handleDrag = ({ scrollContainer }) => ev =>
    dragMove(ev, posDiff => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff
      }
    })
  return (
    <ScrollMenu
      RightArrow={RightArrow}
      LeftArrow={LeftArrow}
      onMouseDown={() => dragStart}
      onMouseUp={() => dragStop}
      onMouseMove={handleDrag}
    >
      {children}
    </ScrollMenu>
  );
}


const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
  } = React.useContext(VisibilityContext);


  return (
    <Hidden smDown>
      <IconButton hidden={isFirstItemVisible} onClick={() => scrollPrev()}  >
        <KeyboardArrowLeftIcon sx={localStorage?.i18nextLng === "ar" ? CalenderStyle.arrowArabic : ""}  />
      </IconButton>
    </Hidden>
  );
}

const RightArrow = () => {
  const {
    isLastItemVisible,
    scrollNext,
  } = React.useContext(VisibilityContext);


  return (
    <Hidden smDown>
      <IconButton hidden={isLastItemVisible} onClick={() => scrollNext()} >
        <KeyboardArrowRightIcon sx={localStorage?.i18nextLng === "ar" ? CalenderStyle.arrowArabic : ""}  />
      </IconButton>
    </Hidden>

  );
}






