import { Medium, SemiBold } from "../../utils/font";

export const labelStyles = {
  label: (props) => {
    return {
      color: props?.labelColor ?? 'text.secondary',
      fontFamily: (props?.fontFamily === Medium ? Medium : SemiBold),
      fontSize: props?.labelSize ?? ' 0.75rem',
      marginBottom: props?.labelMarginBottom ?? "5px",
      textAlign: props?.textAlign ?? "-webkit-match-parent",
    }
  },
  labelArabic: (props) => {
    return {
      color: props?.labelColor ?? 'text.secondary',
      fontFamily: (props?.fontFamily === Medium ? Medium : SemiBold),
      fontSize: props?.labelSize ?? '12px',
      marginBottom: props?.labelMarginBottom ?? "5px",
      // textAlign: props?.textAlign ?? "left",
    }
  },
  required: (props) => {
    return {
      color: "error.light",
      fontFamily: SemiBold,
      fontSize: props?.labelSize ?? ' 0.75rem',
      marginBottom: props?.labelMarginBottom ?? "5px",
    }
  },
};
