import * as React from "react";
export const BlueTick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.998}
    height={16}
    viewBox="0 0 15.998 16"
    {...props}
  >
    <g
      id="Group_112972"
      data-name="Group 112972"
      transform="translate(-660.002 -304.998)"
    >
      <g id="Group_114319" data-name="Group 114319">
        <path
          id="Subtraction_49"
          data-name="Subtraction 49"
          d="M21515,21324.871a8,8,0,1,1,8-8A8.009,8.009,0,0,1,21515,21324.871Zm-4.029-9.148-1.344,1.479,4.186,3.791,6.324-7.129-1.49-1.324-4.99,5.617Z"
          transform="translate(-20847 -21003.873)"
          fill="#1f74ea"
        />
      </g>
    </g>
  </svg>
);

