import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/components/calendar";
import { DateRangeStyle } from "./style";
import "./styles.css";

const ExampleCustomInput = React.forwardRef(
  (
    {
      value = "",
      onClick = () => false,
      onChange = () => false,
      placeholder = "Check-in | Check-out",
      fieldStyle={},
    }, 
    ref
  ) => {
    return (
      <TextField
        sx={{...DateRangeStyle.text,...fieldStyle}}
        value={value}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {" "}
              <CalendarIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export const DateRangePickerReact = ({ onChange = () => false, selected = "",fieldStyle={},startDate='',endDate='' }) => {
  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      customInput={<ExampleCustomInput fieldStyle={fieldStyle}/>}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      selectsRange
      peekNextMonth
      startDate={startDate}
      endDate={endDate}
      monthsShown={2}
    />
  );
};
