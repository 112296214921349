import { SvgIcon } from "@mui/material"
import * as React from "react"

export const DropUp = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#1f74ea",
      ...rootStyle,
    };
    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <path
          data-name="icons8-sort-down (1)"
          d="M.422 6.188h8.156a.422.422 0 0 0 .336-.678L4.836.166a.422.422 0 0 0-.671 0L.087 5.51a.422.422 0 0 0 .335.678Z"
          fill="currentColor"
        />
      </SvgIcon>
    )

}

