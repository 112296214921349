import { SvgIcon } from "@mui/material"
import * as React from "react"

export const FloorIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98A0AC",
      ...rootStyle,
    };
    return(
<SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
    <path
      d="M2.526 0A2.535 2.535 0 0 0 0 2.526v8.94a2.535 2.535 0 0 0 2.526 2.526h3.4a.583.583 0 0 0 .189 0h5.348a2.535 2.535 0 0 0 2.526-2.526v-8.94A2.535 2.535 0 0 0 11.466 0H9.523a.583.583 0 1 0 0 1.166h1.943a1.352 1.352 0 0 1 1.36 1.36V7h-1.749a.583.583 0 1 0 0 1.166h1.749v3.3a1.352 1.352 0 0 1-1.36 1.36H6.607V8.162h1.36a.583.583 0 1 0 0-1.166H3.692a.583.583 0 1 0 0 1.166h1.749v4.664H2.526a1.352 1.352 0 0 1-1.36-1.36v-8.94a1.352 1.352 0 0 1 1.36-1.36h1.757L6.852 3a.583.583 0 1 0 .677-.949L4.808.109A.583.583 0 0 0 4.47 0Z"
      fill="currentColor"
    />
  </SvgIcon>
    )
  
}

