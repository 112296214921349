import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Typography } from "@mui/material";
import { ResidentialBreadcrumbStyle } from "./style";

export const ResidentialBreadcrumb = ({ data = []}) => {
  const language = localStorage.getItem("i18nextLng")
  return (
    <>
    {/* breadcrumb */}
      <Breadcrumbs
        sx={ResidentialBreadcrumbStyle.breadcrum}
        separator={language === "ar" ? <NavigateBeforeIcon fontSize="small" /> : <NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {data?.map((item, index) => {
          return (
            <>
              {
                <Typography 
                  sx={data.length-1 === index ? ResidentialBreadcrumbStyle.endtext: ResidentialBreadcrumbStyle.text }
                >
                  {item.name}
                </Typography>
              }
            </>
          );
        })}
      </Breadcrumbs>
    </>
  );
};
