import * as React from "react"
export const DrawerCloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112243"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#f7f7f7" data-name="Ellipse 129667" />
    <path
      fill="#091b29"
      d="M26.365 13a.617.617 0 0 0-.43.19l-5.938 5.938-5.938-5.938a.617.617 0 1 0-.872.872L19.125 20l-5.938 5.937a.617.617 0 1 0 .872.872l5.938-5.938 5.938 5.938a.617.617 0 1 0 .872-.872l-5.938-5.938 5.937-5.937A.617.617 0 0 0 26.364 13Z"
      data-name="icons8-delete (3)"
    />
  </svg>
)