import * as React from "react";
export const ApartmentImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={272}
    height={140}
    viewBox="0 0 272 140"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_40359"
          data-name="Ellipse 40359"
          cx={46}
          cy={46}
          r={46}
          transform="translate(0 -0.484)"
          fill="#f5f7fa"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1={-3.333}
        y1={0.5}
        x2={-2.333}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={0.845}
        x2={0.279}
        y2={1.171}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#e8edf3" />
      </linearGradient>
    </defs>
    <g
      id="Group_112560"
      data-name="Group 112560"
      transform="translate(-85 -916)"
    >
      <rect
        id="NoPath_-_Copy_2_"
        data-name="NoPath - Copy (2)"
        width={287}
        height={150}
        rx={16}
        transform="translate(85 916)"
        fill="#e4e8ee"
      />
      <g
        id="Group_112551"
        data-name="Group 112551"
        transform="translate(69.066 857.572)"
      >
        <g
          id="Group_95400"
          data-name="Group 95400"
          transform="translate(113.934 76.912)"
        >
          <circle
            id="Ellipse_40358"
            data-name="Ellipse 40358"
            cx={46}
            cy={46}
            r={46}
            transform="translate(0 -0.484)"
            fill="#f5f7fa"
            opacity={0.5}
          />
          <g
            id="Mask_Group_6347"
            data-name="Mask Group 6347"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <g
              id="Group_95399"
              data-name="Group 95399"
              transform="translate(-13.149 17.538)"
            >
              <g id="icons8-skyscrapers" transform="translate(31.698)">
                <path
                  id="Path_6986"
                  data-name="Path 6986"
                  d="M28,11H44.759a1.524,1.524,0,0,1,1.524,1.524v4.571H28Z"
                  transform="translate(7.042 4.236)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6987"
                  data-name="Path 6987"
                  d="M25,14H50.9a1.524,1.524,0,0,1,1.524,1.524V69.844H25Z"
                  transform="translate(5.472 5.807)"
                  fill="#e4e8ee"
                />
                <path
                  id="Path_6988"
                  data-name="Path 6988"
                  d="M26.283,21.236H8V17.025A3.047,3.047,0,0,1,9.684,14.3L26.283,6Z"
                  transform="translate(-3.429 1.618)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6989"
                  data-name="Path 6989"
                  d="M41.094,19.047h3.047V16H41.094Zm0,6.094h3.047V22.094H41.094ZM35,19.047h3.047V16H35Zm0,6.094h3.047V22.094H35Zm6.094,6.094h3.047V28.189H41.094Zm0,6.094h3.047V34.283H41.094Zm0,6.094h3.047V40.377H41.094ZM35,31.236h3.047V28.189H35Zm0,6.094h3.047V34.283H35Zm0,6.094h3.047V40.377H35Zm6.094,6.094h3.047V46.472H41.094Zm0,6.094h3.047V52.566H41.094ZM35,49.519h3.047V46.472H35Zm0,6.094h3.047V52.566H35Z"
                  transform="translate(10.707 6.854)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6990"
                  data-name="Path 6990"
                  d="M5,72.986H32.424V8L6.81,19.384A3.047,3.047,0,0,0,5,22.169Z"
                  transform="translate(-5 2.665)"
                  fill="#e4e8ee"
                />
                <rect
                  id="Rectangle_50357"
                  data-name="Rectangle 50357"
                  width={18.711}
                  height={6.804}
                  transform="translate(35.752 21.701) rotate(180)"
                  fill="url(#linear-gradient)"
                />
                <path
                  id="Path_6991"
                  data-name="Path 6991"
                  d="M10.047,22.094H7v3.047h3.047ZM16.141,16H13.094v3.047h3.047Zm0,6.094H13.094v3.047h3.047Zm-6.094,6.094H7v3.047h3.047Zm0,6.094H7V37.33h3.047Zm0,6.094H7v3.047h3.047Zm6.094-12.189H13.094v3.047h3.047Zm0,6.094H13.094V37.33h3.047Zm0,6.094H13.094v3.047h3.047Zm-6.094,6.094H7v3.047h3.047Zm0,6.094H7v3.047h3.047Zm6.094-6.094H13.094v3.047h3.047Zm0,6.094H13.094v3.047h3.047Z"
                  transform="translate(-3.953 6.854)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_7007"
                  data-name="Path 7007"
                  d="M3.047,6.094H0V9.141H3.047ZM9.141,0H6.094V3.047H9.141Zm0,6.094H6.094V9.141H9.141ZM3.047,12.189H0v3.047H3.047Zm0,6.094H0V21.33H3.047Zm0,6.094H0v3.047H3.047ZM9.141,12.189H6.094v3.047H9.141Zm0,6.094H6.094V21.33H9.141Zm0,6.094H6.094v3.047H9.141ZM3.047,30.472H0v3.047H3.047Zm0,6.094H0v3.047H3.047Zm6.094-6.094H6.094v3.047H9.141Zm0,6.094H6.094v3.047H9.141Z"
                  transform="translate(12.189 105.379) rotate(180)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_7009"
                  data-name="Path 7009"
                  d="M3.047,6.094H0V9.141H3.047ZM9.141,0H6.094V3.047H9.141Zm0,6.094H6.094V9.141H9.141ZM3.047,12.189H0v3.047H3.047Zm0,6.094H0V21.33H3.047Zm0,6.094H0v3.047H3.047ZM9.141,12.189H6.094v3.047H9.141Zm0,6.094H6.094V21.33H9.141Zm0,6.094H6.094v3.047H9.141ZM3.047,30.472H0v3.047H3.047Zm0,6.094H0v3.047H3.047Zm6.094-6.094H6.094v3.047H9.141Zm0,6.094H6.094v3.047H9.141Z"
                  transform="translate(54.849 105.379) rotate(180)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6992"
                  data-name="Path 6992"
                  d="M16.524,6H40.9a1.524,1.524,0,0,1,1.524,1.524V74.033H15V7.524A1.524,1.524,0,0,1,16.524,6Z"
                  transform="translate(0.236 1.618)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6993"
                  data-name="Path 6993"
                  d="M20.524,1H32.712a1.524,1.524,0,0,1,1.524,1.524v7.618H19V2.524A1.524,1.524,0,0,1,20.524,1Z"
                  transform="translate(2.33 -1)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6994"
                  data-name="Path 6994"
                  d="M38.33,10.618v3.047H35.283V10.618Zm-3.047,9.141H38.33V16.712H35.283Zm0,6.094H38.33V22.807H35.283Zm0,6.094H38.33V28.9H35.283ZM29.189,13.665h3.047V10.618H29.189Zm0,6.094h3.047V16.712H29.189Zm0,6.094h3.047V22.807H29.189Zm0,6.094h3.047V28.9H29.189ZM23.094,13.665h3.047V10.618H23.094Zm6.094-6.094h3.047V3H29.189Zm-6.094,0h3.047V3H23.094Zm0,12.189h3.047V16.712H23.094Zm0,6.094h3.047V22.807H23.094Zm0,6.094h3.047V28.9H23.094ZM17,13.665h3.047V10.618H17Zm0,6.094h3.047V16.712H17Zm0,6.094h3.047V22.807H17Zm0,6.094h3.047V28.9H17Zm18.283,6.094H38.33V35H35.283Zm0,6.094H38.33V41.09H35.283Zm0,6.094H38.33V47.184H35.283ZM29.189,38.042h3.047V35H29.189Zm0,6.094h3.047V41.09H29.189Zm0,6.094h3.047V47.184H29.189ZM23.094,38.042h3.047V35H23.094Zm0,6.094h3.047V41.09H23.094Zm0,6.094h3.047V47.184H23.094ZM17,38.042h3.047V35H17Zm0,6.094h3.047V41.09H17Zm0,6.094h3.047V47.184H17Zm18.283,6.094H38.33V53.278H35.283Zm0,6.094H38.33V59.373H35.283Zm-6.094-6.094h3.047V53.278H29.189Zm0,6.094h3.047V59.373H29.189Zm-6.094-6.094h3.047V53.278H23.094Zm0,6.094h3.047V59.373H23.094ZM17,56.325h3.047V53.278H17Zm0,6.094h3.047V59.373H17Z"
                  transform="translate(1.283 0.047)"
                  fill="#e4e8ee"
                />
                <path
                  id="Path_7008"
                  data-name="Path 7008"
                  d="M21.33,7.618v3.047H18.283V7.618Zm-3.047,9.141H21.33V13.712H18.283Zm0,6.094H21.33V19.807H18.283Zm0,6.094H21.33V25.9H18.283ZM12.189,10.665h3.047V7.618H12.189Zm0,6.094h3.047V13.712H12.189Zm0,6.094h3.047V19.807H12.189Zm0,6.094h3.047V25.9H12.189ZM6.094,10.665H9.141V7.618H6.094Zm6.094-6.094h3.047V0H12.189Zm-6.094,0H9.141V0H6.094Zm0,12.189H9.141V13.712H6.094Zm0,6.094H9.141V19.807H6.094Zm0,6.094H9.141V25.9H6.094ZM0,10.665H3.047V7.618H0Zm0,6.094H3.047V13.712H0Zm0,6.094H3.047V19.807H0Zm0,6.094H3.047V25.9H0Zm18.283,6.094H21.33V32H18.283Zm0,6.094H21.33V38.09H18.283Zm0,6.094H21.33V44.184H18.283ZM12.189,35.042h3.047V32H12.189Zm0,6.094h3.047V38.09H12.189Zm0,6.094h3.047V44.184H12.189ZM6.094,35.042H9.141V32H6.094Zm0,6.094H9.141V38.09H6.094Zm0,6.094H9.141V44.184H6.094ZM0,35.042H3.047V32H0Zm0,6.094H3.047V38.09H0Zm0,6.094H3.047V44.184H0Zm18.283,6.094H21.33V50.278H18.283Zm0,6.094H21.33V56.373H18.283Zm-6.094-6.094h3.047V50.278H12.189Zm0,6.094h3.047V56.373H12.189ZM6.094,53.325H9.141V50.278H6.094Zm0,6.094H9.141V56.373H6.094ZM0,53.325H3.047V50.278H0ZM0,59.42H3.047V56.373H0Z"
                  transform="translate(39.613 125.186) rotate(180)"
                  fill="#e4e8ee"
                />
              </g>
              <g
                id="Group_95391"
                data-name="Group 95391"
                transform="translate(0 31.596)"
              >
                <path
                  id="blob_1_"
                  data-name="blob (1)"
                  d="M15.729,4c1.317,1.1,2.98,1.4,4.033,2.374,1.07.969,1.547,2.584,2.14,4.8.576,2.212,1.268,5.006.379,6.992-.889,2-3.375,3.181-5.91,3.682A14.792,14.792,0,0,1,8.9,21.377a10.184,10.184,0,0,1-5.614-4C2.148,15.515,2,13.141,1.324,10.638S-.552,5.52.287,3.84C1.11,2.161,3.975,1.418,6.329.788,8.7.158,10.576-.358,12.009.32,13.424,1.014,14.412,2.9,15.729,4Z"
                  transform="translate(16.056 31.698) rotate(-135)"
                  fill="url(#linear-gradient-2)"
                />
                <g
                  id="Group_95390"
                  data-name="Group 95390"
                  transform="translate(12.703 22.1)"
                >
                  <path
                    id="Path_7005"
                    data-name="Path 7005"
                    d="M7779.6,5671.872s.053-3.915,0-6.833-3.442-1.185-4.353-3.768"
                    transform="translate(-7775.25 -5660.053)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                  <path
                    id="Path_7006"
                    data-name="Path 7006"
                    d="M7778.659,5664.127a25.726,25.726,0,0,0,3.123-3.694,3.5,3.5,0,0,0,.677-2.536"
                    transform="translate(-7774.18 -5657.897)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                </g>
              </g>
              <g
                id="Group_95398"
                data-name="Group 95398"
                transform="translate(85.825 31.596)"
              >
                <path
                  id="blob_1_2"
                  data-name="blob (1)"
                  d="M15.729,4c1.317,1.1,2.98,1.4,4.033,2.374,1.07.969,1.547,2.584,2.14,4.8.576,2.212,1.268,5.006.379,6.992-.889,2-3.375,3.181-5.91,3.682A14.792,14.792,0,0,1,8.9,21.377a10.184,10.184,0,0,1-5.614-4C2.148,15.515,2,13.141,1.324,10.638S-.552,5.52.287,3.84C1.11,2.161,3.975,1.418,6.329.788,8.7.158,10.576-.358,12.009.32,13.424,1.014,14.412,2.9,15.729,4Z"
                  transform="translate(16.056 31.698) rotate(-135)"
                  fill="url(#linear-gradient-2)"
                />
                <g
                  id="Group_95390-2"
                  data-name="Group 95390"
                  transform="translate(12.703 22.1)"
                >
                  <path
                    id="Path_7005-2"
                    data-name="Path 7005"
                    d="M7779.6,5671.872s.053-3.915,0-6.833-3.442-1.185-4.353-3.768"
                    transform="translate(-7775.25 -5660.053)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                  <path
                    id="Path_7006-2"
                    data-name="Path 7006"
                    d="M7778.659,5664.127a25.726,25.726,0,0,0,3.123-3.694,3.5,3.5,0,0,0,.677-2.536"
                    transform="translate(-7774.18 -5657.897)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <text
          id="No_Image_Found"
          data-name="No Image Found"
          transform="translate(159.934 186.428)"
          fill="#98a0ac"
          fontSize={10}
          fontFamily="NunitoSans-Bold, Nunito Sans"
          fontWeight={700}
        >
          <tspan x={-37} y={0}>
            {"No Image Found"}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
