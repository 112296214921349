import { remCalc } from "../../utils";
import { Medium, Regular } from "../../utils/font";

export const locationStyle = {
    textFeild: {
        display: "flex", alignItems: "center", height: "58px", border: '1px solid #E4E8EE', borderRadius: '8px', position: 'relative'
    },
    location: {
        position: 'absolute', top: '16px', left: '8px'
    },
    locationArabic: {
        position: 'absolute', top: '14px', right: '12px'
    },
    input: {
        border: 'none',
        outline: 'none',
        fontSize: "0.875rem",
        fontFamily: Medium,
        padding: "0px",
        marginInlineEnd: "4px",
        marginInlineStart: "34px",
        width: "100%",
        color: "#091B29",
        textOverflow: "ellipsis",
        borderColor: '#E4E8EE',
        "&.Mui-focused fieldset": {
            borderColor: 'primary.main',
        },

    },
    target: {
        // position: 'absolute', top: '16px', right: '2px'
        mt: 1.5,
        padding: "0px 8px"

    },
    locationBox: {
        width: '100%'
    },
    pointer: {
        cursor: 'pointer'
    },
    text: {
        fontFamily: Regular,
        fontSize: remCalc(12),
        color: "#98A0AC",
        textOverflow: "ellipsis",
        marginInlineEnd: "4px",
        marginInlineStart: "34px"
    }
}